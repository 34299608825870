import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { HouseholdCalculationType, JointHeadingType, MandantType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { FinancingService, IFinancingStateParentDefinition } from 'app/modules/financing/data';
import { IHouseholdCalcData } from 'app/modules/financing/data/interfaces/household-calc-data.interface';
import { IHouseholdCalcExpenditure } from 'app/modules/financing/data/interfaces/household-calc-expenditure.interface';
import { IHouseholdCalcHouseholds } from 'app/modules/financing/data/interfaces/household-calc-households.interface';
import { IHouseholdCalcIncome } from 'app/modules/financing/data/interfaces/household-calc-income.interface';
import { IHouseholdCalcOverviewRow } from 'app/modules/financing/data/interfaces/household-calc-overview-row.interface';
import { IUpdateHouseholdCalculationExpenditure } from 'app/modules/financing/data/interfaces/update-household-calc-expenditure.interface';
import { IUpdateHouseholdCalculationIncome } from 'app/modules/financing/data/interfaces/update-household-calc-income.interface';
import { FinancingStatus, FinancingSubStatus, HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Observable, Subject, map, mergeMap, of, takeUntil, tap } from 'rxjs';

import { AddHouseholdNotesDialogComponent } from '../add-household-notes-dialog/add-household-notes-dialog.component';
import { EditHouseholdCalcTelecomDialogComponent } from '../edit-household-calc-telecom-dialog/edit-household-calc-telecom-dialog.component';


export interface IHouseholdCalcHouseholdsExtended extends IHouseholdCalcHouseholds {

    /** Gemeinsames verfügbares Einkommen */
    availableIncomeOverview?: number,

    /** Summe der Gemeinsamen Einnahmen */
    sumOfIncomeOverview?: number,

    /** Gemeinsames frei verfügbares Einkommen */
    discretionaryIncomeOverview?: number,

    /** Summe der gemeinsamen Ausgaben */
    expenditureOverview?: number,

    /** Gemeinsame bestehenbleibende Kredit-/Leasingrate */
    ongoinLoanInstallments?: number,

    /** Gemeinsame zumutbare Kreditrate */
    reasonableCreditRateOverview?: number,
}

/**
 * Household Bills
 */
@Component({
    selector: 'finprocess-household-calculations',
    templateUrl: './household-calculations.component.html',
    styleUrls: ['./household-calculations.component.scss'],
    animations: [
        trigger('fade', [
            state('void', style({ opacity: 0 })),
            transition(':enter', [animate('500ms')]),
            transition(':leave', [animate('500ms')]),
        ]),
    ],
})
export class HouseholdCalculationsComponent implements OnInit, OnDestroy {
    /**
     * HouseHold Calculation Data
     */
    public houseHoldData: IHouseholdCalcData | undefined;

    /**
     * financing id
     */
    public financingContainerID?: string;

    //data sorted by type
    public riskHouseholdData: IHouseholdCalcHouseholdsExtended[] = [];
    public customerHouseholdData: IHouseholdCalcHouseholdsExtended[] = [];
    public pensionHouseholdData: IHouseholdCalcHouseholdsExtended[] = [];

    ///
    /// Übersichtsseite
    ///

    /**
     * Overview must be refreshed or not
     */
    public refreshOverview = false;

    //Household Overview Table Rows
    public householdOverviewTableRows: IHouseholdCalcOverviewRow[] = [
        {
            headerKey: 'financing.features.financing-processing.householdBills.sumOfIncome',
            dataKeyCalculation: 'sumOfIncome',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.sumOfIncomeOverview',
            dataKey: 'sumOfIncomeOverview',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.sumOfExpenditure',
            dataKeyExpenditureCalculation: 'expenditure', 
            dataKeyExpenditureObject: 'sumOfExpenditure',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.expenditureOverview',
            dataKey: 'expenditureOverview',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.discretionaryIncome',
            dataKeyCalculation: 'discretionaryIncome',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.discretionaryIncomeOverview',
            dataKey: 'discretionaryIncomeOverview',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.ongoinLoanInstallments',
            dataKeyCalculation: 'ongoinLoanInstallments',
        },
        {
            headerKey: 'financing.features.financing-processing.householdBills.reasonableCreditRateOverview',
            dataKey: 'reasonableCreditRateOverview',
            highlighted: true,
        },
    ];

    //Overview - RiskHouseholdCalculation
    public riskHouseHoldOverview: IHouseholdCalcHouseholdsExtended[] = [];

    //Overview - PensionHouseholdCalculation
    public pensionHouseHoldOverview: IHouseholdCalcHouseholdsExtended[] = [];

    ///
    /// Haushalte
    ///

    public selectedHousehold: number = -1;
    public selectedHouseholdId: string | null = null;
    public householdExpenditure: IHouseholdCalcExpenditure[] = [];
    public debitors: IHouseholdCalcIncome[] = [];
    public riskHouseholdCalculationType: HouseholdCalculationType = HouseholdCalculationType.RiskHouseholdCalculation;
    public customerHouseholdCalculationType: HouseholdCalculationType = HouseholdCalculationType.CustomerHouseholdCalculation;
    public pensionHouseholdCalculationType: HouseholdCalculationType = HouseholdCalculationType.PensionHouseholdCalculation;

    //RiskhouseHold
    public riskHouseHoldDebitors: IHouseholdCalcIncome[] = [];
    public riskHouseholdExpenditure: IHouseholdCalcExpenditure[] = [];

    //CustomerHouseHold
    public customerHouseHoldDebitors: IHouseholdCalcIncome[] = [];
    public customerHouseholdExpenditure: IHouseholdCalcExpenditure[] = [];

    //PensionHouseHold
    public pensionHouseHoldDebitors: IHouseholdCalcIncome[] = [];
    public pensionHouseholdExpenditure: IHouseholdCalcExpenditure[] = [];

    //show/hide customer household table
    public showKHHRTable = false;

    //calculate sums of expenditures for each household
    public riskHouseholdExpenditureSum?: number;
    public pensionHouseholdExpenditureSum?: number;

    //calculate sums of available income
    public riskHouseholdAvailableIncomeSum?: number;
    public pensionHouseholdAvailableIncomeSum?: number;

    //calculate joint Income Sums
    public riskHouseholdJointIncomeSum?: { sum?: number, thirtyPercentOfSum?: number };
    public pensionHouseholdJointIncomeSum?: { sum?: number, thirtyPercentOfSum?: number };

    //calculate telekommunikationskosten
    public riskHouseholdTelecommunicationSum?: number;
    public customerHouseholdTelecommunicationSum?: number;
    public pensionHouseholdTelecommunicationSum?: number;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();
    public isStatusNotOpenOrWaiting = false;
    
    /**
     * Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     * check input change
     */
    public inputChanged = false;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    public isBaf: boolean | undefined;

    /**
     * bestehende Verpflichtungen Tabelle
     */
    public displayedLiabilityColumns: string[] = ['liabilityBank', 'liabilityType', 'started', 'initialAmount', 'currentAmount', 'fictionalRateAmount', 'monthlyRate', 'loanPeriodInMonths', 'isBeyondRetirement'];

    /**
     * LiabilitySource
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public LiabilitySource = JointHeadingType;
    
    /**
     * Konstruktor
     *
     * @param {Store} store store
     * @param {FinancingService} financingService financing service
     * @param {WaiterService} waiterService waiterservice
     * @param {NotificationService} notificationService notificationservice
     * @param {TranslateService} translate translate service
     * @param {ChangeDetectorRef} changeDetection change detection
     * @param {MatDialog} dialog mat dialog
     * @param {string} locale locale
     */
    public constructor(private store: Store,
        private financingService: FinancingService, private waiterService: WaiterService,
        private notificationService: NotificationService, private translate: TranslateService,
        private changeDetection: ChangeDetectorRef, public dialog: MatDialog, @Inject(LOCALE_ID) locale: string) {

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
            decimal: ',',
            thousands: '.',
        });
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.financingContainerID = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financingContainerID);
        this.getRiskHouseHoldData();
        this.store.select((it: IFinancingStateParentDefinition) => it).pipe(
            takeUntil(this.viewLeft$),
            map(it => {
                this.isStatusNotOpenOrWaiting = !(it.financing.finprocessContainer?.status === FinancingStatus.Open && it.financing.finprocessContainer?.subStatus === FinancingSubStatus.Editing
                    || it.financing.finprocessContainer?.status === FinancingStatus.HouseholdCalculationWaitingForAcception && it.financing.finprocessContainer?.subStatus === FinancingSubStatus.HouseholdCalculationWaitingForAcception)

                this.isBaf = it.financing.finprocessContainer?.mandantType === MandantType.BAF;
            }),
        ).subscribe();
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * get risk household data
     */
    public getRiskHouseHoldData() {
        if (this.financingContainerID) {
            this.waiterService.show();
            this.financingService.getRiskHouseHoldCalculations(this.financingContainerID).subscribe({
                next: result => {
                    if (result) {

                        this.houseHoldData = result;

                        for (const household of result.households) {
                            for (const calculation of household.calculations) {

                                // Push the calculations into the corresponding arrays based on the calculation type
                                switch (calculation.type) {
                                    case 0:
                                        this.riskHouseholdData.push({ id: household.id, householdCalculationExist: household.householdCalculationExist, calculations: [calculation], liabilities: household.liabilities, newLiabilities: household.newLiabilities });
                                        break;
                                    case 1:
                                        this.customerHouseholdData.push({ id: household.id, householdCalculationExist: household.householdCalculationExist, calculations: [calculation], liabilities: household.liabilities, newLiabilities: household.newLiabilities });
                                        break;
                                    case 2:
                                        this.pensionHouseholdData.push({ id: household.id, householdCalculationExist: household.householdCalculationExist, calculations: [calculation], liabilities: household.liabilities, newLiabilities: household.newLiabilities });
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }

                    this.setOverviewSumData(this.riskHouseholdData, HouseholdCalculationType.RiskHouseholdCalculation);
                    this.setOverviewSumData(this.pensionHouseholdData, HouseholdCalculationType.PensionHouseholdCalculation);

                    this.waiterService.hide();
                    this.changeDetection.detectChanges();
                },
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.riskfinancingplans.rfpLoadError'));
                },
            });
        }
    }


    /**
     * calculate overview sums for risk household and pension household
     *
     * @param {IHouseholdCalcHouseholdsExtended} data riskhousehold or pensionhousehold data
     * @param {HouseholdCalculationType} calcType calculation type
     */
    public setOverviewSumData(
        data: IHouseholdCalcHouseholdsExtended[],
        calcType: HouseholdCalculationType,
    ): void {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let indexHoushold = 0; indexHoushold < data.length; indexHoushold++) {
            for (let indexCalculation = 0; indexCalculation < data[indexHoushold].calculations.length; indexCalculation++) {
                data[indexHoushold]?.sumOfIncomeOverview === undefined ?
                    (data[indexHoushold].sumOfIncomeOverview = this.isUndefinedReturnZero(data[indexHoushold]?.calculations[indexCalculation]?.sumOfIncome)) :
                    (data[indexHoushold].sumOfIncomeOverview! += this.isUndefinedReturnZero(data[indexHoushold]?.calculations[indexCalculation]?.sumOfIncome));
                data[indexHoushold]?.expenditureOverview === undefined ?
                    (data[indexHoushold].expenditureOverview = this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.expenditure?.sumOfExpenditure)) :
                    (data[indexHoushold].expenditureOverview! += this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.expenditure?.sumOfExpenditure));

                data[indexHoushold]?.availableIncomeOverview === undefined ?
                    (data[indexHoushold].availableIncomeOverview = this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.availableIncome)) :
                    (data[indexHoushold].availableIncomeOverview! += this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.availableIncome));

                //Gemeinsames frei verfügbares Einkommen
                data[indexHoushold].discretionaryIncomeOverview === undefined ?
                    data[indexHoushold].discretionaryIncomeOverview = this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.discretionaryIncome) :
                    data[indexHoushold].discretionaryIncomeOverview! += this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.discretionaryIncome)

                data[indexHoushold].reasonableCreditRateOverview === undefined ?
                    data[indexHoushold].reasonableCreditRateOverview = this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.reasinableCreditRate) :
                    data[indexHoushold].reasonableCreditRateOverview! += this.isUndefinedReturnZero(data[indexHoushold].calculations[indexCalculation]?.reasinableCreditRate)
            }
        }

        // Update data
        this.updateHouseholdData(data, calcType, 'households');
    }

    /**
     * on Tab Change -> get selected household and get corresponding data
     *
     * @param {number} index tab change index
     */
    public onTabChange(index: number) {
        //-1 === Übersichtsseite -> sonst Error bei Haushalten
        this.selectedHousehold = index - 1;
        this.selectedHouseholdId = this.houseHoldData?.households[index-1]?.id ?? null;

        //refresh overview when household values changed
        if (this.selectedHousehold <= 0 && this.refreshOverview) {
            this.clearRiskAndPensionOverviewSumData();
            this.getRiskHouseHoldData();
            this.refreshOverview = false;
        }

        //update household data
        if (this.selectedHousehold >= 0) {
            if (this.riskHouseholdData[this.selectedHousehold] && this.riskHouseholdData.length > 0) {
                this.updateHouseholdData(
                    this.riskHouseholdData,
                    HouseholdCalculationType.RiskHouseholdCalculation,
                    'debitors',
                    this.riskHouseholdExpenditure,
                );

                this.calculateSumsHouseholds(HouseholdCalculationType.RiskHouseholdCalculation);
            }

            if (this.customerHouseholdData[this.selectedHousehold] && this.customerHouseholdData.length > 0) {
                this.updateHouseholdData(
                    this.customerHouseholdData,
                    HouseholdCalculationType.CustomerHouseholdCalculation,
                    'debitors',
                    this.customerHouseholdExpenditure,
                );

                this.calculateSumsHouseholds(HouseholdCalculationType.CustomerHouseholdCalculation);
            }

            if (this.findArrayElementWithId(this.pensionHouseholdData,this.selectedHouseholdId!) && this.pensionHouseholdData.length > 0) {

                this.updateHouseholdData(
                    this.pensionHouseholdData,
                    HouseholdCalculationType.PensionHouseholdCalculation,
                    'debitors',
                    this.pensionHouseholdExpenditure,
                );

                this.calculateSumsHouseholds(HouseholdCalculationType.PensionHouseholdCalculation);
            }
        }
    }

    /**
     * update Household Calculation Data
     *
     * @param {IHouseholdCalcHouseholds} householdData household data array
     * @param {HouseholdCalculationType} calculationType calculationType
     * @param {navigationType} navigationType navigation type 'debitors' | 'households'
     * @param {IHouseholdCalcExpenditure} householdExpenditure household expenditure array
     */
    private updateHouseholdData(
        householdData: IHouseholdCalcHouseholds[] | IHouseholdCalcHouseholdsExtended[],
        calculationType: HouseholdCalculationType,
        navigationType: 'debitors' | 'households',
        householdExpenditure?: IHouseholdCalcExpenditure[],
    ): void {

        if (navigationType === 'debitors') {
            const selectedHouseholdData = this.findArrayElementWithId(householdData, this.selectedHouseholdId!)!;
            if (householdExpenditure) {
                householdExpenditure.length = 0;
                householdExpenditure.push(selectedHouseholdData.calculations[0].expenditure);
            }

            this.debitors = selectedHouseholdData.calculations[0].income;

            this.updateDebitors(this.debitors, calculationType, navigationType);
        }

        else if (navigationType === 'households') {
            this.updateDebitors(householdData, calculationType, navigationType);
        }
    }

    /**
     * update debitors in tables
     *
     * @param {IHouseholdCalcIncome[] | IHouseholdCalcHouseholdsExtended[]} data data that should be shown
     * @param {HouseholdCalculationType} calcType HouseholdCalculationType
     * @param {navigationType} navigationType navigationType 'debitors' | 'households'
     */
    private updateDebitors(data: IHouseholdCalcIncome[] | IHouseholdCalcHouseholdsExtended[], calcType: HouseholdCalculationType, navigationType: 'debitors' | 'households') {
        //RiskHouseholdCalculation
        if (calcType === HouseholdCalculationType.RiskHouseholdCalculation) {

            if (navigationType === 'debitors') {
                this.updateData(
                    data,
                    this.riskHouseHoldDebitors,
                    navigationType,
                );
                this.calculateSumsHouseholds(calcType);
            }
            else if (navigationType === 'households') {
                this.updateData(
                    data,
                    this.riskHouseHoldOverview,
                    navigationType,
                );
            }
        }

        //CustomerHouseholdCalculation
        if (calcType === HouseholdCalculationType.CustomerHouseholdCalculation) {
            this.updateData(
                data,
                this.customerHouseHoldDebitors,
                navigationType,
            );
            this.calculateSumsHouseholds(calcType);
        }

        //PensionHouseholdCalculation
        if (calcType === HouseholdCalculationType.PensionHouseholdCalculation) {

            if (navigationType === 'debitors') {
                this.updateData(
                    data,
                    this.pensionHouseHoldDebitors,
                    navigationType,
                );
                this.calculateSumsHouseholds(calcType);
            }
            else if (navigationType === 'households') {
                this.updateData(
                    data,
                    this.pensionHouseHoldOverview,
                    navigationType,
                );
            }
        }
    }

    /**
     * update shown debitors
     *
     * @param {(IHouseholdCalcIncome | IHouseholdCalcHouseholdsExtended)[]} data array of shown data
     * @param {(IHouseholdCalcIncome | IHouseholdCalcHouseholdsExtended)[]} columnData column separated data
     * @param {navigationType} navigationType navigationType 'debitors' | 'households'
     */
    private updateData(
        data: (IHouseholdCalcIncome | IHouseholdCalcHouseholdsExtended)[],
        columnData: (IHouseholdCalcIncome | IHouseholdCalcHouseholdsExtended)[],
        navigationType: 'debitors' | 'households',
    ) {
        columnData.length = 0;
        if (navigationType === 'debitors') {
            columnData.push(...(data as IHouseholdCalcIncome[]));

        } else if (navigationType === 'households') {
            columnData.push(...(data as IHouseholdCalcHouseholdsExtended[]));
        }
    }


    /**
     * show/hide customer household table
     */
    public customerTableVisibility() {
        this.showKHHRTable = !this.showKHHRTable;
    }

    /**
     * get calculated sums for household calculation tables
     *
     * @param {HouseholdCalculationType} calcType calculation type
     */
    public calculateSumsHouseholds(calcType: HouseholdCalculationType) {

        if (calcType === HouseholdCalculationType.RiskHouseholdCalculation) {
            //expenditure sum
            this.riskHouseholdExpenditureSum = this.calculateExpenditureSum(this.riskHouseholdExpenditure);
            //debitor income sum
            this.riskHouseHoldDebitors = this.calculateDebitorIncomeSum(this.riskHouseHoldDebitors);
            //joint income sum and 30% of income sum
            this.riskHouseholdJointIncomeSum = this.calculateJointIncomeSum(this.riskHouseholdData[this.selectedHousehold].calculations[0].income);
            //available income sum
            this.riskHouseholdAvailableIncomeSum = this.calculateAvailableIncomeSum(this.riskHouseholdExpenditureSum, this.riskHouseholdJointIncomeSum.sum ?? 0);
            //telekommunikationskostensumme
            this.riskHouseholdTelecommunicationSum = this.calculateTelecommunicationCosts(this.riskHouseholdExpenditure[0].phoneCosts, this.riskHouseholdExpenditure[0].broadcastCosts);
        }

        if (calcType === HouseholdCalculationType.CustomerHouseholdCalculation) {
            //telekommunikationskostensumme
            this.customerHouseholdTelecommunicationSum = this.calculateTelecommunicationCosts(this.customerHouseholdExpenditure[0].phoneCosts, this.customerHouseholdExpenditure[0].broadcastCosts);
        }

        if (calcType === HouseholdCalculationType.PensionHouseholdCalculation) {
            //expenditure sum
            this.pensionHouseholdExpenditureSum = this.calculateExpenditureSum(this.pensionHouseholdExpenditure);
            //debitor income sum
            this.pensionHouseHoldDebitors = this.calculateDebitorIncomeSum(this.pensionHouseHoldDebitors);
            //joint income sum and 30% of income sum
            this.pensionHouseholdJointIncomeSum = this.calculateJointIncomeSum(this.findArrayElementWithId(this.pensionHouseholdData, this.selectedHouseholdId!)!.calculations[0].income);
            //available income sum
            this.pensionHouseholdAvailableIncomeSum = this.calculateAvailableIncomeSum(this.pensionHouseholdExpenditureSum, this.pensionHouseholdJointIncomeSum.sum ?? 0);
            //telekommunikationskostensumme
            this.pensionHouseholdTelecommunicationSum = this.calculateTelecommunicationCosts(this.pensionHouseholdExpenditure[0].phoneCosts, this.pensionHouseholdExpenditure[0].broadcastCosts);
        }
    }

    /**
     * calculate expenditure sum
     *
     * @param {IHouseholdCalcExpenditure} expenditures expenditures
     * @returns {number} sum of expenditures
     */
    public calculateExpenditureSum(expenditures: IHouseholdCalcExpenditure[]): number {
        let sum = 0;
        for (const expenditure of expenditures) {
            sum += this.isUndefinedReturnZero(expenditure.broadcastCosts) + this.isUndefinedReturnZero(expenditure.carCosts) + this.isUndefinedReturnZero(expenditure.electricityCosts) +
                this.isUndefinedReturnZero(expenditure.insuranceCosts) + this.isUndefinedReturnZero(expenditure.livingCosts) + this.isUndefinedReturnZero(expenditure.livingCostsOfDependentChildren) + 
                this.isUndefinedReturnZero(expenditure.expensesForAdditionalResidentialpropertiesForPersonalUse) + this.isUndefinedReturnZero(expenditure.safetySurcharge) + 
                this.isUndefinedReturnZero(expenditure.additionalExpensesForChildren) + this.isUndefinedReturnZero(expenditure.operationalCosts) + this.isUndefinedReturnZero(expenditure.otherCosts) + this.isUndefinedReturnZero(expenditure.phoneCosts);
        }
        return sum;
    }

    /**
     * calculate available income sum
     *
     * @param {number} expenditureSum expenditure sum
     * @param {number} incomeSum income sum
     * @returns {number} available sum
     */
    public calculateAvailableIncomeSum(expenditureSum: number, incomeSum: number): number {
        let sum = 0;
        sum = this.isUndefinedReturnZero(incomeSum) - this.isUndefinedReturnZero(expenditureSum);
        return sum;
    }

    /**
     * calculate joint income sum
     *
     * @param {IHouseholdCalcIncome} incomes incomes
     * @returns {number} sum income sum
     */
    public calculateJointIncomeSum(incomes: IHouseholdCalcIncome[]): { sum: number, thirtyPercentOfSum: number } {
        let sum = 0;
        let thirtyPercentOfSum = 0;

        for (const income of incomes) {
            sum += this.isUndefinedReturnZero(income.sumOfIncome);
        }
        thirtyPercentOfSum = sum * 0.3;

        return { sum, thirtyPercentOfSum };
    }

    /**
     * calculate income sum of each debitor
     *
     * @param {IHouseholdCalcIncome} debitorIncome debitor income
     * @returns {IHouseholdCalcIncome} debitors with updated sum of income
     */
    public calculateDebitorIncomeSum(debitorIncome: IHouseholdCalcIncome[]): IHouseholdCalcIncome[] {
        for (const income of debitorIncome) {
            let sum = 0;
            let netIncome = this.isUndefinedReturnZero(income.netIncome);

            //check for 14. month salary
            if (income.fourteenSalariesPerYear) {
                netIncome = (income.netIncome * 14) / 12;
            }

            sum += this.isUndefinedReturnZero(income.existingOtherIncome) + this.isUndefinedReturnZero(income.independentIncome) +
                this.isUndefinedReturnZero(netIncome) + this.isUndefinedReturnZero(income.otherIncome) + this.isUndefinedReturnZero(income.rentalIncome) + this.isUndefinedReturnZero(income.existingRentalIncome);

            income.sumOfIncome = sum;
        }
        return debitorIncome;
    }

    /**
     * calculate the sum of phone + broadcast costs = telekommunikationskosten
     *
     * @param {number} phoneCosts phone costs
     * @param {number} broadcastCosts broadcast costs
     * @returns {number} summe aus phone und broadcast costs = telekommunikationskosten
     */
    public calculateTelecommunicationCosts(phoneCosts: number, broadcastCosts: number) {
        let sum = 0;
        sum = this.isUndefinedReturnZero(phoneCosts) + this.isUndefinedReturnZero(broadcastCosts);
        return sum;
    }

    /**
     * check if value is null/undefined/NaN and set it to 0
     *
     * @param {number} value value number | undefined | null
     * @returns {number} value or 0
     */
    public isUndefinedReturnZero(value: number | undefined | null) {
        return Number.isNaN(value) || value === null || value === undefined ? 0 : value;
    }

    /**
     * open dialog to edit telecommunication costs
     *
     * @param {HouseholdCalculationType} calcType calculation type
     * @param {string} householdID household id
     * @param {number} broadCastCost previous broadcast cost
     * @param {number} phoneCost previous phone cost
     */
    public openEditTelecommunicationDialog(calcType: HouseholdCalculationType, householdID: string, broadCastCost: number, phoneCost: number) {
        const dialogRef = this.dialog.open(EditHouseholdCalcTelecomDialogComponent, {
            data: { previousBroadCastCost: broadCastCost, previousPhoneCost: phoneCost },
        });

        dialogRef.afterClosed().subscribe(result => {
            //re-calculate and save the telecommunicationcosts
            if (result) {
                switch (calcType) {
                    case 0:
                        this.riskHouseholdExpenditure[0].broadcastCosts = result.newBroadcastCost;
                        this.riskHouseholdExpenditure[0].phoneCosts = result.newPhoneCost;
                        this.riskHouseholdTelecommunicationSum = this.calculateTelecommunicationCosts(result.newPhoneCost, result.newBroadcastCost);
                        this.riskHouseholdExpenditureSum = this.calculateExpenditureSum(this.riskHouseholdExpenditure);
                        this.riskHouseholdAvailableIncomeSum = this.calculateAvailableIncomeSum(this.riskHouseholdExpenditureSum, this.riskHouseholdJointIncomeSum?.sum || 0);
                        this.updateHouseholdCalculationExpenditure(this.riskHouseholdExpenditure[0], householdID, calcType, true);
                        this.changeDetection.detectChanges();
                        break;
                    case 2:
                        this.pensionHouseholdExpenditure[0].broadcastCosts = result.newBroadcastCost;
                        this.pensionHouseholdExpenditure[0].phoneCosts = result.newPhoneCost;
                        this.pensionHouseholdTelecommunicationSum = this.calculateTelecommunicationCosts(result.newPhoneCost, result.newBroadcastCost);
                        this.pensionHouseholdExpenditureSum = this.calculateExpenditureSum(this.pensionHouseholdExpenditure);
                        this.pensionHouseholdAvailableIncomeSum = this.calculateAvailableIncomeSum(this.pensionHouseholdExpenditureSum, this.pensionHouseholdJointIncomeSum?.sum || 0);
                        this.updateHouseholdCalculationExpenditure(this.pensionHouseholdExpenditure[0], householdID, calcType, true);
                        this.changeDetection.detectChanges();
                        break;
                    default:
                        break;
                }
            }
        });
    }

    /**
     * open notes dialog - updates new notes
     *
     * @param {string} householdID household id
     */
    public openNotesDialog(householdID: string) {
        const dialogRef = this.dialog.open(AddHouseholdNotesDialogComponent, {
            data: { previousNote: this.riskHouseholdExpenditure[0].riskCalculationNote ?? '<p></p>' },
            minWidth: '50%',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.riskHouseholdExpenditure[0].riskCalculationNote = result.note;
                this.updateHouseholdCalculationExpenditure(this.riskHouseholdExpenditure[0], householdID, HouseholdCalculationType.RiskHouseholdCalculation, false, true);
            }
        });
    }

    /**
     * update Household Calculation Expenditure data
     *
     * @param {IHouseholdCalcExpenditure} data household expenditure data
     * @param {string} householdID household id
     * @param {HouseholdCalculationType} calcType calculation type
     * @param {boolean} telecommunicationUpdate telecommunication update
     * @param {boolean} noteUpdate note update
     */
    public updateHouseholdCalculationExpenditure(data: IHouseholdCalcExpenditure, householdID: string, calcType: HouseholdCalculationType, telecommunicationUpdate?: boolean, noteUpdate?: boolean) {

        if (this.inputChanged || telecommunicationUpdate || noteUpdate) {
            if (this.financingContainerID) {

                const updateData: IUpdateHouseholdCalculationExpenditure = {
                    financingMapId: this.financingContainerID,
                    householdId: householdID,
                    type: calcType,
                    expenditureInformation: data,
                };

                this.financingService.updateHouseHoldCalculationExpenditure(updateData).subscribe({
                    next: () => {
                        //re-calculate the pension sums
                        if (calcType === HouseholdCalculationType.RiskHouseholdCalculation) {
                            this.calculateSumsHouseholds(calcType);
                            this.changeDetection.detectChanges();
                        }
                        if (calcType === HouseholdCalculationType.PensionHouseholdCalculation) {
                            this.calculateSumsHouseholds(calcType);
                            this.changeDetection.detectChanges();
                        }
                        this.refreshOverview = true;
                    },
                    error: () => {
                        this.notificationService.confirmOkCancel('Fehler beim Speichern', 'Beim Speichern der Haushaltsausgabenwerte ist ein Fehler aufgetreten.');
                    },
                });
            }
        }
        this.inputChanged = false; // Reset the flag
    }

    /**
     * update Pensionhousehold Calculation Income data
     *
     * @param {IHouseholdCalcIncome} debitor debitor income
     */
    public updatePensionCalculationIncome(debitor: IHouseholdCalcIncome) {
        if (this.inputChanged) {

            if (this.financingContainerID) {

                const updateData: IUpdateHouseholdCalculationIncome = {
                    financingMapId: this.financingContainerID,
                    type: HouseholdCalculationType.PensionHouseholdCalculation,
                    incomeInformation: debitor,
                };

                this.financingService.updateHouseHoldCalculationIncome(updateData).subscribe({
                    next: () => {
                        //re-calculate the pension sums
                        this.calculateSumsHouseholds(HouseholdCalculationType.PensionHouseholdCalculation);
                        this.changeDetection.detectChanges();
                        this.refreshOverview = true;
                    },
                    error: () => {
                        this.notificationService.confirmOkCancel('Fehler beim Speichern', 'Beim Speichern der Einkommenswerte ist ein Fehler aufgetreten.');
                    },
                });
            }
            this.inputChanged = false; // Reset the flag
        }
    }

    /**
     * flag to check if input in cell has changed
     */
    public onInputChange() {
        this.inputChanged = true;
    }

    /**
     * clear Overview Sum Data
     */
    private clearRiskAndPensionOverviewSumData(): void {
        this.houseHoldData = undefined;
        this.pensionHouseholdData = [];
        this.riskHouseholdData = [];
    }

    /**
     * Ruft service an und generate die Dokumente
     *
     * @param {string} housholdId housholdId
     */
    public generateDocumentClicked(housholdId: string): void {
        this.notificationService.confirmOkCancel(
            this.translate.instant('financing.features.financing-processing.householdBills.generateDocuments'),
            this.translate.instant('financing.features.financing-processing.householdBills.confirmGenerate'),

        ).pipe(
            tap(() => this.waiterService.show()),
            mergeMap(result => {
                if (result === 'submit' && this.financingContainerID !== undefined) {
                    return this.financingService.generateHousholdBills(this.financingContainerID, housholdId)
                }

                return of(false);
            }),
        ).subscribe({
            next: isGenerated => {
                this.waiterService.hide();
                if (isGenerated !== false) {
                    this.setHousholdCalculationExist(housholdId);
                    this.notificationService.toast(
                        this.translate.instant('financing.features.financing-processing.householdBills.generateDocumentsToast'),
                    );
                }
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(
                    this.translate.instant('general.error'),
                    this.translate.instant('general.unknownError'),
                );
            },
        });
    }

    /**
     * Method um HausholdCalculationExist als true setzen um ermoglichen noch mal das geliche dokumente zu generieren
     *
     * @param {string} housholdId housholdId
     */
    private setHousholdCalculationExist(housholdId: string) {
        const housholdData = this.riskHouseholdData.find(it => it.id === housholdId);
        if (!!housholdData) {
            housholdData.householdCalculationExist = true;
            this.changeDetection.markForCheck();
            this.changeDetection.detectChanges();
        }
    }

    /**
     * function used to identify element in array that has a specific id
     * 
     * @param {any[]} array array
     * @param {string} id id
     * @returns {object | undefined} data object or null
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public findArrayElementWithId(array: any[], id: string) {
        return array.find(e => e.id === id);
    }
}
