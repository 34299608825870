<div class="ucba-tile-layout-container" [ngStyle]="{ 'grid-template-columns': gridColumnsStyle() }">
    @for (tile of tiles$(); track $index; let last = $last) {
        @if (tiles$()[$index - 1]; as previousTile) {
            @if (previousTile.column + previousTile.tile.span() < currentColumns() && tile.column === 0) {
                <div class="ucba-tile" [ngStyle]="{ 'grid-column': 'span ' + (currentColumns() - (previousTile.column + previousTile.tile.span())) }"></div>
            }
        }

        <div class="ucba-tile" [ngStyle]="{ 'grid-column': 'span ' + tile.tile.span()}">
            <ng-template [cdkPortalOutlet]="tile.tile"></ng-template>
        </div>
        @if (last && tile.column + tile.tile.span() < currentColumns()) {
            <div class="ucba-tile" [ngStyle]="{ 'grid-column': 'span ' + (currentColumns() - (tile.column + tile.tile.span())) }"></div>
        }
    }
</div>
<ng-content></ng-content>
