import { animate, group, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, input, signal } from '@angular/core';

import { UcbaHeadingType } from '../../types';

/**
 * A chip component
 */
@Component({
    selector: 'ucba-heading',
    templateUrl: './heading.component.html',
    styleUrls: ['./heading.component.scss'],
    standalone: true,
    imports: [
        NgClass,
    ],
    animations: [
        trigger('expand', [
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                group([
                    animate('180ms ease-in-out', style({ height: '*' })),
                    animate('250ms ease-in-out', style({ opacity: 1 })),
                ]),
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                group([
                    animate('180ms ease-in-out', style({ height: '0' })),
                    animate('250ms ease-in-out', style({ opacity: 0 })),
                ]),
            ]),
        ]),
    ],
})
export class UcbaHeadingComponent {

    /**
     * The type of heading. Can be 'large', 'medium', or 'small'.
     */
    public type = input<UcbaHeadingType>('large');

    /**
     * Whether the heading is expandable.
     */
    public expandable = input<boolean>(false);

    /**
     * Whether the heading is expanded.
     */
    protected isExpanded = signal<boolean>(false);

    /**
     * Toggles the expanded state of the heading.
     */
    public toggleExpanded() {
        if (this.expandable()) {
            this.isExpanded.update(isExpanded => !isExpanded);
        }
    }
    
}
