import { CommonModule, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FinancingService, IAutocomleteData, IBase, ValueStorageType } from 'app/modules/financing/data';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { Observable } from 'rxjs/internal/Observable';

import { PipesModule } from '../../../../../shared/features/pipes/pipes.module';
import { OverwriteInputComponent } from '../base-input/base-input';

/**
 * Autocomplete
 */
@Component({
    selector: 'finprocess-autocomplete-input',
    templateUrl: './autocomplete-input.component.html',
    styleUrls: ['./autocomplete-input.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        FlexLayoutModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        PipesModule,
        MatAutocompleteModule,
    ],
})
export class AutocompleteInputComponent <T extends IBase> extends OverwriteInputComponent<T> {

    /**
     * Data for Autocomplete
     */
    @Input()
    public autocompleteData$!: Observable<IAutocomleteData<unknown>[] | undefined>

    /**
     * Search text
     */
    @Output()
    public searchChanged: EventEmitter<string> = new EventEmitter<string>();
        
    @Output()
    public selectedChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

    public displayWith = input<(value: IAutocomleteData<unknown>) => string>(value => value?.toString());

    /**
     * Standard Constructor
     * 
     * @param {FinancingService} financingService FinancingService-Injector
     */
    public constructor(financingService: FinancingService) {
        super(ValueStorageType.String, financingService);
    }

    /**
     *  Search text Changed
     * 
     * @param {string} term search term
     */ 
    public onSearchChanged(term?: string) {
        this.searchChanged.emit(term);
    }   

    /**
     * optionSelected
     * 
     * @param {MatAutocompleteSelectedEvent} event MatAutocompleteSelectedEvent 
     */
    public onSelectionChange(event: MatAutocompleteSelectedEvent) {
        const saveFn = this.saveFn();
        const entity = this.entity();

        if (saveFn && !!entity?.id) {
            saveFn(event.option.value, { entityId: entity.id });
        }  
    }
}
