<div right class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>

<button class="back-button mt-3 mb-1 ml-3" mat-stroked-button (click)="backToProductPackages()">
    <!-- TODO: when header is redesigned this back button is placed in there -->
    <mat-icon>arrow_back</mat-icon>
    {{'financing.features.financing-processing.risk-decision.productPackages' | translate}}
</button>
<div class="container" class="mt-3">
    <finprocess-stepper [steps]="[collateralStep, riskDecisionStep, approvalStep, financingDetailsStep]"></finprocess-stepper>
    <router-outlet></router-outlet>
</div>