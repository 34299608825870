import { Validators } from '@angular/forms';
import { FinprocessFormBuilder, createProviderInput } from '@ntag-ef/finprocess-forms';

import { ADDITIONAL_INFORMATION } from '../forms/colt-provider';
import { IAdditionalInformation } from '../interfaces';

export const additionalInformationForm = FinprocessFormBuilder.createFormGroup<IAdditionalInformation>({
    fxCurrency: {
        validator: createProviderInput({ 
            providers: ADDITIONAL_INFORMATION, 
            fn: (addInf: IAdditionalInformation) => (addInf.fxAmount1 > 1 ? Validators.required : null),
        }),
    },
    fxAmount1: {
        validator: createProviderInput({
            providers: ADDITIONAL_INFORMATION, 
            fn: (addInf: IAdditionalInformation) => (addInf.fxAmount2 > 1 ? Validators.required : null),
        }),
    },
    fxAmount2: null,
}, ADDITIONAL_INFORMATION);
