<ucba-heading *ngIf="!!dataSources" [type]="'medium'">
    <div ucba-heading-title>{{ 'asset.assetDetails.collateralisation' | translate }}</div>
    <div ucba-heading-body-visible>
        <ucba-tile-layout>
            @for(source of dataSources; track $index) {
                <ng-template ucba-tile [forceNewLine]="true">
                    <finprocess-label-value>
                        <span label>{{ 'asset.assetDetails.tableProductName' | translate }}</span>
                        <span value>{{ source.name! | finprocessEnumTranslation : 'ProductPackageType' }}</span>
                    </finprocess-label-value>
                </ng-template>
                <ng-template ucba-tile>
                    <finprocess-label-value>
                        <span label>{{ 'asset.assetDetails.tableDuration' | translate }}</span>
                        <span value>
                            @if(source.duration !== '-') {
                                {{ source.duration }} {{ 'asset.assetDetails.tableMonths' | translate }}
                            } @else {
                                {{ source.duration }}
                            }
                        </span>
                    </finprocess-label-value>
                </ng-template>
                <ng-template ucba-tile [span]="3">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <finprocess-label-value>
                            <span label>{{ 'asset.assetDetails.tableAmount' | translate }}</span>
                            <span value>{{ source.amount | formatCurrency }}</span>
                        </finprocess-label-value>
                        <mat-checkbox
                            color="primary"
                            [disabled]="editingReadonly$ | async"
                            [checked]="source.collateralized"
                            (change)="productSecured(collateralDetail.id, source.id, $event.checked)">
                        </mat-checkbox>
                    </div>
                </ng-template>
            }
        </ucba-tile-layout>
    </div>
</ucba-heading>