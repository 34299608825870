<div class="w-100">
    <div *ngIf="approvalView === false" class="overview-data-wrapper" fxLayout="row" fxLayoutAlign="center">
        <finprocess-label-value>
            <span label>{{'asset.assetDetails.totalCreditAmount' | translate}}</span>
            <span value>{{totalCreditAmount | formatCurrency}}</span>
        </finprocess-label-value>
    </div>

    <ng-container *ngIf="collateralDataRealEstates$ | async as collateralDataRealEstates">
        <finprocess-collateral-real-estate-details [productPackage]="productPackageData"
            [collateralRealEstateDetail]="collateralDataRealEstates"
            [assetRealEstate]="assetRealEstates" [approvalView]="approvalView"></finprocess-collateral-real-estate-details>
    </ng-container>

    <ng-container *ngIf="collateralDataInsurance$ | async as collateralDataInsurances">
        <finprocess-collateral-insurance-details [productPackage]="productPackageData"
            [collateralInsuranceDetail]="collateralDataInsurances"
            [assetInsurance]="assetInsurance" [approvalView]="approvalView"></finprocess-collateral-insurance-details>
    </ng-container>

    <ng-container *ngIf="collateralDataAccounts$ | async as collateralDataAccounts">
        <finprocess-collateral-account-details [productPackage]="productPackageData"
            [collateralAccountDetail]="collateralDataAccounts"
            [assetAccount]="assetAccounts" [approvalView]="approvalView"></finprocess-collateral-account-details>
    </ng-container>

    <ng-container
        *ngIf="(collateralDataRealEstates$ | async)?.length === 0 && (collateralDataInsurance$ | async)?.length === 0 && (collateralDataAccounts$ | async)?.length === 0">
        <div class="info-text" fxlayout="row" fxLayoutAlign="center center">Es sind noch keine Sicherheitendetails
            vorhanden.</div>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="end" class="add-btn mt-4 mb-4" *ngIf="approvalView === false">
        <!--neues Sicherheitendetail anlegen-->
        <button [disabled]="(editingReadonly$ | async)" mat-raised-button color="primary" style="height: 36px;" [matMenuTriggerFor]="mainMenu">
            {{'asset.assetDetails.addNewCollateral' | translate}}
        </button>

        <mat-menu #mainMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="kontoMenu">{{'asset.assetDetails.accountTitle' | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="wertpapiereMenu">{{'asset.assetDetails.securityPaperTitle' | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="versicherungenMenu">{{'asset.assetDetails.insuranceTitle' | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="immobilienMenu">{{'asset.assetDetails.realEstateTitle' | translate}}</button>
        </mat-menu>

        <mat-menu #kontoMenu="matMenu">
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA415)">{{ CollateralType.BA415 |
                finprocessEnumTranslation:'CollateralType' }}</button>
        </mat-menu>

        <mat-menu #wertpapiereMenu="matMenu">
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA425)">{{ CollateralType.BA425 |
                finprocessEnumTranslation:'CollateralType' }}</button>
        </mat-menu>

        <mat-menu #versicherungenMenu="matMenu">
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA105)">{{ CollateralType.BA105 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA110)">{{ CollateralType.BA110 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA115)">{{ CollateralType.BA115 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA120)">{{ CollateralType.BA120 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA125)">{{ CollateralType.BA125 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA130)">{{ CollateralType.BA130 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA135)">{{ CollateralType.BA135 |
                finprocessEnumTranslation:'CollateralType' }}</button>
        </mat-menu>

        <mat-menu #immobilienMenu="matMenu">
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA205)">{{ CollateralType.BA205 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA210)">{{ CollateralType.BA210 |
                finprocessEnumTranslation:'CollateralType' }}</button>
            <button mat-menu-item (click)="addNewCollateralDetail(CollateralType.BA220)">{{ CollateralType.BA220 |
                finprocessEnumTranslation:'CollateralType' }}</button>
        </mat-menu>
    </div>
</div>