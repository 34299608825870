<ng-template #decimalInputTemplate let-entity="entity" let-fieldName="fieldName" let-value="value" let-format="format">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-decimal-input [readonly]="fieldReadonly$" [flexDirection]="'column'" [transparent]="true"
            [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
            [entity]="entity"
            [format]="format ?? '1.2-2'"
            [fieldName]="fieldName">
        </finprocess-decimal-input>
    } @else {
        <div>{{ (value !== undefined && value !== null) ? (value | number: (format ? '1.3-3' : '1.2-2') : locale) : '-' }}</div>
    }
</ng-template>

<ng-template #booleanInputTemplate let-entity="entity" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-boolean-input [readonly]="fieldReadonly$" [flexDirection]="'column'" [transparent]="true"
            [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
            [entity]="entity"
            [fieldName]="fieldName">
        </finprocess-boolean-input>
    } @else {
        <div>{{ value === true ? ('general.yes' | translate) : (value === false ? ('general.no' | translate) : '-') }}</div>
    }
</ng-template>

<ng-template #textareaInputTemplate let-financing="financing" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <div class="textarea-input-wrapper">
            <finprocess-textarea-input
                [flexDirection]="'column'" [transparent]="true"
                [placeholder]="'...'" [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue"
                [readonly]="fieldReadonly$" [entity]="financing" [fieldName]="fieldName">
            </finprocess-textarea-input>
        </div>
    } @else {
        <div>{{ (value !== undefined && value !== null && value !== '') ? value : '-' }}</div>
    }
</ng-template>

<finprocess-two-column-layout leftFxLayoutAlign="start start" rightFxLayoutAlign="center center" rigthClass="pb-0 px-2" leftClass="pb-0 px-2" [flexMd]="65">
    <div left>
        <ucba-table *ngIf="!!selectedRealEstate" [noAlternatingColumnColors]="true">
            <!-- Projektrahmen -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderFinancingScope' | translate}}</div>
            </ng-template>
            <!-- Finanzierungszweck -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.financingPurpose' | translate }}
                </div>
                @if((fieldReadonly$ | async) === false) {
                    <finprocess-select-input [readonly]="fieldReadonly$" [flexDirection]="'column'" [transparent]="true"
                        [overwriteValueClassType]="OverwriteValueClassType.RealEstateOverwriteValue"
                        [valueStorageType]="ValueStorageType.Int" [entity]="selectedRealEstate" [items]="creditPurpose"
                        fieldName="creditPurpose">
                    </finprocess-select-input>
                } @else {
                    <div>{{ findArrayElementWithId(creditPurpose, selectedRealEstate.creditPurpose)?.displayName ?? '-' }}</div>
                }
            </ng-template>
        </ucba-table>
        <ucba-table *ngIf="!!selectedRealEstate && (financing$ | async) as financing" [noAlternatingColumnColors]="true">
            <!-- Projektkosten in EUR -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderFinancingCosts' | translate}}</div>
            </ng-template>
            <!-- Kaufpreis -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.purchasePrice' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'purchasePrice', value: financing.purchasePrice}"></ng-container>
            </ng-template>
            <!-- Kaufnebenkosten relevant -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.purchasePriceRelevantForAdditionalCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'purchasePriceRelevantForAdditionalCosts', value: financing.purchasePriceRelevantForAdditionalCosts}"></ng-container>
            </ng-template>
            <!-- Grundpreis -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.lotPrice' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'lotPrice', value: financing.lotPrice}"></ng-container>
            </ng-template>
            <!-- Kaufnebenkosten relevant -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'lotPriceRelevantForAdditionalCosts', value: financing.lotPriceRelevantForAdditionalCosts}"></ng-container>
            </ng-template>
            <!-- Aufschließungskosten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.developmentCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'developmentCosts', value: financing.developmentCosts}"></ng-container>
            </ng-template>
            <!-- Kaufnebenkosten relevant -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'developmentCostsRelevantForAdditionalCosts', value: financing.developmentCostsRelevantForAdditionalCosts}"></ng-container>
            </ng-template>
            <!-- Baukosten / Küche -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.constructionCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'constructionCosts', value: financing.constructionCosts}"></ng-container>
            </ng-template>
            <!-- Kaufnebenkosten relevant -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.relevantAdditionalCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'developconstructionCostsRelevantForAdditionalCosts', value: financing.constructionCostsRelevantForAdditionalCosts}"></ng-container>
            </ng-template>
            <!-- Renovierungskosten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.refurbishmentCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'refurbishmentCosts', value: financing.refurbishmentCosts}"></ng-container>
            </ng-template>
            <!-- Baukostenüberschreitung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.constructionCostsOverrun' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'constructionCostsOverrun', value: financing.constructionCostsOverrun}"></ng-container>
            </ng-template>
            <!-- Kaufnebenkosten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.additionalCosts' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumAdditionalCosts$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Abdeckung Vorkredit -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.preDebtCoverage' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="currentAmountCoveredLiabilites$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Umschuldungsbetrag -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.reschedulingAmount' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: selectedRealEstate, fieldName: 'reschedulingAmount', value: selectedRealEstate.reschedulingAmount}"></ng-container>
            </ng-template>
            <!-- Einrichtung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.furnishing' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: selectedRealEstate, fieldName: 'otherCosts', value: selectedRealEstate.otherCosts}"></ng-container>
            </ng-template>
            <!-- Summe Projektkosten -->
            <ng-template ucba-table-row ucba-highlighted-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.sumProjectCosts' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumProjectCosts$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Eigenmittel in EUR -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderCapital' | translate}}</div>
            </ng-template>
            <!-- Bar -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.cash' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'cash', value: financing.cash}"></ng-container>
            </ng-template>
            <!-- Summe Verkaufserlös -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.salesRevenue' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'salesRevenue', value: financing.salesRevenue}"></ng-container>
            </ng-template>
            <!-- Verkaufserlös (bestehende Whg., Haus) -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.sumMarketValues' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display
                        [hint]="'financing.features.financing-processing.financing-plan.sumMarketValuesHint' | translate"
                        [numberFunctionObservable]="sumMarketValues$">
                    </finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Vorfinanzierung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.prefinancing' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'prefinancingSales', value: financing.prefinancingSales}"></ng-container>
            </ng-template>
            <!-- Ablösekapital Versicherung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.redemptionInsurance' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'redemptionInsurance', value: financing.redemptionInsurance}"></ng-container>
            </ng-template>
            <!-- Vorfinanzierung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.prefinancing' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'prefinancingInsurance', value: financing.prefinancingInsurance}"></ng-container>
            </ng-template>
            <!-- Bausparguthaben/Restlaufzeit -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.bausparCreditBalance' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'bausparCreditBalance', value: financing.bausparCreditBalance}"></ng-container>
            </ng-template>
            <!-- Vorfinanzierung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.prefinancing' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'prefinancingBausparCreditBalance', value: financing.prefinancingBausparCreditBalance}"></ng-container>
            </ng-template>
            <!-- Summe Eigenmittel -->
            <ng-template ucba-table-row ucba-highlighted-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.sumCapital' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumOwnCapital$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Förderung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.funding' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'funding', value: financing.funding}"></ng-container>
            </ng-template>
            <!-- Vorfinanzierung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.prefinancing' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'prefinancingFunding', value: financing.prefinancingFunding}"></ng-container>
            </ng-template>
            <!-- Sonstige Mittel -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.otherOwnCapital' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'otherOwnCapital', value: financing.otherOwnCapital}"></ng-container>
            </ng-template>
            <!-- Vorfinanzierung -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.prefinancing' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'prefinancingOtherOwnCapital', labelKey: 'prefinancing', value: financing.prefinancingOtherOwnCapital}"></ng-container>
            </ng-template>
            <!-- Finanzierungsbedarf in EUR -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderFinancingNeeds' | translate}}</div>
            </ng-template>
            <!-- Kurzfristiger Finanzierungsbedarf -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.sumPrefinancing' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumPrefinancing$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Langfristiger Finanzierungsbedarf Netto -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.netFinancingRequirement' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="netFinancingRequirement$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Finanzierungsnebenkosten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.sumFinancingAdditionalCharges' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumFinancingAdditionalCharges$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Langfristiger Finanzierungsbedarf Brutto -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.grossFinancingRequirement' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="grossFinancingRequirement$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Langfristiger Finanzierungsbedarf Brutto mit Vorfinanzierungen -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.grossFinancingRequirementUpdate' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="newCalculatedgrossFinancingRequirement$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Monatliche fiktive Rate -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.household.fictionalRateAmount' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="fictionalRateAmount$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Kaufnebenkosten in EUR -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderPurchaseAdditionalCosts' | translate}}</div>
            </ng-template>
            <!-- Grunderwerbsteuer in %  -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.realEstateTransferTaxPercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'realEstatePurchaseTaxes', value: financing.realEstatePurchaseTaxes, format: '1.2-3' }"></ng-container>
            </ng-template>
            <!-- Eintragung Eigentumsrecht in % -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.rightOfOwnershipPercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'landregisterEntryFee', value: financing.landregisterEntryFee, format: '1.2-3' }"></ng-container>
            </ng-template>
            <!-- Eintragung Eigentumsrecht -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.rightOfOwnership' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="landregisterEntry$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Errichtung Kaufvertrag/Treuhand in % -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.notaryFeeInputPercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'notaryFee', value: financing.notaryFee, format: '1.2-3' }"></ng-container>
            </ng-template>
            <!-- Errichtung Kaufvertrag/Treuhand -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.notaryFeeInput' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="notaryCosts$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Maklergebühr in % -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.brokerageFeeInputPercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'brokerageFee', value: financing.brokerageFee, format: '1.2-3' }"></ng-container>
            </ng-template>
            <!-- Maklergebühr -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.brokerageFeeInput' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="brokerageCosts$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Sonstiges -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.otherAdditionalCosts' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: selectedRealEstate, fieldName: 'otherAdditionalCosts', value: selectedRealEstate.otherAdditionalCosts, format: '1.2-3'}"></ng-container>
            </ng-template>
            <!-- Summe Kaufnebenkosten -->
            <ng-template ucba-table-row ucba-highlighted-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.purchasingAdditionalCostsSum' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumAdditionalCosts$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Finanzierungsnebenkosten in EUR -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderFinancingAdditionalCosts' | translate}}</div>
            </ng-template>
            <!-- Bearbeitungsspesen in %  -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.handlingFeePercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'processingChargesPercent', value: financing.processingChargesPercent, format: '1.0-3'}"></ng-container>
            </ng-template>
            <!-- Bearbeitungsspesen -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.handlingFee' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="processingCharges$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Schätzgebühr in % -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.valuationFeePercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'estimateChargesPercent', value: financing.estimateChargesPercent, format: '1.0-3'}"></ng-container>
            </ng-template>
            <!-- Schätzgebühr -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.valuationFee' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="estimateCharges$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Schätzgebühr extern -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.valuationFeeExternal' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'valuationFeeExternal', value: financing.valuationFeeExternal, format: '1.0-3'}"></ng-container>
            </ng-template>
            <!-- Eintragungsgebühr in % -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.registrationChargesAmountPercentage' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'registrationChargesPercent', value: financing.registrationChargesPercent, format: '1.0-3'}"></ng-container>
            </ng-template>
            <!-- Eintragungsgebühr -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.registrationChargesAmount' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="registrationCharges$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Grundbuchgesuch -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.landRegistryRequest' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="landRegistryRequest$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Grundbuchauszug -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.landRegistryExcerpt' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="landRegistryExcerpt$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!--  Legalisierungsgebühr (lt. Notariatstarif) -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.legalisationFee' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="legalisationFee$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!--  Summe Finanzierungsnebenkosten -->
            <ng-template ucba-table-row ucba-highlighted-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.financingAdditionalCostsSum' | translate }}
                </div>
                <div class="calculation-display-wrapper" [class.locked-mode]="!((fieldReadonly$ | async) === false)">
                    <finprocess-calculation-display [numberFunctionObservable]="sumFinancingAdditionalCharges$"></finprocess-calculation-display>
                </div>
            </ng-template>
            <!-- Sicherheiten -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderSecurity' | translate}}</div>
            </ng-template>
            <!-- Sicherheiten vorhanden  -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.guarantees' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="booleanInputTemplate; context: { entity: financing, fieldName: 'guarantees', value: financing.guarantees}"></ng-container>
            </ng-template>
            <!-- Angabe der Sicherheiten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.notesGuarantees' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textareaInputTemplate; context: { financing: financing, fieldName: 'notesGuarantees', value: financing.notesGuarantees}"></ng-container>
            </ng-template>
            <!-- Garantie für Firma -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderCompanyGuarantee' | translate}}</div>
            </ng-template>
            <!-- Betrag -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.guaranteeAmount' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { entity: financing, fieldName: 'guaranteeAmount', value: financing.guaranteeAmount}"></ng-container>
            </ng-template>
            <!-- Termin -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.guaranteeDate' | translate }}
                </div>
                @if((fieldReadonly$ | async) === false) {
                    <finprocess-datepicker-input [readonly]="fieldReadonly$"
                        [flexDirection]="'column'" [transparent]="true"
                        [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                        fieldName="guaranteeDate">
                    </finprocess-datepicker-input>
                } @else {
                    <div>{{ financing.guaranteeDate ? (financing.guaranteeDate | date : 'dd.MM.yyyy') : '-' }}</div>
                }
            </ng-template>
            <!-- Laufzeit in Monaten -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.guaranteeDuration' | translate }}
                </div>
                @if((fieldReadonly$ | async) === false) {
                    <finprocess-integer-input [readonly]="fieldReadonly$"
                        [flexDirection]="'column'" [transparent]="true"
                        [overwriteValueClassType]="OverwriteValueClassType.FinancingMapOverwriteValue" [entity]="financing"
                        fieldName="guaranteeDuration">
                    </finprocess-integer-input> 
                } @else {
                    <div>{{ (financing.guaranteeDuration !== undefined && financing.guaranteeDuration !== null) ? financing.guaranteeDuration : '-' }}</div>
                }
            </ng-template>
            <!-- Anmerkungen zum Finanzplan -->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.financing-plan.subHeaderFinancingNotes' | translate}}</div>
            </ng-template>
            <!-- Anm. zum Finanzierungsplan -->
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">
                    {{ 'financing.features.financing-processing.financing-plan.financingNotes' | translate }}
                </div>
                <ng-container *ngTemplateOutlet="textareaInputTemplate; context: { financing: financing, fieldName: 'financingNotes', value: financing.financingNotes}"></ng-container>
            </ng-template>
        </ucba-table>
        <div *ngIf="isRiskFinancingPlan" class="mt-4">
            <ucba-heading>
                <div ucba-heading-title>{{'financing.features.financing-processing.financing-plan.scoring' | translate}}</div>
                <div ucba-heading-body-visible class="scoring-body-wrapper">
                    <finprocess-scoring></finprocess-scoring>
                </div>
            </ucba-heading>
        </div>
    </div>
    <div right fxFill>
        <finprocess-document-viewer [documentFunction]="documents$"></finprocess-document-viewer>
    </div>
</finprocess-two-column-layout>
