import { Validators } from '@angular/forms';
import { MandantType } from '@ntag-ef/finprocess-enums';
import { FinprocessFormBuilder, createProviderInput } from '@ntag-ef/finprocess-forms';

import { COLT_META_DATA, SALES_PARTNER } from '../forms/colt-provider';
import { IColtMetaData, ISalesPartner } from '../interfaces';
import { ColtValidators } from '../util';

export const salesPartnerForm = FinprocessFormBuilder.createFormGroup<ISalesPartner>({
    salespartnerId: {
        validator: createProviderInput({
            providers: COLT_META_DATA,
            fn: (metaData: IColtMetaData) => (metaData.mandantType === MandantType.BAF ? Validators.compose([Validators.required, ColtValidators.numeric]) : null),
        }),
    },
    externalBusinessHunterId: {
        validator: Validators.compose([ColtValidators.numeric, ColtValidators.maxStringNumber(2147483647)]),
    },
    advisoryServicesDoneBySalespartner: {
        validator: createProviderInput({
            providers: COLT_META_DATA,
            fn: (metaData: IColtMetaData) => (metaData.mandantType === MandantType.BAF ? Validators.required : null),
        }),
    },
}, SALES_PARTNER);
