import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CollateralType, EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { AssetService, FinancingService, IAssetInsurance, SourceCollateral } from 'app/modules/financing/data';
import { HelperService, ISelectItem } from 'app/modules/shared';
import moment from 'moment';

import { AssetCommonComponent } from '../asset-common/asset-common';

/**
 * Asset Insurance
 */
@Component({
    selector: 'finprocess-asset-insurance',
    templateUrl: './asset-insurance.component.html',
    styleUrls: ['./asset-insurance.component.scss'],
})
export class AssetInsuranceComponent extends AssetCommonComponent implements OnInit {

    @Input()
    public assetInsurance: IAssetInsurance | undefined;
    
    public mortgageTypeSelectItems: ISelectItem<number>[] = [];
    
    public assetExpand = false;

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Validators für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Validators = Validators;

    /*
     * CollateralType für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public CollateralType = CollateralType;

    /**
     * SourceCollateral für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SourceCollateral = SourceCollateral;

    public dateFilter = (date: moment.Moment | null): boolean => date instanceof moment && date.date() === 1

    /**
     * Konstruktor
     * 
     * @param {string} locale Locale
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     */
    public constructor(
        @Inject(LOCALE_ID) locale: string,
            enumTranslate: EnumTranslationPipe,
            assetService: AssetService,
            store: Store,
            financingService: FinancingService,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * OnInit
     */
    public ngOnInit() {
        this.mortgageTypeSelectItems = HelperService.getSortedSelectItems(CollateralType, value => this.enumTranslate.transform(value, 'CollateralType') as string, [CollateralType.BA205, CollateralType.BA210, CollateralType.BA220, CollateralType.BA330, CollateralType.BA415, CollateralType.BA425], true);
    }

    /**
     * function used to identify element in array that has a specific id
     * 
     * @param {any[]} array array
     * @param {any} id id
     * @returns {object | undefined} data object or null
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public findArrayElementWithId(array: any[], id: any) {
        return array.find(e => e.value === id);
    }
}
