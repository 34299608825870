import { ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { UcbaHeadingComponent } from '@ntag-ef/components/heading';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetService, AssetState, EntityClassType, FinancingService, IAssetRealEstate, ICollateralAssignmentRealEstates, ICollateralRealEstateDetail, IProductPackageData, IRealEstateDetailDialogData, SourceCollateral } from 'app/modules/financing/data';
import { HelperService, ISelectItem } from 'app/modules/shared';
import { Observable, Subject, of, take, takeUntil } from 'rxjs';

import { CollateralType, ProductPackageType, RealEstateType } from '../../../../../../../../../../../libs/enums/src/core/enums';
import { AssetCommonComponent } from '../../asset-common/asset-common';
import { RealEstateDetailComponent } from '../../real-estate-detail/real-estate-detail.component';


export interface ICollateralTableInterface {
    position: number;
    name: ProductPackageType;
    duration: number | string;
    amount: number;
    collateralized: boolean | undefined;
    id: string;
}

/**
 * Asset Real Estate Details
 */
@Component({
    selector: 'finprocess-collateral-real-estate-details',
    templateUrl: './collateral-real-estate-details.component.html',
    styleUrls: ['./collateral-real-estate-details.component.scss'],
})
export class CollateralRealEstateDetailsComponent extends AssetCommonComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('matSelect') public matSelect!: MatSelect;
    @ViewChild('expandableHeading') public expandableHeading!: UcbaHeadingComponent;

    //Inputs//
    public openThis = false;
    /**
     * Collateral Real Estate Daten
     */
    @Input()
    public collateralRealEstateDetail: ICollateralRealEstateDetail[] | undefined | null;

    /**
     * Asset Real Estate Daten
     */
    @Input() public assetRealEstate: IAssetRealEstate[] | undefined;

    /**
     * Genehmigungsseite
     */
    @Input()
    public approvalView = false;

    public booleanItems: ISelectItem<boolean>[] = [];
    public realEstateTypes: ISelectItem<string>[] = [];

    public entityClassType = EntityClassType;
    public inputFlexDirection: 'column' | 'row' = 'column';

    /**
     * Observable Schreibschutz
     */
    public editingReadonly$!: Observable<boolean>;

    /**
     * Observable Schreibschutz
     */
    public editingReadonlyApprovalView$!: Observable<boolean>;

    /**
     * Mat Expansion Panel Immobilien
     */
    public collateralRealEstateDetailExpand: Record<string, boolean> = {};

    /**
     * Mat Expansion Panel Liegschaften
     */
    public assetsExpand: Record<string, boolean> = {};

    /**
     * ProductPackage
     */
    @Input()
    public productPackage?: IProductPackageData;

    public displayedColumns: string[] = ['position', 'name', 'duration', 'amount', 'collateralized'];
    public dataSources: Map<ICollateralRealEstateDetail, ICollateralTableInterface[]> = new Map();

    /**
     * CollateralType für Anzeige
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public CollateralType = CollateralType;

    /**
     * CollateralType für Anzeige
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Source = SourceCollateral;

    /**
     * FinancingMapID
     */
    public financingMapID?: string;

    /**
     * ProductPackageID
     */
    public productPackageID?: string;

    /**
     * SourceCollateral
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SourceCollateral = SourceCollateral;

    /**
     * Validators
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Validators = Validators;

    /**
     * Requirements failed Map
     */
    public validRealEstatesMap: { [id: string]: boolean } = {};

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Konstruktor
     * 
     * @param {string} locale Locale
     * @param {FinancingService} financingService financingservice
     * @param {TranslateService} translate TranslateService
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {AssetService} assetService assetservice
     * @param {WaiterService} waiterService waiterService
     * @param {NotificationService} notificationService notificationService
     * @param {ChangeDetectorRef} changeDetection changedetection
     * @param {Store} store store
     * @param {MatDialog} dialog MatDialog-Injektor
     */
    public constructor(
        @Inject(LOCALE_ID) locale: string,
            financingService: FinancingService, 
            private translate: TranslateService,
            enumTranslate: EnumTranslationPipe, 
            assetService: AssetService, 
            private waiterService: WaiterService,
            private notificationService: NotificationService, 
            private changeDetection: ChangeDetectorRef, 
            store: Store,
            private dialog: MatDialog,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        );
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.editingReadonly$ = this.approvalView ? of(true) : this.financingService.editingReadonlyWithEditmodeExpert$;
        this.editingReadonlyApprovalView$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.realEstateTypes = HelperService.getSortedSelectItems(RealEstateType, value => RealEstateType.translate(value));
        this.booleanItems = [true, false].map(value => ({
            value: value,
            displayName: value ? 'Ja' : 'Nein',
        }));

        this.defineDataSource();

        //Validate Collateral Insurance
        this.store.select(AssetState.validateCollateralRealEstates).pipe(takeUntil(this.onDestroy$)).subscribe(invalidRealEstates => {
            this.validRealEstatesMap = invalidRealEstates.reduce((acc, realEstates) => {
                acc[realEstates.id] = realEstates.valid;
                return acc;
            }, {} as { [id: string]: boolean });
          
        });

        //Validierung für Genehmigungsseite
        if (this.approvalView === true) {
            this.store.select(AssetState.validateCollateralRealEstatesForApproval).pipe(takeUntil(this.onDestroy$)).subscribe(invalidRealEstates => {
                this.validRealEstatesMap = invalidRealEstates.reduce((acc, realEstates) => {
                    acc[realEstates.id] = realEstates.valid;
                    return acc;
                }, {} as { [id: string]: boolean });
              
            });
        }
    }

    /**
     * ngOnChanges
     *
     * @param {SimpleChanges} changes changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.collateralRealEstateDetail && changes.collateralRealEstateDetail.previousValue !== changes.collateralRealEstateDetail.currentValue) {
            this.defineDataSource();
        }
    }

    /**
     * define Data Source
     */
    public defineDataSource() {
    // Create a map to hold the data source for each real estate
        const dataSources: Map<ICollateralRealEstateDetail, ICollateralTableInterface[]> = new Map();

        if (!!this.productPackage) {
            this.financingMapID = this.productPackage.financingMapID;
            const products = this.productPackage?.assignProductPackages[0].assignProducts;

            this.productPackageID = this.productPackage.assignProductPackages[0].id;

            // Iterate over each real estate
            this.collateralRealEstateDetail?.forEach(detail => {
                const newData: ICollateralTableInterface[] = [];

                //Iterate over products
                products?.forEach(element => {
                    let isCollateralized = false;

                    // Find all productAssignmentInfos with the same productID
                    const productAssignmentInfos = detail.productAssignmentInfos?.find(info => info.productId === element.productID);

                    // If any productAssignmentInfo is found and isUsedAsCollateralForProduct is true, set isCollateralized to true
                    if (productAssignmentInfos && productAssignmentInfos.isUsedAsCollateralForProduct === true) {
                        isCollateralized = true;
                    }
                    else {
                        isCollateralized = false;
                    }

                    const data: ICollateralTableInterface = {
                        position: element.position,
                        name: element.productCreditType,
                        duration: element.productCreditType !== ProductPackageType.Guarantee ? element.durationWithoutASD : '-',
                        amount: element.productCreditType !== ProductPackageType.Guarantee ? element.creditAmount : element.guaranteeAmount,
                        collateralized: isCollateralized,
                        id: element.productID,
                    };

                    newData.push(data);
                });

                dataSources.set(detail, newData);
                this.dataSources = dataSources;
            });
        }
    }

    /**
     * delete Collateral
     * 
     * @param {string} collateralID collateral id
     */
    public deleteCollateral(collateralID: string) {
        this.notificationService.confirmOkCancel(this.translate.instant('asset.assetDetails.deleteCollateralDetail'), this.translate.instant('asset.assetDetails.deleteCollateralDetailQuestion')).subscribe(result => {
            if (result === 'submit' && !!this.productPackageID) {
                this.waiterService.show();
                this.assetService.deleteCollateral(this.productPackageID, collateralID).pipe(take(1)).subscribe({
                    next: data => {
                        if (!!data) {
                            this.collateralRealEstateDetail = data.mortages;
                            this.changeDetection.detectChanges();
                        }
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.waiterService.hide();
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.realEstateDetails.deleteError'));
                    },
                });
            }
        });
    }

    /**
     * assign real estate
     * 
     * @param {string} assignedRealEstateID selected realestate id
     * @param {string} collateralID collateralid
     * @param {boolean} isCheckboxDelete true/false
     * @param {MatOptionSelectionChange} event MatOptionSelectionChange
     */
    public assignRealEstates(assignedRealEstateID: string, collateralID: string, isCheckboxDelete: boolean, event?: MatOptionSelectionChange) {
        if (!!event && !event.isUserInput) {
            return;
        }
        //wird es über die checkbox/dropdown gelöscht oder über button
        if (isCheckboxDelete === true) {
            if (!!this.productPackageID && !!assignedRealEstateID) {
                this.assignCollateralRequest(collateralID, assignedRealEstateID, this.productPackageID, isCheckboxDelete);
            }
        }
        else {
            this.notificationService.confirmOkCancel(this.translate.instant('asset.assetDetails.deleteAssetAssignment'), this.translate.instant('asset.assetDetails.deleteAssetAssignmentQuestion')).subscribe(result => {
                if (result === 'submit' && !!this.productPackageID && !!assignedRealEstateID) {
                    this.assignCollateralRequest(collateralID, assignedRealEstateID, this.productPackageID, isCheckboxDelete);
                }
            });
        }
    }
     
    /**
     * assign real estates request
     * 
     * @param {string} collateralID collateralID
     * @param {string} realEstateID realestateID
     * @param {string} productpackageID productpackageID
     * @param {boolean} openMatSelect openMatSelect
     */
    public assignCollateralRequest(collateralID: string, realEstateID: string, productpackageID: string, openMatSelect: boolean) {
        this.waiterService.show();

        this.assetService.assignCollateral(collateralID, realEstateID, productpackageID).pipe(take(1)).subscribe({
            next: data => {
                if (!!data) {
                    this.collateralRealEstateDetail = data.mortages;
                    this.defineDataSource();
                    this.changeDetection.detectChanges();

                    if (openMatSelect) {
                        this.expandableHeading?.toggleExpanded();
                        this.matSelect?.open();
                    }
                }
                this.waiterService.hide();

                setTimeout(() => {
                    const elementToScroll = document?.getElementById(realEstateID);
                    elementToScroll?.scrollIntoView(({ behavior: 'smooth', block: 'center', inline: 'start' }));

                }, 100);

            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.assignCollateralError'));
            },
        });
    }

    /**
     * check if Source is AGP for overwrite
     * 
     * @param {ICollateralRealEstateDetail} realEstate realEstate
     * @returns {boolean} true/false
     */
    public isSourceCollateralAgp(realEstate: ICollateralRealEstateDetail): boolean {
        return realEstate.source === SourceCollateral.Agp;
    }

    /**
     * show Real Estate Detail
     * 
     * @param {string} realestateId realestateId
     * @param {MouseEvent} event MouseEvent
     */
    public showRealEstateDetail(realestateId: string | undefined, event: MouseEvent): void {
        event.stopPropagation();

        if (!realestateId) {
            return;
        }
        
        const dialogData: IRealEstateDetailDialogData = { realestateId: realestateId, isNotAssetData: true, onlyOne: true };
        //da weiter zentriert wurde, height findet sich auch in global styles und muss dort angepasst werden
        this.dialog.open(RealEstateDetailComponent, { minWidth: '60vw', minHeight: '75vh', data: dialogData });
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
    
    /**
     * Compare Asset Real Estates IDs for Mat-Select
     * 
     * @param {IAssetRealEstate} assetRealEstate Asset Real Estate
     * @param {ICollateralAssignmentRealEstates} assignedRealEstate Assigned Real Estate to Collateral
     * @returns {boolean} true/false
     */
    public compareAssetRealEstates(assetRealEstate: IAssetRealEstate, assignedRealEstate: ICollateralAssignmentRealEstates) {
        return assetRealEstate.id === assignedRealEstate.assetId;
    }
}

