<ng-template #decimalInputTemplate let-required="required" let-label="label" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-decimal-input
            [overwrite]="false"
            [entityClassType]="entityClassType.AssetAccount"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetAccount"
            [transparent]="true"
            subscriptSizing="fixed"
            [validators]="required ? Validators.required : null"
            [allowNegativeValues]="true">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-decimal-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== undefined && value !== null) ? (value | number: '1.2-2' : locale) : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ng-template #integerInputTemplate let-required="required" let-label="label" let-fieldName="fieldName" let-value="value">
    @if((fieldReadonly$ | async) === false) {
        <finprocess-integer-input
            [overwrite]="false"
            [entityClassType]="entityClassType.AssetAccount"
            [fieldName]="fieldName"
            [flexDirection]="inputFlexDirection"
            [hideHorizontalRule]="true"
            [readonly]="fieldReadonly$"
            [entity]="assetAccount"
            [validators]="required ? Validators.required : null"
            [transparent]="true"
            subscriptSizing="fixed">
            <span label class="input-label">{{ label | translate }}</span>
        </finprocess-integer-input>
    } @else {
        <finprocess-label-value>
            <span label>{{ label | translate }}</span>
            <span value>{{ (value !== undefined && value !== null) ? value : '-' }}</span>
        </finprocess-label-value>
    }
</ng-template>

<ucba-heading *ngIf="!!assetAccount" [expandable]="true">
    <div ucba-heading-title>
        <b>
            @if (assetAccount.isBonds) {
                {{ 'asset.assetAccount.securities' | translate }}  
            } @else {
                {{ 'asset.assetAccount.accountBalance' | translate }}
            }
        </b>
    </div>
    <mat-checkbox
        class="heading-checkbox"
        ucba-heading-actions
        #checkboxRef
        color="primary"
        [checked]="assetAccount.isActive"
        (click)="onCheckboxChange(assetAccount.id, !assetAccount.isActive)"
        [class.finprocess-readonly]="(fieldReadonly$ | async) ?? true">
    </mat-checkbox>
    <div ucba-heading-body-visible>
        <ucba-tile-layout>
            @if (assetAccount.isBonds) {
                <!-- materieller Wert gesamt -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetAccount.materialValueTotal', fieldName: 'materialValueTotal', value: assetAccount.materialValueTotal }"></ng-container>
                </ng-template>
    
                <!-- Depotstand -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetAccount.balance', fieldName: 'balance', required: true, value: assetAccount.balance }"></ng-container>
                </ng-template>
    
                <!-- Wertpapierdepotnummer -->
                <ng-template ucba-tile>
                    @if((fieldReadonly$ | async) === false) {
                        <finprocess-textbox-input
                            [overwrite]="false"
                            [entityClassType]="entityClassType.AssetAccount"
                            fieldName="accountNumber"
                            [flexDirection]="inputFlexDirection"
                            [hideHorizontalRule]="true"
                            [readonly]="fieldReadonly$"
                            [entity]="assetAccount"
                            [transparent]="true"
                            subscriptSizing="fixed">
                            <span label class="input-label">{{ 'asset.assetAccount.accountNumber' | translate }}</span>
                        </finprocess-textbox-input>
                    } @else {
                        <finprocess-label-value>
                            <span label>{{ 'asset.assetAccount.accountNumber' | translate }}</span>
                            <span value>{{ assetAccount.accountNumber ?? '-' }}</span>
                        </finprocess-label-value>
                    }
                </ng-template>
            } @else {
                <!-- Kontostand -->
                <ng-template ucba-tile>
                    <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetAccount.balanceBond', fieldName: 'balance', required: true, value: assetAccount.balance }"></ng-container>
                </ng-template>
    
                <!-- Dient das Kontoguthaben ausschließlich für eine Bankgarantie? -->
                <ng-template ucba-tile [span]="4">
                    @if((fieldReadonly$ | async) === false) {
                        <finprocess-select-input
                            fxFlex="50"
                            class="bank-guarantee"
                            [items]="booleanItems"
                            [overwrite]="false"
                            [entityClassType]="entityClassType.AssetAccount"
                            [hideHorizontalRule]="true"
                            fieldName="isExclusivelyCollateral"
                            [readonly]="fieldReadonly$"
                            [entity]="assetAccount"
                            [valueStorageType]="ValueStorageType.Bool"
                            [flexDirection]="inputFlexDirection"
                            [validators]="Validators.required"
                            [transparent]="true"
                            subscriptSizing="fixed">
                            <span label class="input-label">{{ 'asset.assetAccount.isExclusivelyCollateral' | translate }}</span>
                        </finprocess-select-input>
                    } @else {
                        <finprocess-label-value>
                            <span label>{{ 'asset.assetAccount.isExclusivelyCollateral' | translate }}</span>
                            <span value>{{ assetAccount.isExclusivelyCollateral === true ? ('general.yes' | translate) : (assetAccount.isExclusivelyCollateral === false ? ('general.no' | translate) : '-') }}</span>
                        </finprocess-label-value>
                    }
                </ng-template>
            }
        </ucba-tile-layout>
    </div>
    <div ucba-heading-body>
        <!-- Restliche Daten -->
        <ucba-heading [type]="'medium'">
            <div ucba-heading-title>{{ 'asset.assetCommon.otherInfos' | translate }}</div>
            <div ucba-heading-body-visible>
                <ucba-tile-layout>
                    <ng-template ucba-tile>
                        @if (assetAccount.isBonds) {
                            <!-- Belehnsatz aus Geos -->
                                <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetAccount.loanRateGeos', fieldName: 'loanRate', required: true, value: assetAccount.loanRate }"></ng-container>
                        } @else {
                            <!-- Belehnungssatz -->
                            <ng-container *ngTemplateOutlet="integerInputTemplate; context: { label: 'asset.assetAccount.loanRate', fieldName: 'loanRate', required: assetAccount.isExclusivelyCollateral, value: assetAccount.loanRate }"></ng-container>
                        }
                    </ng-template>
    
                    <!-- bereits gen. mat. Wert -->
                    <ng-template ucba-tile *ngIf="assetAccount.source === SourceCollateral.Agp">
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetCommon.materialValueAlreadyInUse', fieldName: 'materialValueAlreadyInUse', value: assetAccount.materialValueAlreadyInUse }"></ng-container>
                    </ng-template>
    
                    <!-- freier materieller Wert -->
                    <ng-template ucba-tile>
                        <ng-container *ngTemplateOutlet="decimalInputTemplate; context: { label: 'asset.assetCommon.freeMaterialValue', fieldName: 'freeMaterialValue', value: assetAccount.freeMaterialValue }"></ng-container>
                    </ng-template>
                </ucba-tile-layout>
            </div>
        </ucba-heading>

        <finprocess-asset-provider
            [entityClassType]="entityClassType.AssetAccount"
            [assetProviders]="assetAccount.assetProviders"
            [assetId]="assetAccount.id"></finprocess-asset-provider>
        <finprocess-asset-agp [assetsAgps]="assetAccount.assetsAgp"></finprocess-asset-agp>
    </div>
</ucba-heading>
