import { animate, group, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, viewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EnumTranslationService, MandantType } from '@ntag-ef/finprocess-enums';
import { DocumentType } from '@ntag-ef/finprocess-enums/finprocess';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { DocumentService, FinancingService, IFinancingStateParentDefinition, IHouseholdOverviewDocument, IHouseholdOverviewDocuments } from 'app/modules/financing/data';
import { FinancingStatus, FinancingSubStatus, HelperService, UUID } from 'app/modules/shared';
import { Observable, Subject, distinctUntilChanged, filter, map, mergeMap, of, take, takeUntil, tap } from 'rxjs';

/**
 * Form Details
 */
@Component({
    selector: 'finprocess-household-bills-view',
    templateUrl: './household-bills-view.component.html',
    styleUrls: ['./household-bills-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expand', [
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                group([
                    animate('180ms ease-in-out', style({ height: '*' })),
                    animate('400ms ease-in', style({ opacity: 1 })),
                ]),
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                group([
                    animate('180ms ease-in-out', style({ height: '0' })),
                    animate('180ms ease-out', style({ opacity: 0 })),
                ]),
            ]),
        ]),
    ],

})
export class HouseholdBillsViewComponent implements OnInit, OnDestroy {

    public documents = viewChildren<ElementRef<HTMLDivElement>>('documents');
    
    public buttonsWrapper = viewChildren<ElementRef<HTMLDivElement>>('buttonsWrapper');

    public householdOverviewDocuments: IHouseholdOverviewDocuments[] | undefined;

    public selectedHouseholdIndex: number = 0;

    public selectedHousehold?: IHouseholdOverviewDocuments;

    public showDocuments: boolean[] = [];

    public financingContainerId: string | undefined;

    public existNotSendedDocument = false;

    public existNotGeneratedDocument = true;

    public existingSendedDocument = false;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    public isStatusNotOpenOrWaiting = false;

    public isBaf: boolean | undefined;

    /**
     * hat Rolle Expert
     */
    public hasRoleExpert?: boolean;

    /**
     * FinAdvisory Fälle
     */
    public isFinAdvisory?: boolean;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Konstruktor
     *
     * @param {WaiterService} waiter waiter service
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {DocumentService} documentService DocumentService-Injektor
     * @param {NotificationService} notification NotificationService-Injektor
     * @param {TranslateService} translate TranslateService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     * @param {EnumTranslationService} enumTranslationService enumTranslationService-Injektor
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef
     * @param {Store} store Store-Injektor
     * @param {AuthorizationService} authorizationService authorizationService
     */
    public constructor(
        private waiter: WaiterService,
        private financingService: FinancingService,
        private documentService: DocumentService,
        private notification: NotificationService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private enumTranslationService: EnumTranslationService,
        private cRef: ChangeDetectorRef,
        private store: Store,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     *
     */
    public ngOnInit(): void {

        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.waiter.show();
        this.hasRoleExpert = this.authorizationService.hasRole(Role.Expert);

        this.activatedRoute.paramMap.pipe(
            takeUntil(this.viewLeft$),
            map(params => params.get('financingContainerId') as string),
            distinctUntilChanged(),
            filter(financingContainerId => financingContainerId !== undefined),
            tap(financingContainerId => { this.financingContainerId = financingContainerId }),
            mergeMap(financingContainerId => this.financingService.getAllHouseholdOVerviewDocuments(financingContainerId).pipe(
                map(docs => {
                    this.householdOverviewDocuments = docs;
                    this.selectedHousehold = docs?.[this.selectedHouseholdIndex];
                    this.updateShowDocumentsArray();
                    //TODO optimize
                    this.existingSendedDocument = (docs ?? []).some(doc => doc.householdOverviewDocumentTypes.find(it => it.documentType !== DocumentType.SelfDisclosure && it.sended !== undefined));
                    this.existNotGeneratedDocument = (docs ?? []).some(doc => doc.householdOverviewDocumentTypes.find(it => it.documentType !== DocumentType.SelfDisclosure && it.generated === undefined));
                    this.existNotSendedDocument = (docs ?? []).some(doc => doc.householdOverviewDocumentTypes.find(it => {
                        if (it.documentType !== DocumentType.SelfDisclosure && !!it.generated && !!it.sended && it.generated > it.sended) {
                            return it;
                        } else if (it.documentType !== DocumentType.SelfDisclosure && !!it.generated && !it.sended) {
                            return it;
                        }
                        return undefined
                    },
                    ));

                    this.cRef.markForCheck(),
                    this.cRef.detectChanges();
                }),
            )),
        ).subscribe({
            next: () => {
                this.waiter.hide();
                this.cRef.markForCheck(),
                this.cRef.detectChanges();
            },
            error: () => {
                this.waiter.hide();
                this.notification.alert(
                    this.translate.instant('general.error'),
                    this.translate.instant('financing.features.financing-processing.householdBillsView.loadDocumentsError'),
                );
            },
        })

        this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer).pipe(
            takeUntil(this.viewLeft$),
            map(finprocessContainer => {

                this.isStatusNotOpenOrWaiting = !(finprocessContainer?.status === FinancingStatus.Open && finprocessContainer?.subStatus === FinancingSubStatus.Editing
                    || finprocessContainer?.status === FinancingStatus.HouseholdCalculationWaitingForAcception && finprocessContainer?.subStatus === FinancingSubStatus.HouseholdCalculationWaitingForAcception)

                this.isBaf = finprocessContainer?.mandantType === MandantType.BAF;
                this.isFinAdvisory = finprocessContainer?.mandantType === MandantType.FinAdvisory;

                this.cRef.markForCheck(),
                this.cRef.detectChanges();
            }),
        ).subscribe();
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * on household change -> get selected household and corresponding data
     *
     * @param {number} index household change index
     */
    public onHouseholdChange(index: number) {
        this.selectedHouseholdIndex = index;
        this.selectedHousehold = this.householdOverviewDocuments?.[this.selectedHouseholdIndex];
        this.updateShowDocumentsArray();
    }

    /**
     * fill showDocuments array with false value for every document type of the selected household
     *
     */
    public updateShowDocumentsArray() {
        this.showDocuments = [];
        if (this.selectedHousehold) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < this.selectedHousehold.householdOverviewDocumentTypes.length; ++i) {
                this.showDocuments.push(false);
            }
        }
    }

    /**
     * toggle documents -> set showDocuments to true or false and ignore click if it is on documents wrapper or buttons wrapper
     *
     * @param {MouseEvent} event event
     * @param {number} index show documents index
     * @param {boolean} isButton event on show button
     */
    public toggleDocuments(event: MouseEvent, index: number, isButton?: boolean) {
        const documents = this.documents();
        const elmButtonsWrapper = this.buttonsWrapper()?.[index]?.nativeElement;
        // clicks on the list element will only fire the toggle if the click is not inside of the documents wrapper or the buttons area
        if (isButton || (event.target instanceof HTMLElement && !elmButtonsWrapper?.contains(event.target))) {
            let noClickAction = false;
            if (!isButton && documents?.length > 0) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < documents.length; ++i) {
                    if (event.target instanceof HTMLElement && documents[i].nativeElement.contains(event.target)) {
                        noClickAction = true;
                    }
                }
            }

            if (noClickAction === false) {
                this.showDocuments[index] = !this.showDocuments[index];
            }
        }
    }

    /**
     * Einen File öffnen
     * 
     * @param {string} id FileId
     * @param {string} mimeType mimeType
     * @param {string} fileName fileName
     */
    public openFile(id?: string, mimeType?: string, fileName?: string) {
        if (!!id) {
            this.waiter.show();
            this.documentService.loadFile(id)
                .subscribe({
                    next: async fileContent => {
                        const blob = HelperService.fileContentToBlob(fileContent, mimeType ?? 'application/pdf');
                        
                        if (blob) {
                            await HelperService.downloadFileFromBlob(blob, fileName || 'Haushaltsdokument');
                        }
                    },
                    complete: () => {
                        this.waiter.hide();
                    },
                    error: () => {
                        this.waiter.hide();
                        this.notification.alert(
                            this.translate.instant('general.error'),
                            this.translate.instant('financing.features.financing-processing.householdBillsView.downloadDocumentsError'),
                        );
                    },
                });
        }
    }

    /**
     * Alle Dokumente senden
     */
    public documentsSend(): void {
        if (!!this.financingContainerId) {
            this.notification.confirmOkCancel(
                this.translate.instant('financing.features.financing-processing.householdBillsView.sendAllDocumentsTitle'),
                this.translate.instant('financing.features.financing-processing.householdBillsView.sendAllDocuments'),
            ).pipe(
                mergeMap(result => {
                    this.waiter.show();

                    if (result !== 'submit' || this.financingContainerId === undefined) {
                        return of(false);
                    }
                    return of(true);

                }),
                mergeMap(isSubmited => {
                    if (isSubmited && !!this.financingContainerId) {
                        if (this.existingSendedDocument) {
                            return this.financingService.setStatus({ id: this.financingContainerId, status: FinancingStatus.HouseholdCalculationWaitingForAcception, subStatus: FinancingSubStatus.HouseholdCalculationUpdateAvailable })
                                .pipe(
                                    map(() => true),
                                )
                        }
                        return this.financingService.setStatus({ id: this.financingContainerId, status: FinancingStatus.HouseholdCalculationWaitingForAcception, subStatus: FinancingSubStatus.HouseholdCalculationWaitingForAcception })
                            .pipe(
                                map(() => true),
                            )
                    }
                    return of(false)
                }),
                mergeMap(statusChanged => {
                    if (statusChanged && !!this.financingContainerId) {
                        return this.financingService.getAllHouseholdOVerviewDocuments(this.financingContainerId).pipe(
                            map(docs => {
                                this.householdOverviewDocuments = docs;
                                this.selectedHousehold = this.householdOverviewDocuments?.[this.selectedHouseholdIndex];
                                this.existNotSendedDocument = false;
                                this.existingSendedDocument = true;
                                this.cRef.markForCheck();
                                this.cRef.detectChanges();
                            }),
                            map(() => true),
                        )
                    }
                    return of(false)

                }),
            ).subscribe({
                next: isSended => {
                    this.waiter.hide();
                    if (isSended !== false) {
                        this.notification.toast(
                            this.translate.instant('financing.features.financing-processing.householdBillsView.succeededDocuments'),
                        );
                        this.cRef.markForCheck(),
                        this.cRef.detectChanges();
                    }
                },
                error: () => {
                    this.waiter.hide();
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('financing.features.financing-processing.householdBillsView.sendDocumentsError'),
                    );
                },
            })
        }
    }

    /**
     * Signiert einen Dokument
     * 
     * @param {MouseEvent} event event 
     * @param {DocumentType} documentType documentType 
     * @param {IHouseholdOverviewDocument[]} files files 
     */
    public signDocument(event: MouseEvent, documentType: DocumentType, files: IHouseholdOverviewDocument[]) {
        event.stopPropagation();
        const docUniqueIDs = [...new Set(files.map(it => it.documentId))] as string[];

        if (Array.isArray(docUniqueIDs)) {
            this.notification.confirmOkCancel(
                this.translate.instant('financing.features.financing-processing.householdBillsView.signDocTitle'),
                this.translate.instant('financing.features.financing-processing.householdBillsView.signDocNotification', {
                    name: this.enumTranslationService.instant({ type: 'DocumentType', value: documentType }),
                }),
                true,
            ).pipe(
                mergeMap(result => {
                    this.waiter.show();

                    if (result === 'submit' && !!this.financingContainerId) {
                        return this.financingService.singDocument({ containerId: this.financingContainerId, documentIds: docUniqueIDs })
                            .pipe(
                                map(() => of(true)),
                            )
                    }
                    return of(false);

                }),
                mergeMap(isSended => {
                    if (!!this.financingContainerId && isSended !== false) {

                        return this.financingService.getAllHouseholdOVerviewDocuments(this.financingContainerId).pipe(
                            map(docs => {
                                this.householdOverviewDocuments = docs;
                                this.selectedHousehold = this.householdOverviewDocuments?.[this.selectedHouseholdIndex];
                            }),
                        )
                    }

                    return of(false);
                }),
            ).subscribe({
                next: isSended => {
                    this.waiter.hide();

                    if (isSended !== false) {
                        this.cRef.markForCheck(),
                        this.cRef.detectChanges();
                    }
                },
                error: () => {
                    this.waiter.hide();
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('financing.features.financing-processing.householdBillsView.signDocumentsError'),
                    );
                },
            })

        }
    }

    /**
     * Dokument hochladen
     * 
     * @param { EventTarget | null} target Event Target
     * @param { DocumentType } type DocumentType
     * @param { string} debtorId debtorId
     * @param { string} oldFileToDeleteId oldFileId to löshen
     */
    public uploadFile(target: EventTarget | null, type: DocumentType, debtorId?: string, oldFileToDeleteId?: string): void {

        if (!!this.financingContainerId && !!debtorId) {
            const tmp = target as HTMLInputElement;


            if (!!tmp.files && tmp.files.length > 1) {
                this.notification.toast(this.translate.instant('financing.features.financing-processing.additionalSheets.maxOneFile'), { duration: 3000 });
                return;
            }
            const file = tmp.files?.item(0);

            if (!!file && (file.size === 0 || file.type !== 'application/pdf')) {
                this.notification.toast(
                    this.translate.instant('general.errorUploadPdfFile'),
                )
                tmp.value = '';
                this.waiter.hide();
                return;
            }

            if (!!file) {
                this.notification.confirmOkCancel(
                    this.translate.instant('financing.features.financing-processing.additionalSheets.uploadDocument'),
                    this.translate.instant('financing.features.financing-processing.additionalSheets.confirmDialogContent', {
                        name: file.name,
                    }),
                ).pipe(
                    take(1),
                    mergeMap(result => {
                        this.waiter.show();

                        if (result === 'submit' && !!this.financingContainerId) {
                            return this.documentService.uploadDebitorFile({ debitorId: debtorId, mapId: this.financingContainerId, file: file, type: type, oldFileId: oldFileToDeleteId ?? undefined })
                        }

                        return of(false)
                    }),
                    mergeMap(result => {
                        if (result !== false && !!this.financingContainerId) {
                            return this.financingService.getAllHouseholdOVerviewDocuments(this.financingContainerId).pipe(
                                map(docs => {
                                    this.householdOverviewDocuments = docs;
                                    this.selectedHousehold = this.householdOverviewDocuments?.[this.selectedHouseholdIndex];
                                }))
                        }

                        return of(false)
                    }),
                ).subscribe({
                    next: isSended => {
                        this.waiter.hide();
                        tmp.value = '';
                        if (isSended !== false) {
                            this.notification.toast(
                                this.translate.instant('financing.features.financing-processing.additionalSheets.documentUploaded'),
                            ),
                            this.cRef.markForCheck();
                            this.cRef.detectChanges();

                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.waiter.hide();

                        if (error.status === HttpStatusCode.MethodNotAllowed) {
                            this.notification.alert(
                                this.translate.instant('general.error'),
                                this.translate.instant('financing.features.financing-processing.additionalSheets.errorDocumentMethodNotAllowed'),
                            );
                        }
                        else {
                            this.notification.alert(
                                this.translate.instant('general.error'),
                                this.translate.instant('financing.features.financing-processing.additionalSheets.errorDocument'),
                            );
                        }
                        tmp.value = '';
                        this.cRef.markForCheck();
                        this.cRef.detectChanges();
                    },
                })
            }
        }
    }

    /**
     * Selbstauskunft generieren
     *
     * @param {UUID} debitorID debitor ID
     */
    public generateSelfDisclosure(debitorID: UUID | undefined) {
        if (!!debitorID && !!this.financingContainerId) {
            this.financingService.generateSelfDisclosure(debitorID, this.financingContainerId).pipe(take(1)).subscribe({
                next: result => {
                    if (!!result) {
                        this.notification.toast(
                            this.translate.instant('financing.features.financing-processing.additionalSheets.selfDisclosureSucess'),
                        )
                        this.reloadDocuments();
                    }
                },
                error: () => {
                    this.notification.alert(
                        this.translate.instant('general.error'),
                        this.translate.instant('financing.features.financing-processing.additionalSheets.selfDisclosureError'),
                    );
                },
            });
        }
    }

    /**
     * Reload Documents
     */
    private reloadDocuments() {
        if (!!this.financingContainerId) {
            this.waiter.show();
            this.financingService.getAllHouseholdOVerviewDocuments(this.financingContainerId).pipe(
                take(1),
            ).subscribe(docs => {
                this.householdOverviewDocuments = docs;
                this.selectedHousehold = this.householdOverviewDocuments?.[this.selectedHouseholdIndex];
                this.waiter.hide();
            });
        }
    }

}
