<mat-sidenav-container hasBackdrop="false" autosize class="financing-container h-100 w-100">
    <mat-sidenav #drawer mode="side" opened class="bg-dark-2">
       
        <div fxLayout="row" *ngIf="!!headerInformation">
            <div class=" ml-3 mt-2" fxLayout="column" fxAlign="center">
                <mat-icon class="mr-1">real_estate_agent</mat-icon>
            </div>

            <div fxLayout="column" class="ml-3">
                <div fxLayout="row" fxLayoutAlign="start" style="font-size: 11px;">
                    <div>
                        {{ headerInformation.firstName || '-' }} {{ headerInformation.lastName || '-' }}, Telefon: {{ headerInformation.telephone || '-' }}
                    </div>
                </div>
                <div  fxLayout="row" fxLayoutAlign="start" style="font-size: 11px; margin-top: -10px;">
                    <div>{{ headerInformation.companyName || '-' }}</div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
       
        <div class="financing-container-nav-list-wrapper" fxLayout="column" fxLayoutAlign="start center" fxFill>
            <mat-nav-list>
                <mat-list-item (click)="openMessages()">
                    <mat-icon matListItemIcon>chat_bubble_outline</mat-icon>
                    &nbsp; {{ 'navigation.financing.messages' | translate }}
                </mat-list-item>
                <mat-list-item
                    [routerLink]="'financingmap/' + defaultFinancingMapId"
                    [queryParams]="{ mode: FinancingMode.Submission }"
                    routerLinkActive
                    [activated]="isActiveFinancingMap.isActive"
                    #isActiveFinancingMap="routerLinkActive">
                    <mat-icon matListItemIcon>folder_open</mat-icon>
                    &nbsp; {{ 'navigation.financing.financingmap.title' | translate }}
                </mat-list-item>

                <div [class.disabled]="isLiveEnvironment">
                    <mat-list-item style="pointer-events: none">
                        <mat-icon matListItemIcon>person</mat-icon>
                        &nbsp;&nbsp;
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <span>{{ 'navigation.financing.borrowerCheck.title' | translate }}</span>
                            <button mat-icon-button style="pointer-events: all">
                                <mat-icon style="cursor: pointer; z-index: 2" (click)="borrowerCheck = !borrowerCheck">expand_more</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                    <mat-expansion-panel [expanded]="borrowerCheck">
                        <mat-list-item
                            [routerLink]="'borrower-check-customer-data/' + defaultFinancingMapId"
                            routerLinkActive
                            [activated]="isActiveCustomerData.isActive"
                            #isActiveCustomerData="routerLinkActive">
                            {{ 'navigation.financing.borrowerCheck.customer-data' | translate }}
                        </mat-list-item>
                        <mat-list-item
                            [routerLink]="'borrower-check-income/' + defaultFinancingMapId"
                            routerLinkActive
                            [activated]="isActiveIncome.isActive"
                            #isActiveIncome="routerLinkActive">
                            {{ 'navigation.financing.borrowerCheck.income' | translate }}
                        </mat-list-item>
                    </mat-expansion-panel>

                    <mat-list-item style="pointer-events: none">
                        <mat-icon matListItemIcon>account_balance_wallet</mat-icon>
                        &nbsp;&nbsp;
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <span>{{ 'financing.features.financing-processing.liabilities.title' | translate }}</span>
                            <button mat-icon-button style="pointer-events: all">
                                <mat-icon style="cursor: pointer; z-index: 2" (click)="liabilitiesExpanded = !liabilitiesExpanded">expand_more</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                    <mat-expansion-panel [expanded]="liabilitiesExpanded">
                        <mat-list-item
                            routerLink="liabilities"
                            routerLinkActive
                            [activated]="isActiveLiabilities.isActive"
                            #isActiveLiabilities="routerLinkActive">
                            {{ 'navigation.financing.liabilities' | translate }}
                        </mat-list-item>
                        <mat-list-item
                            routerLinkActive
                            routerLink="liabilities-overview"
                            [activated]="isActiveLiabilitiesOverview.isActive"
                            #isActiveLiabilitiesOverview="routerLinkActive">
                            {{ 'navigation.financing.liabilities-overview' | translate }}
                        </mat-list-item>
                    </mat-expansion-panel>

                    <mat-list-item style="pointer-events: none">
                        <mat-icon>payments</mat-icon>
                        &nbsp;&nbsp;
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <span>{{ 'navigation.financing.household-bills' | translate }}</span>
                            <button mat-icon-button style="pointer-events: all">
                                <mat-icon style="cursor: pointer; z-index: 2" (click)="householdCalculationsExpanded = !householdCalculationsExpanded"
                                    >expand_more</mat-icon
                                >
                            </button>
                        </div>
                    </mat-list-item>
                    <mat-expansion-panel [expanded]="householdCalculationsExpanded">
                        <mat-list-item routerLink="household-bills" routerLinkActive [activated]="isActiveBills.isActive" #isActiveBills="routerLinkActive">
                            <span>
                                {{ 'navigation.financing.household-bills' | translate }}
                            </span>
                        </mat-list-item>

                        <mat-list-item
                            routerLink="additional-sheets"
                            routerLinkActive
                            [activated]="isActiveAdditionalSheets.isActive"
                            #isActiveAdditionalSheets="routerLinkActive">
                            <span>
                                {{ 'navigation.financing.additional-sheets' | translate }}
                            </span>
                        </mat-list-item>

                        <mat-list-item
                            routerLink="household-bills-view"
                            routerLinkActive
                            [activated]="isActiveBillsView.isActive"
                            #isActiveBillsView="routerLinkActive">
                            <span>
                                {{ 'navigation.financing.householdBillsView' | translate }}
                            </span>
                        </mat-list-item>
                    </mat-expansion-panel>

                    <mat-list-item
                        routerLink="general-assets"
                        routerLinkActive
                        [activated]="isActiveGeneralAssets.isActive"
                        #isActiveGeneralAssets="routerLinkActive">
                        <mat-icon matListItemIcon>security</mat-icon>
                        &nbsp; {{ 'navigation.financing.general-assets' | translate }}
                    </mat-list-item>

                    <mat-list-item
                        routerLink="riskfinancingplans"
                        routerLinkActive
                        [activated]="isActiveRiskfinancingPlans.isActive"
                        #isActiveRiskfinancingPlans="routerLinkActive">
                        <mat-icon>create_new_folder</mat-icon>
                        &nbsp;&nbsp;
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <span>{{ 'navigation.financing.riskfinancingplans' | translate }}</span>
                            <button mat-icon-button>
                                <mat-icon style="cursor: pointer; z-index: 2" (click)="rfpExpanded = !rfpExpanded">expand_more</mat-icon>
                            </button>
                        </div>
                    </mat-list-item>
                    <mat-expansion-panel [expanded]="rfpExpanded">
                        <mat-list-item
                            class="sub-item"
                            *ngFor="let riskFinancingPlan of riskFinancingPlans; let i = index"
                            [routerLink]="'riskfinancingplan/' + riskFinancingPlan.id"
                            [queryParams]="{ mode: FinancingMode.RiskFinancingPlan }"
                            routerLinkActive
                            [activated]="riskFinancingPlanRouterLink.isActive"
                            #riskFinancingPlanRouterLink="routerLinkActive"
                            [matTooltip]="riskFinancingPlan.description"
                            [routerLinkActiveOptions]="{ exact: false }">
                            <mat-icon></mat-icon>
                            &nbsp; {{ riskFinancingPlan.description }}
                        </mat-list-item>
                    </mat-expansion-panel>
                </div>
                <mat-list-item
                    routerLink="customer-center"
                    routerLinkActive
                    [activated]="isActiveCustomerCenter.isActive"
                    #isActiveCustomerCenter="routerLinkActive">
                    <mat-icon matListItemIcon>business_center</mat-icon>
                    &nbsp; {{ 'navigation.financing.customer-center.title' | translate }}
                </mat-list-item>

                <mat-list-item routerLink="colt" routerLinkActive [activated]="isActiveColt.isActive" #isActiveColt="routerLinkActive">
                    <mat-icon matListItemIcon>ballot</mat-icon>
                    &nbsp; {{ 'navigation.financing.colt.title' | translate }}
                </mat-list-item>

                <mat-list-item routerLink="esis" routerLinkActive [activated]="isActiveEsis.isActive" #isActiveEsis="routerLinkActive">
                    <mat-icon matListItemIcon>assignment</mat-icon>
                    &nbsp; {{ 'navigation.financing.esis' | translate }}
                </mat-list-item>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
        <waiter-component [isLoading]="loading"></waiter-component>
    </div>
    <div *ngIf="!loading" class="p-2 sidenav-content">
        <div fxLayout="row" fxLayoutAlign="start baseline">
            <div fxFlex="85" fxLayout="column">
                <div *ngIf="debitors.length > 0" class="ml-3">
                    <div class="debitor-container">
                        <div class="groups-element" fxLayout="column" fxLayoutAlign="center">
                            <button class="mb-1 debitor-button" mat-icon-button (click)="toggleExpansion()" [disabled]="debitors.length <= 2">
                                <div *ngIf="debitors.length > 2" class="debitor-icon">
                                    <mat-icon color="primary">groups</mat-icon>
                                </div>
                                <div *ngIf="debitors.length < 2" class="debitor-icon">
                                    <mat-icon color="primary">person</mat-icon>
                                </div>
                                <div *ngIf="debitors.length === 2" class="debitor-icon">
                                    <mat-icon color="primary">group</mat-icon>
                                </div>
                            </button>

                            <h5 class="light-grey" style="margin-top: 0; padding-top: 0; line-height: 1">
                                {{ 'dashboard.features.dashboard.communityCustomerNumber' | translate }}: {{ communityCustomerNumber ?? '-' }}
                            </h5>
                        </div>

                        <div *ngFor="let debitor of debitors; let i = index" class="debitor-item" [class.hidden]="!isExpanded() && i >= 2">
                            <h3 class="color-primary mb-1" style="margin: 0">{{ debitor.firstName }} {{ debitor.lastName }}</h3>
                            <h5 class="light-grey" style="line-height: 1">{{ debitor.customerNumber }}</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column">
                <div fxLayout="row">
                    <button
                        *ngIf="(editingReadonly$ | async) === false"
                        mat-mini-fab
                        color="primary"
                        (click)="toggleEditMode()"
                        [matTooltip]="
                            (fieldReadonly$ | async) === false
                                ? ('financing.features.financing-processing.lockEditMode' | translate)
                                : ('financing.features.financing-processing.unlockEditMode' | translate)
                        ">
                        <mat-icon>{{ (fieldReadonly$ | async) === false ? 'lock_open' : 'lock' }}</mat-icon>
                    </button>

                    <button
                        class="ml-3"
                        mat-mini-fab
                        color="primary"
                        [matTooltip]="'financing.features.financing-processing.menu' | translate"
                        [matMenuTriggerFor]="multiMenu">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <mat-menu #multiMenu>
                        <button mat-menu-item (click)="downloadAllFiles()" [disabled]="(isEditor$ | async) === false">
                            <mat-icon>file_download</mat-icon>
                            <span>{{ 'financing.features.financing-processing.downloadSubmittedDocs' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="financingMapExport()" [disabled]="(isEditor$ | async) === false && !isAdminOrLeader()">
                            <mat-icon>file_download</mat-icon>
                            <span>{{ 'financing.features.financing-processing.exportFinancingFolder' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="openEditHistory()">
                            <mat-icon>timeline</mat-icon>
                            <span>{{ 'financing.features.financing-processing.editingHistory' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="cancelContract()" [disabled]="cancelReadonly$ | async">
                            <mat-icon>cancel</mat-icon>
                            <span>{{ 'financing.features.financing-processing.cancelOrder' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="openSmartdoc()" [disabled]="smartdocDisabled$ | async">
                            <mat-icon>content_cut</mat-icon>
                            <span>SmartDoc</span>
                        </button>
                        <button mat-menu-item (click)="smartDocExport()" [disabled]="(isSmartDocExportButtonActive$ | async) === false">
                            <mat-icon>file_download</mat-icon>
                            <span>{{ 'financing.features.financing-processing.downloadSmartDoc' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="allowSubmission()" *ngIf="(showEscalation$ | async) ?? false">
                            <mat-icon>restart_alt</mat-icon>
                            <span>{{ 'financing.features.financing-processing.allowFiling' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="openProcess()" [disabled]="disableAllowance || (isEditor$ | async) === false">
                            <mat-icon>account_tree</mat-icon>
                            <span>{{ 'financing.features.financing-processing.allowance ' | translate }}</span>
                        </button>
                        <ng-container *finprocessAuthorization="Role.FinancingMapsEditor">
                            <button *ngIf="!isLiveEnvironment" mat-menu-item (click)="toWorkflowTool()">
                                <mat-icon>developer_board</mat-icon>
                                <span>{{ 'financing.features.financing-processing.workflowTool' | translate }}</span>
                            </button>
                        </ng-container>
                        <button mat-menu-item (click)="toSampleCalculation()">
                            <mat-icon>receipt_long</mat-icon>
                            <span>{{ 'financing.features.financing-processing.productCalculator' | translate }}</span>
                        </button>
                        <button
                            *ngIf="mandantType !== undefined && mandantType === MandantType.FinAdvisory"
                            mat-menu-item
                            (click)="openGeneralComponentDialog()">
                            <mat-icon>language</mat-icon>
                            <span>{{ 'financing.features.financing-processing.generalComponent' | translate }}</span>
                        </button>
                        <button *ngIf="mandantType !== undefined && mandantType === MandantType.BAF" mat-menu-item (click)="openFormalDialogComponent()">
                            <mat-icon>business_center</mat-icon>
                            <span>{{ 'financing.features.financing-processing.formalComponent' | translate }}</span>
                        </button>
                    </mat-menu>
                    <button
                        (click)="close()"
                        class="ml-3 bg-dark"
                        mat-mini-fab
                        color="primary"
                        [matTooltip]="'financing.features.financing-processing.close' | translate">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="router-height">
            <router-outlet></router-outlet>
        </div>
    </div>
</mat-sidenav-container>
