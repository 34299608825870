<div class="waiter w-100" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <waiter-component [isLoading]="loading"></waiter-component>
</div>
<div *ngIf="!loading" fxLayout="column">
    <ng-container *ngIf="(productPackage$ | async) as productPackage">
        <!-- Hinweistexte -->  
        <section
            *ngIf="productPackage.status >= ProductPackageStatus.DecisionSuccesful && !approvalCompetenceLoading">
            <finprocess-sub-header *ngIf="productPackage.inApprovalPolicies === undefined && approvalCompetence$.value !== ApprovalCompetence.BackOffice" 
                [label]="'financing.features.financing-processing.approval.determineCompetence' | translate">
            </finprocess-sub-header>
            <finprocess-sub-header *ngIf="productPackage.inApprovalPolicies === false || approvalCompetence$.value === ApprovalCompetence.BackOffice" 
                [label]="'financing.features.financing-processing.approval.marketCompetence' | translate">
            </finprocess-sub-header>
            <finprocess-sub-header *ngIf="productPackage.inApprovalPolicies === true && approvalCompetence$.value !== ApprovalCompetence.BackOffice"
                label="{{'financing.features.financing-processing.approval.salesCompetence' | translate}}:&nbsp;
                    {{  !approvalCompetenceLoading ?
                        (approvalCompetence$.value === ApprovalCompetence.SelfCompetence ? ('financing.features.financing-processing.approval.ownCompetence' | translate) : 
                        (approvalCompetence$.value === ApprovalCompetence.ExternalCompetence ? ('financing.features.financing-processing.approval.externalCompetence' | translate) : '')) : ''
                    }}
                "
            ></finprocess-sub-header>
            <div class="section-padding-horizontal">
                <ng-container *ngIf="approvalCompetence$.value !== ApprovalCompetence.BackOffice">
                    <finprocess-hint *ngIf="productPackage.inApprovalPolicies === undefined">
                        <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                            translate}}</span>
                        <p content>{{'financing.features.financing-processing.approval.infoDetermineCompetence' |
                            translate}}</p>
                    </finprocess-hint>
                    <finprocess-hint type="warn" *ngIf="productPackage.inApprovalPolicies === false">
                        <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                            translate}}</span>
                        <p content>
                            <span *ngIf="productPackageSumHint">{{ productPackageSumHint}} </span>
                            {{'financing.features.financing-processing.approval.infoMarketCompetence' | translate}}
                        </p>
                    </finprocess-hint>
                    <finprocess-hint type="info"
                        *ngIf="productPackage.inApprovalPolicies === true && approvalCompetence$.value === ApprovalCompetence.SelfCompetence">
                        <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                            translate}}</span>
                        <p content>{{ productPackageMinCompetence?.levelAddition }}</p>
                    </finprocess-hint>
                    <finprocess-hint type="warn"
                        *ngIf="productPackage.inApprovalPolicies === true && approvalCompetence$.value === ApprovalCompetence.ExternalCompetence">
                        <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                            translate}}</span>
                        <p content>{{ productPackageMinCompetence?.levelAddition }}</p>
                    </finprocess-hint>
                </ng-container>
                <finprocess-hint type="warn" *ngIf="approvalCompetence$.value === ApprovalCompetence.BackOffice">
                    <span header>{{'financing.features.financing-processing.approval.minimumCompetence' |
                    translate}}</span>
                    <p content>
                        <span *ngIf="productPackageSumHint">{{ productPackageSumHint}} </span>
                        {{'financing.features.financing-processing.approval.infoMarketCompetence' | translate}}
                    </p>
                </finprocess-hint>
            </div>
        </section>
        <waiter-component *ngIf="approvalCompetenceLoading"
            [isLoading]="approvalCompetenceLoading"></waiter-component>
        <ng-container *ngIf="productPackage.status === ProductPackageStatus.DecisionFailed">
            <finprocess-hint type="error" *ngIf="(fireData$ | async) as fireData">
                <span header>{{'financing.features.financing-processing.approval.fireDecisionFailed' |
                    translate}}</span>
                <p content
                    innerHTML="{{'financing.features.financing-processing.approval.fireDecisionFailedLong' | translate:fireData}}">
                </p>
            </finprocess-hint>
        </ng-container>


        <!-- Fire Anzeigewerte -->
        <section>
            <finprocess-sub-header [label]="'financing.features.financing-processing.approval.fireDecision' | translate"></finprocess-sub-header>
            <ucba-tile-layout>
                <ng-template ucba-tile>
                    @if(fireDataLoading) {
                        <div class="waiter w-100 h-100" fxLayout="row" fxLayoutAlign="center center">
                            <waiter-component style="height: 50px;" [isLoading]="fireDataLoading"></waiter-component>
                        </div>
                    } @else {
                        <finprocess-label-value *ngIf="(fireData$ | async) as fireData">
                            <span label>{{'financing.features.financing-processing.approval.additionalCode' | translate}}</span>
                            <span value>{{ fireData.ergAdditionalCode | finprocessEnumTranslation:'AdditionalCode'}}</span>
                        </finprocess-label-value>
                    }
                </ng-template>
                <ng-template ucba-tile>
                    @if(fireDataLoading) {
                        <div class="waiter w-100 h-100" fxLayout="row" fxLayoutAlign="center center">
                            <waiter-component style="height: 50px;" [isLoading]="fireDataLoading"></waiter-component>
                        </div>
                    } @else {
                        <finprocess-label-value *ngIf="(fireData$ | async) as fireData">
                            <span label>{{'financing.features.financing-processing.approval.reasonCode' | translate}}</span>
                            <span value>{{ fireData.reasonCode | finprocessEnumTranslation:'ReasonCode' }}</span>
                        </finprocess-label-value>
                    }
                </ng-template>
                <ng-template ucba-tile [span]="2">
                    @if(!!!(fireDocumentData$ | async)) {
                        <div class="waiter w-100 h-100" fxLayout="row" fxLayoutAlign="center center">
                            <waiter-component style="height: 50px;" [isLoading]="!!!(fireDocumentData$ | async)"></waiter-component>
                        </div>
                    } @else {
                        <finprocess-label-value>
                            <span label>{{'financing.features.financing-processing.approval.fireDocument' | translate}}</span>
                            <div value fxLayout="row" fxLayoutAlign="start center">
                                <ng-container *ngIf="(fireDocumentData$ | async) as fireDocumentData">
                                    <span *ngIf="!fireDocumentData || fireDocumentData.fileName === undefined">
                                        {{'financing.features.financing-processing.approval.nofireDocument' | translate}}
                                    </span>
                                    <ng-container *ngIf="!!fireDocumentData && fireDocumentData.fileName !== undefined">
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <div fxLayout="row">
                                                <mat-icon class="mr-1 document-icon">description</mat-icon>
                                                <div class="mr-4">{{fireDocumentData?.fileName}}.pdf</div>
                                            </div>
                                            <button mat-icon-button
                                                [disabled]="!fireDocumentData?.fileId || (fieldReadonlyExpert$ | async) ?? true"
                                                color="primary" (click)="downloadFireDocument(fireDocumentData)">
                                                <mat-icon class="mr-2" [matTooltip]="!fireDocumentData?.fileId ? 'Kein Dokument vorhanden' : 'Öffnen'">
                                                    open_in_new
                                                </mat-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </finprocess-label-value>
                    }
                </ng-template>
                <ng-template ucba-tile *ngIf="productPackage.status === ProductPackageStatus.ApprovalGrantedOnCondition || productPackage.status === ProductPackageStatus.ApprovalGranted">
                    <finprocess-label-value>
                        <span label>{{'financing.features.financing-processing.approval.approvedBy' | translate}}</span>
                        <span value>
                            @if(!!productPackage.approver && !!productPackage.approver.firstName && !!productPackage.approver.lastName) {
                                {{productPackage.approver.firstName}} {{productPackage.approver.lastName}}
                            } @else {
                                {{'financing.features.financing-processing.approval.noApproverName' | translate}}
                            }
                        </span>
                    </finprocess-label-value>
                </ng-template>
                <ng-template ucba-tile *ngIf="productPackage.status >= ProductPackageStatus.DecisionSuccesful && (fireSalesCompetence$ | async) === true && approvalCompetence$.value !== ApprovalCompetence.BackOffice">
                    <finprocess-boolean-input
                        class="approval-input" flexDirection="column" [hideHorizontalRule]="true" [entity]="productPackage"
                        fieldName="inApprovalPolicies" [overwrite]="false"
                        [entityClassType]="EntityClassType.ProductPackage" [readonly]="inApprovalPoliciesReadonly$">
                        <span label class="input-label">
                            {{'financing.features.financing-processing.approval.inApprovalGuidelines' | translate}}
                        </span>
                    </finprocess-boolean-input>
                </ng-template>
            </ucba-tile-layout>
        </section>

        <!-- Export -->
        <section
            *ngIf="productPackage.inApprovalPolicies === false || (fireSalesCompetence$ | async) === false || approvalCompetence$.value === ApprovalCompetence.BackOffice">
            <finprocess-sub-header label="Export der Finanzierungsmappe"></finprocess-sub-header>
            <div class="section-padding-horizontal">
                <button mat-raised-button color="primary" [disabled]="(fieldReadonlyExpert$ | async) ?? true || collateralRequirementSuccessful === false"
                    (click)="downloadExcelExport()">Exportieren</button>
            </div>
        </section>

        <!-- Zusammenfassung Sicherheitsdetails -->
        <section>
            <finprocess-sub-header label="Zusammenfassung der Sicherheitendetails"></finprocess-sub-header>
            <finprocess-productpackage-collateral [approvalView]="true"></finprocess-productpackage-collateral>
        </section>

        <!--Mittelverwendung-->
        <section>
            <finprocess-sub-header [label]="'financing.features.financing-processing.approval.capitalUse' | translate"></finprocess-sub-header>
            <ucba-table>
                <ng-template ucba-table-header>
                    <div>{{'financing.features.financing-processing.approval.category' | translate}}</div>
                    <div>{{'financing.features.financing-processing.approval.value' | translate}}</div>
                </ng-template>
                @for(source of useOfFundsDataSource.data; track $index) {
                    @if($index === useOfFundsDataSource.data.length - 1) {
                        <ng-template ucba-table-row ucba-highlighted-table-row>
                            <div>{{source.category}}</div>
                            <div>{{source.amount | currency:'EUR'}}</div>
                        </ng-template>
                    } @else {
                        <ng-template ucba-table-row>
                            <div>{{source.category}}</div>
                            <div>{{source.amount | currency:'EUR'}}</div>
                        </ng-template>
                    }
                }
            </ucba-table>
        </section>

        <!--Mittelherkunft-->
        <section>
            <finprocess-sub-header [label]="'financing.features.financing-processing.approval.capitalSource' | translate"></finprocess-sub-header>
            <ucba-table>
                <ng-template ucba-table-header>
                    <div>{{'financing.features.financing-processing.approval.category' | translate}}</div>
                    <div>{{'financing.features.financing-processing.approval.value' | translate}}</div>
                </ng-template>
                @for(source of sourceOfFundsDataSource.data; track $index) {
                    @if($index === sourceOfFundsDataSource.data.length - 1) {
                        <ng-template ucba-table-row ucba-highlighted-table-row>
                            <div>{{source.category}}</div>
                            <div>{{source.amount | currency:'EUR'}}</div>
                        </ng-template>
                    } @else {
                        <ng-template ucba-table-row>
                            <div>{{source.category}}</div>
                            <div>{{source.amount | currency:'EUR'}}</div>
                        </ng-template>
                    }
                }
            </ucba-table>
        </section>

        <!-- Stellungnahme Experte -->
        <section fxLayout="column" *ngIf="expertStatementStatus.visible">
            <finprocess-sub-header [label]="'financing.features.financing-processing.approval.expertStatement' | translate"></finprocess-sub-header>
            <div class="section-padding-horizontal" fxLayout="column" fxFlex fxFlex.gt-sm="80" fxLayoutGap="12px">
                <ckeditor fxFlex class="statement-editor" [editor]="Editor" (change)="true"
                    [(ngModel)]="expertStatement" name="expertStatement"
                    [disabled]="expertStatementStatus.readonly">
                </ckeditor>

                <div fxFlexAlign="end" fxLayout="column">
                    <mat-form-field appearance="fill"
                        *ngIf="approvalCompetence$.value === ApprovalCompetence.ExternalCompetence || selectedAction?.value === SelfCompetenceAction.Transfer">
                        <input type="text" matInput [(ngModel)]="approver"
                            placeholder="{{'financing.features.financing-processing.approval.approver'}}"
                            [matAutocomplete]="autocompleteApprover" (ngModelChange)="filterEditors($event)"
                            [disabled]="expertStatementStatus.readonly || collateralRequirementSuccessful === false" name="approver">
                        <mat-autocomplete #autocompleteApprover="matAutocomplete" [displayWith]="getNameFromUser">
                            <mat-option *ngFor="let approver of (approversFiltered$ | async)" [value]="approver">
                                {{ approver.firstName }} {{ approver.lastName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button *ngIf="approvalCompetence$.value === ApprovalCompetence.ExternalCompetence"
                        mat-raised-button color="primary"
                        [disabled]="!approver || !expertStatement || expertStatementStatus.readonly || collateralRequirementSuccessful === false"
                        (click)="setToApprovalAwaiting()">
                        {{'financing.features.financing-processing.approval.transferToApprover' | translate}}
                    </button>
                    <finprocess-select-button
                        *ngIf="approvalCompetence$.value === ApprovalCompetence.SelfCompetence" [actions]="actions"
                        class="select-button self-competence" name="selectedAction" [(ngModel)]="selectedAction"
                        color="primary" [disabled]="expertStatementStatus.readonly" name="selectedAction">
                    </finprocess-select-button>
                </div>
            </div>
        </section>

        <!-- Stellungnahme Genehmiger -->
        <div fxLayout="column" class="mb-5" *ngIf="approverStatementStatus.visible">
            <finprocess-sub-header [label]="'financing.features.financing-processing.approval.approverStatement' | translate"></finprocess-sub-header>
            <div class="section-padding-horizontal" fxLayout="column" fxFlex fxFlex.gt-sm="80" fxLayoutGap="12px">
                <ckeditor class="statement-approver" fxFlex [editor]="Editor" (change)="true"
                    [(ngModel)]="approverStatement" [disabled]="approverStatementStatus.readonly"
                    name="approverStatement">
                </ckeditor>
                <div fxFlexAlign="end" fxLayout="column">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'financing.features.financing-processing.approval.decision' |
                            translate}}</mat-label>
                        <mat-select [(ngModel)]="approvalDecision" [disabled]="approverStatementStatus.readonly || collateralRequirementSuccessful === false"
                            name="approvalDecision">
                            <mat-option *ngFor="let decision of approvalDecisions" [value]="decision">
                                {{ decision | finprocessEnumTranslation:'ApprovalDecision'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="primary"
                        [disabled]="approvalDecision === undefined || !approverStatement || approverStatementStatus.readonly || collateralRequirementSuccessful === false"
                        (click)="setApprovalDecision()">
                        <ng-container [ngSwitch]="approvalDecision">
                            <span *ngSwitchCase="ApprovalDecision.Approved">
                                {{'financing.features.financing-processing.approval.actionApprove' | translate}}</span>
                            <span *ngSwitchCase="ApprovalDecision.NotApproved">
                                {{'financing.features.financing-processing.approval.actionDeny' | translate}}</span>
                            <span *ngSwitchCase="ApprovalDecision.OpenQuestions">
                                {{'financing.features.financing-processing.approval.actionReject' | translate}}</span>
                            <span *ngSwitchDefault>
                                {{'financing.features.financing-processing.approval.action' | translate}}
                            </span>
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>