export enum UnknownCostsType {
    /**
     * Kosten für Feuerversicherung
     * Cost for fire insurance
     */
    HSM_UNKNOWN_COST_1,

    /**
     * Sonstige
     * Other
     */
    HSM_UNKNOWN_COST_2,
}
