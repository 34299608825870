import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { CreditPurpose } from '@ntag-ef/finprocess-enums/core';
import { ObjectPurposeType } from '@ntag-ef/finprocess-enums/finprocess';
import { HelperService, ISelectItem } from 'app/modules/shared';
import { Observable, Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';

import { FinancingMode, FinancingService, FinancingState, IDocument, IFinancing, IFinancingStateParentDefinition, IRealEstate, OverwriteValueClassType, ValueStorageType } from '../../../../data';


/**
 * Komponente für den Tab Finanzplan
 */
@Component({
    selector: 'finprocess-financing-plan',
    templateUrl: './financing-plan.component.html',
    styleUrls: ['./financing-plan.component.scss'],
})
export class FinancingPlanComponent implements OnInit, OnDestroy {

    /**
     * Finanzierung
     */
    @Select((it: IFinancingStateParentDefinition) => it.financing.financing)
    public financing$!: Observable<IFinancing | undefined>;

    public financingData: IFinancing | undefined;

    public creditPurpose!: ISelectItem<string>[];

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public OverwriteValueClassType = OverwriteValueClassType;

    /**
     * Für Template-Nutzung
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ValueStorageType = ValueStorageType;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * Ausgewählte Liegenschaft
     */
    public selectedRealEstate?: IRealEstate;
    /**
     *  Abdeckung Vorkredit
     */
    @Select(FinancingState.currentAmountCoveredLiabilites)
    public currentAmountCoveredLiabilites$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Summe Projektkosten
     */
    @Select(FinancingState.sumProjectCosts)
    public sumProjectCosts$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Summe Eigenmittel
     */
    @Select(FinancingState.sumOwnCapital)
    public sumOwnCapital$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Kurzfristiger Finanzierungsbedarf
     */
    @Select(FinancingState.sumPrefinancing)
    public sumPrefinancing$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Langfristiger Finanzierungsbedarf Netto
     */
    @Select(FinancingState.netFinancingRequirement)
    public netFinancingRequirement$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Finanzierungsnebenkosten
     */
    @Select(FinancingState.sumFinancingAdditionalCharges)
    public sumFinancingAdditionalCharges$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Langfristiger Finanzierungsbedarf Brutto
     */
    @Select(FinancingState.grossFinancingRequirement)
    public grossFinancingRequirement$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Langfristiger Finanzierungsbedarf Brutto - new berechnet
     */
    @Select(FinancingState.newCalculatedgrossFinancingRequirement)
    public newCalculatedgrossFinancingRequirement$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Fktive Rate
     */
    @Select(FinancingState.fictionalRateAmount)
    public fictionalRateAmount$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Grunderwerbssteuer in Euro
     */
    @Select(FinancingState.realEstateTaxes)
    public realEstateTaxes$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Eintragung Eigentumsrecht in Euro
     */
    @Select(FinancingState.landregisterEntry)
    public landregisterEntry$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Erichtung Kaufvertrag / Treuhand in Euro
     */
    @Select(FinancingState.notaryCosts)
    public notaryCosts$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Marklergebügren in Euro
     */
    @Select(FinancingState.brokerageCosts)
    public brokerageCosts$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Summe Kaufnebenkosten in Euro
     */
    @Select(FinancingState.sumAdditionalCosts)
    public sumAdditionalCosts$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Bearbeitungsspesen in Euro
     */
    @Select(FinancingState.processingCharges)
    public processingCharges$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Schätzgebühr in Euro
     */
    @Select(FinancingState.estimateCharges)
    public estimateCharges$!: Observable<(withOverwrites?: boolean) => number>;
    /**
     * Eintragungsgebühr in Euro
     */
    @Select(FinancingState.registrationCharges)
    public registrationCharges$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Summe der Marktwerte
     */
    @Select(FinancingState.sumMarketValues)
    public sumMarketValues$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Grundbuchgesuch
     */
    public landRegistryRequest$!: Observable<number>;
    /**
     * Grundbuchauszug
     */
    public landRegistryExcerpt$!: Observable<number>;
    /**
     * Legalisierungsgebühr
     */
    @Select(FinancingState.legalisationFee)
    public legalisationFee$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Dokumente für den Dokumenten Viewer
     */
    public documents$!: Observable<((document: IDocument[]) => IDocument[])>;

    /**
     * Ansichtswechsel zwischen Risikofinanzierungsplan und Einreichung
     */
    public isRiskFinancingPlan = false;

    /**
     * Subject zum Beenden von Subscriptions
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Konstruktor
     *
     * @param {string} locale Locale
     * @param {FinancingService} financingService FinancingService-Injektor
     * @param {ActivatedRoute} activatedRoute ActivatedRoute-Injektor
     */
    public constructor(@Inject(LOCALE_ID) locale: string, private financingService: FinancingService, private activatedRoute: ActivatedRoute) {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;
        this.locale = locale === 'de-AT' ? 'de' : locale;
    }

    /**
     * Angular-Lifecycle beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        this.financing$.pipe(takeUntil(this.onDestroy$)).subscribe(financing => {
            if (financing === undefined) {
                return;
            }
            this.financingData = financing;

            this.selectedRealEstate = financing.realEstates.find(realestate => realestate.objectPurpose === ObjectPurposeType.Finance);
        });

        this.activatedRoute.queryParamMap.pipe(
            takeUntil(this.onDestroy$),
            map(params => params.get('mode') as FinancingMode),
            distinctUntilChanged(),
        ).subscribe((mode: FinancingMode) => {
            this.isRiskFinancingPlan = mode === FinancingMode.RiskFinancingPlan;
        });

        this.creditPurpose = HelperService.getSortedSelectItems(CreditPurpose, value => CreditPurpose.translate(value));

        this.landRegistryRequest$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            map(financing => financing?.financingConfiguration.landRegisterRequest ?? 0),
        );
        this.landRegistryExcerpt$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            map(financing => financing?.financingConfiguration.landRegisterExtract ?? 0),
        );

        this.documents$ = this.financing$.pipe(
            takeUntil(this.onDestroy$),
            map(() => (documents: IDocument[]) => documents.filter(document => document.parentId === this.selectedRealEstate?.id)),
        );
    }
    
    /**
     * Angular Lifecycle Hook beim Verlassen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * function used to identify element in array that has a specific id
     * 
     * @param {any[]} array array
     * @param {any} id id
     * @returns {object | undefined} data object or null
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public findArrayElementWithId(array: any[], id: any) {
        return array.find(e => e.value === id.toString());
    }
}
