import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalculationHelperService } from '@ntag-ef/finprocess-calculations';
import { IRentalIncomeDialogData } from 'app/modules/financing/data';
import { HelperService } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { combineLatest, startWith } from 'rxjs';

interface IExistingRentalIncomeForm {
    id: FormControl<string | null | undefined>;
    objectName: FormControl<string>;
    rentalIncome: FormControl<number>;
    assignedDebitorId: FormControl<string>;
}

interface IFutureRentalIncomeForm {
    id: FormControl<string | null | undefined>;
    objectName: FormControl<string>;
    grossRentPerMonth: FormControl<number>;
    vatPerMonth: FormControl<number>;
    operatingCostsPerMonth: FormControl<number>;
    netRentPerMonth: FormControl<number>;
    netRent70PercentPerMonth: FormControl<number>;
    assignedDebitorId: FormControl<string>;
}

/**
 * Dialog to add or edit rental incomes
 */
@Component({
    selector: 'finprocess-rental-income-dialog',
    templateUrl: './rental-income-dialog.component.html',
    styleUrls: ['./rental-income-dialog.component.scss'],
})
export class RentalIncomeDialogComponent implements OnInit {

    /**
     * Form for future rental incomes
     */
    public futureRentalIncomeForm: FormGroup<IFutureRentalIncomeForm>;

    /**
     * Form for existing rental incomes
     */
    public existingRentalIncomeForm: FormGroup<IExistingRentalIncomeForm>;

    /**
     * Options for displaying currencies
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     * Constructor
     * 
     * @param {IRentalIncomeDialogData} data Data for the dialog
     * @param {MatDialogRef<RentalIncomeDialogComponent>} dialogRef Reference to the dialog
     * @param {string} locale Locale
     */
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: IRentalIncomeDialogData,
        private dialogRef: MatDialogRef<RentalIncomeDialogComponent>,
        @Inject(LOCALE_ID) private locale: string,
    ) {
        this.existingRentalIncomeForm = new FormGroup({
            id: new FormControl<string | null | undefined>(undefined),
            objectName: new FormControl('', { validators: Validators.required, nonNullable: true }),
            rentalIncome: new FormControl(0, { validators: Validators.required, nonNullable: true }),
            assignedDebitorId: new FormControl('', { validators: Validators.required, nonNullable: true }),
        });

        this.futureRentalIncomeForm = new FormGroup({
            id: new FormControl<string | null | undefined>(undefined),
            objectName: new FormControl('', { validators: Validators.required, nonNullable: true }),
            grossRentPerMonth: new FormControl(0, { validators: Validators.required, nonNullable: true}),
            vatPerMonth: new FormControl(0, { validators: Validators.required, nonNullable: true}),
            operatingCostsPerMonth: new FormControl(0, { validators: Validators.required, nonNullable: true}),
            netRentPerMonth: new FormControl({ value: 0, disabled: true}, { validators: Validators.required, nonNullable: true}),
            netRent70PercentPerMonth: new FormControl({ value: 0, disabled: true }, { validators: Validators.required, nonNullable: true}),
            assignedDebitorId: new FormControl('', { validators: Validators.required, nonNullable: true}),
        });

        combineLatest([
            this.futureRentalIncomeForm.controls.grossRentPerMonth.valueChanges.pipe(startWith(this.futureRentalIncomeForm.controls.grossRentPerMonth.value)),
            this.futureRentalIncomeForm.controls.vatPerMonth.valueChanges.pipe(startWith(this.futureRentalIncomeForm.controls.vatPerMonth.value)),
            this.futureRentalIncomeForm.controls.operatingCostsPerMonth.valueChanges.pipe(startWith(this.futureRentalIncomeForm.controls.operatingCostsPerMonth.value)),
        ]).pipe(
            takeUntilDestroyed(),
        ).subscribe(([grossRentPerMonth, vATPerMonth, operatingCostsPerMonth]) => {
            const netRent = Math.max(0, grossRentPerMonth - vATPerMonth - operatingCostsPerMonth);
            this.futureRentalIncomeForm.controls.netRentPerMonth.setValue(netRent);
            this.futureRentalIncomeForm.controls.netRent70PercentPerMonth.setValue(CalculationHelperService.round(netRent * 0.7, 2));
        });

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
            decimal: ',',
            thousands: '.',
        });
    }

    /**
     * Initializes the forms
     */
    public ngOnInit(): void {
        this.initForms();
    }

    /**
     * Closes the dialog and emits the form content
     */
    public save(): void {
        if (this.data.type === 'existing') {
            this.dialogRef.close(this.existingRentalIncomeForm.getRawValue());
        } else {
            this.dialogRef.close(this.futureRentalIncomeForm.getRawValue());
        }
    }
   
    /**
     * Initializes the forms
     */
    private initForms(): void {
        if (this.data.type === 'existing' && !!this.data.rentalIncome) {
            this.existingRentalIncomeForm.patchValue(this.data.rentalIncome);
        } else if (!!this.data.rentalIncome) {
            this.futureRentalIncomeForm.patchValue(this.data.rentalIncome);
        }
    }
}
