import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from 'app/modules/auth/features/checks/guards';
import { MessagesComponent } from 'app/modules/messages/features/messages/components';
import { AuthenticationGuard, AuthorizationGuard, IExtendedRoutingData, IRoleData, Role } from 'auth';

import {
    AdditionalSheetsComponent,
    ApprovalComponent,
    AssetsContainerComponent,
    BorrowerCheckCustomerDataComponent,
    BorrowerCheckIncomeComponent,
    EsisComponent,
    FinancingComponent, 
    FinancingContainerComponent,
    FinancingDetailsComponent,
    HouseholdBillsViewComponent, 
    HouseholdCalculationsComponent,
    LiabilitiesComponent,
    LiabilitiesOverviewComponent,
    ProductpackageCollateralComponent,
    RiskFinancingPlanComponent,
    RiskfinancingplansComponent,
    SampleCalculationComponent,
    ScoringComponent,
    StatusComponent,
}
    from './components';
import { CustomerCenterComponent } from './components/customer-center/customer-center.component';
import { ProductPackageComponent } from './components/product-package/product-package.component';
import { ProductPackageWorkflowComponent } from './components/product-package-workflow/product-package-workflow.component';
import { RiskDecisionComponent } from './components/risk-decision/risk-decision.component';


const routes: Routes = [
    {
        path: ':financingContainerId/messages',
        pathMatch: 'full',
        component: MessagesComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true, hideNavigation: true } as IExtendedRoutingData,
    },
    {
        path: ':financingContainerId/status',
        pathMatch: 'full',
        component: StatusComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true, hideNavigation: true } as IExtendedRoutingData,
    },
    {
        path: ':financingContainerId',
        component: FinancingContainerComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
        children: [
            {
                path: '',
                pathMatch: 'full',

                redirectTo: 'financingmap',
            },
            {
                path: 'sample-calculation',
                component: SampleCalculationComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'borrower-check-income/:riskfinancingplanid',
                component: BorrowerCheckIncomeComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'borrower-check-customer-data/:riskfinancingplanid',
                component: BorrowerCheckCustomerDataComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'riskfinancingplans',
                component: RiskfinancingplansComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'customer-center',
                component: CustomerCenterComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'general-assets',
                component: AssetsContainerComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'liabilities',
                component: LiabilitiesComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canDeactivate: [PendingChangesGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'liabilities-overview',
                component: LiabilitiesOverviewComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'esis',
                component: EsisComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'financingmap/:riskfinancingplanid',

                component: FinancingComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'riskfinancingplan/:riskfinancingplanid',
                component: RiskFinancingPlanComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                children: [
                    {
                        path: 'financingComponent',
                        component: FinancingComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                        children: [
                            {
                                path: 'colt',
                                loadChildren: async () => (await import('../../features/colt/colt.module')).ColtModule,
                                canLoad: [AuthenticationGuard, AuthorizationGuard],
                                canActivate: [AuthenticationGuard, AuthorizationGuard],
                                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                            },
                        ],
                    },
                    {
                        path: 'scoring',
                        component: ScoringComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                    },
                    {
                        path: 'product-package',
                        component: ProductPackageComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                    },
                    {
                        path: '',
                        redirectTo: 'financingComponent',
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: 'riskfinancingplan/:riskfinancingplanid/product-package-decision/:productPackageID',
                component: ProductPackageWorkflowComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                children: [
                    {
                        path: 'collateral-details',
                        component: ProductpackageCollateralComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                    },
                    {
                        path: 'risk-decision',
                        component: RiskDecisionComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                    },
                    {
                        path: 'financingdetails',
                        component: FinancingDetailsComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                    },
                    {
                        path: 'approval',
                        component: ApprovalComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                        data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
                    },
                ],
            },
            {
                path: 'household-bills',
                component: HouseholdCalculationsComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'households',
                component: HouseholdCalculationsComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'additional-sheets',
                component: AdditionalSheetsComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'household-bills-view',
                component: HouseholdBillsViewComponent,
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
            {
                path: 'colt',
                loadChildren: async () => (await import('../../features/colt/colt.module')).ColtModule,
                canLoad: [AuthenticationGuard, AuthorizationGuard],
                canActivate: [AuthenticationGuard, AuthorizationGuard],
                canActivateChild: [AuthenticationGuard, AuthorizationGuard],
                data: { roles: Role.FinancingMapsGlobalReader | Role.FinancingMapsReader | Role.FinancingMapsEditor, needOnlyOne: true } as IRoleData,
            },
        ],

    },

];

/**
 * Modul für Routen zur FInanzierung
 */
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FinancingProcessingRoutingModule { }
