export enum CreditPurpose {
    /**
     * Errichtung
     */
    Construction = 0,

    /**
     * Kauf
     */
    Purchase = 1,

    /**
     * Erweiterung/Ausbau
     */
    Expansion = 2,

    /**
     * Renovierung
     */
    Refurbishment = 3,

    /**
     * Technische Ausstattung
     */
    TechEquipment = 4,

    /**
     * Sonstiges
     */
    Other = 5,

    /**
     * Umschuldung
     */
    Rescheduling = 6,
}
