export enum MaritalStatus {
    /**
     * Ledig
     */
    Single = 0,

    /**
     * Verheiratet
     */
    Married = 1,

    /**
     * Verwitwet
     */
    Widowed = 2,

    /**
     * Geschieden
     */
    Divorced = 3,

    /**
     * Verpartnert
     */
    Partnered = 4
}
