<ng-template #labelValueTemplate let-fieldName="fieldName" let-value="value">
  <finprocess-label-value>
    <span label>{{ ('asset.assetProvider.'+fieldName) | translate }}</span>
    <span value>{{ (value !== null && value !== undefined && value !== '') ? value : '-' }}</span>
  </finprocess-label-value>
</ng-template>

<ucba-heading [type]="headingType">
  <div ucba-heading-title>{{ 'asset.assetCommon.assetProvider' | translate }}</div>
  <div 
    class="add-btn"
    ucba-heading-actions
    *ngIf="(fieldReadonly$ | async) !== true"
    (click)="addAssetProvider()">
    <span class="mr-1">{{ 'asset.assetProvider.addAssetProvider' | translate }}</span>
    <mat-icon style="font-size: 20px;">add_circle</mat-icon>
  </div>
  <div ucba-heading-body-visible>
    @for (provider of assetProviders; track $index) {
      <div [class.tile-margin]="$index !== 0" [class.darker-tiles]="$index % 2 !== 0">
        <ucba-tile-layout>
          <!-- NDG -->
          <ng-template ucba-tile>
            <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'ndg', value: provider.ndg }"></ng-container>
          </ng-template>
    
          <!-- Nachname -->
          <ng-template ucba-tile>
            <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'lastName', value: provider.lastName}"></ng-container>
          </ng-template>
    
          <!-- Vorname -->
          <ng-template ucba-tile [span]="3">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'firstName', value: provider.firstName }"></ng-container>
              <button 
                *ngIf="(fieldReadonly$ | async) !== true" 
                class="delete-btn" 
                (click)="deleteAssetProvider(provider.id)" 
                [matTooltip]="'asset.assetProvider.delete' | translate"
                mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-template>
        </ucba-tile-layout>
      </div>
    } @empty {
      <ucba-tile-layout>
        <ng-template ucba-tile [span]="5">
          {{'asset.assetProvider.noAssetProviders' | translate}}
        </ng-template>
      </ucba-tile-layout>
    }
  </div>
</ucba-heading>
