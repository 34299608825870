import { CollateralType } from '@ntag-ef/finprocess-enums';

/**
 * BA105, BA110, BA115, BA120, BA125, BA130, BA135
 * Versicherungen
 */
export const INSURANCES = [CollateralType.BA105, CollateralType.BA110, CollateralType.BA115, CollateralType.BA120, CollateralType.BA125, CollateralType.BA130, CollateralType.BA135];

/**
 * BA105 und BA125
 */
export const BA105BA125 = [CollateralType.BA105, CollateralType.BA110, CollateralType.BA115, CollateralType.BA120, CollateralType.BA125];

/**
 * BA205, BA210 und BA220
 * Collaterals für Hypotheken
 */
export const MORTGAGES = [CollateralType.BA205, CollateralType.BA210, CollateralType.BA220];

/**
 * BA415 und BA425
 * Eigene finanzielle Sicherheiten
 */
export const OWN_COLLATERALS = [CollateralType.BA415, CollateralType.BA425];

export const INSURANCES_MAX_VALUE = 99999999.99;

export const EMPLOYEES_BRANCH_ID = '70444884-E4FA-4827-1AA7-08DA161C5D6A'
