import { Validators } from '@angular/forms';
import { FinprocessFormBuilder } from '@ntag-ef/finprocess-forms';

import { APPROVAL_DATA } from '../forms/colt-provider';
import { IApprovalData } from '../interfaces';
import { ColtValidators } from '../util';

export const approvalDataForm = FinprocessFormBuilder.createFormGroup<IApprovalData>({

    /**
     * FIRE ID
     */
    fireId: { validator: Validators.compose([Validators.required, ColtValidators.fireId]) },

    /**
     *  Kompetenzstufe
     */
    decisionLevel: { validator: Validators.required },

    /**
     *  Entscheider
     */
    userIdDecider: { validator: Validators.required },

    /**
     *  Unit Entscheider
     */
    unitDecider: { validator: Validators.required },
}, APPROVAL_DATA);
