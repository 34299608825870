export enum PriorChargeType {
    /**
     * eigene Vorlast
     */
    OWN = 3,

    /**
     * fremde Vorlast
     */
    THIRDPARTY = 4,
}
