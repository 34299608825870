import { Validators } from '@angular/forms';
import { FinprocessFormBuilder, createProviderInput } from '@ntag-ef/finprocess-forms';

import { RepaymentDetailsType } from '../enums';
import { REPAYMENT_DETAILS } from '../forms/colt-provider';
import { IRepaymentDetails } from '../interfaces';
import { ColtValidators } from '../util';

export const repaymentDetailsForm = FinprocessFormBuilder.createFormGroup<IRepaymentDetails>({

    /**
     * Rückzahlungsart (Lastschrift oder Zahlschein)
     */
    repaymentDetailsType: {
        validator: Validators.required,
    },

    /**
     * IBAN
     */
    iban: {
        validator: createProviderInput({
            providers: REPAYMENT_DETAILS,
            fn: (details: IRepaymentDetails) => (details.repaymentDetailsType === RepaymentDetailsType.DirectDebit ? Validators.compose([Validators.required, ColtValidators.noSpace, ColtValidators.invalidEuIBAN]) : null),
        }),
        visible: createProviderInput({
            providers: REPAYMENT_DETAILS,
            fn: (details: IRepaymentDetails) => details.repaymentDetailsType === RepaymentDetailsType.DirectDebit,
        }),
    },

    /**
     * NDG Zahler
     */
    ndgPayer: {
        validator: createProviderInput({
            providers: REPAYMENT_DETAILS,
            fn: (details: IRepaymentDetails) => (details.repaymentDetailsType === RepaymentDetailsType.DirectDebit ? Validators.compose([Validators.required, ColtValidators.numeric]) : null),
        }),
        visible: createProviderInput({
            providers: REPAYMENT_DETAILS,
            fn: (details: IRepaymentDetails) => details.repaymentDetailsType === RepaymentDetailsType.DirectDebit,
        }),
    },
}, REPAYMENT_DETAILS);
