<ng-template #workflowControlTemplate let-control="control" let-key="key" let-model="model" let-translateKey="translateKey" let-className="className" let-lastOfGroup="lastOfGroup">
    <div fxLayoutAlign="flex-start center" [class.last-row-of-group]="lastOfGroup">
        <span>{{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}</span>
        @if(model.validators) {
            <span>*</span>
        }
    </div>   
    <div [class.last-row-of-group]="lastOfGroup">
        @switch (model.type) {
            @case (WorkflowType.String) {
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" floatLabel="always"
                        [class]="'w-100 mat-form-field-transparent-background ' + className">
                        <input matInput [formControl]="control">
                        <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
                    </mat-form-field>
                } @else {
                    <div class="readonly-mode-field">
                        @if (control.value === undefined || control.value === null || control.value === '') {
                            -
                        } @else {
                            {{control.value}}
                        }
                    </div>
                }
            } @case (WorkflowType.Number) {
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" floatLabel="always"
                        [class]="'w-100 mat-form-field-transparent-background ' + className">
                        <input *ngIf="model.formattingType === FormattingType.Currency" matInput [formControl]="control"
                            currencyMask [options]="currencyMaskOptions">
                        <input *ngIf="model.formattingType === FormattingType.Percentage" matInput [formControl]="control"
                            currencyMask [options]="percentageMaskOptions">
                        <input *ngIf="model.formattingType === FormattingType.Number || model.formattingType === undefined" matInput [formControl]="control"
                            type="number">
                        <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
                    </mat-form-field>
                } @else {
                    <div class="readonly-mode-field">
                        @if (control.value === undefined || control.value === null) {
                            -
                        } @else if (model.formattingType === FormattingType.Currency) {
                            {{control.value | formatCurrency}}
                        } @else if(model.formattingType === FormattingType.Percentage) {
                            {{(control.value | number:'1.3-3')+'%'}}
                        } @else if(model.formattingType === FormattingType.Number || model.formattingType === undefined) {
                            {{control.value | number}}
                        }
                    </div>
                }
            } @case (WorkflowType.Enum) {
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" floatLabel="always"
                        [class]="'w-100 mat-form-field-transparent-background ' + className">
                        <mat-select [formControl]="control">
                            <mat-option *ngFor="let enumValue of getEnumArray(model.enumObject)" [value]="enumValue">
                                {{ 'workflow.' + globalTranslateKey + model.enumLabel + '.' + enumValue | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
                    </mat-form-field>
                } @else {
                    <div class="readonly-mode-field">
                        @if (control.value === undefined || control.value === null) {
                            -
                        } @else {
                            {{ 'workflow.' + globalTranslateKey + model.enumLabel + '.' + control.value | translate }}
                        }
                    </div>
                }
            } @case (WorkflowType.Boolean) {
                @if((fieldReadonly$ | async) === false) {
                    <mat-checkbox color="primary" [formControl]="control">
                        {{ 'workflow.' + globalTranslateKey + translateKey + '.' + key | translate }}
                    </mat-checkbox>
                } @else {
                    <div class="readonly-mode-field">
                        {{ control.value ? ('general.yes' | translate) : ('general.no' | translate) }}
                    </div>
                }
            } @case (WorkflowType.Date) {
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" floatLabel="always" class="w-100 mat-form-field-transparent-background">
                        <input *ngIf="!!control" matInput [formControl]="control" (focus)="dateinput_key.open()" [matDatepicker]="dateinput_key">
                        <mat-datepicker-toggle matSuffix [for]="dateinput_key"></mat-datepicker-toggle>
                        <mat-datepicker #dateinput_key></mat-datepicker>
                    </mat-form-field>
                    <mat-error *ngIf="control.errors">{{ control.errors | finProcessFormError }}</mat-error>
                } @else {
                    <div class="readonly-mode-field">
                        @if (control.value === undefined || control.value === null) {
                            -
                        } @else {
                            {{ control.value | date : 'dd.MM.yyyy' }}
                        }
                    </div>
                }
            } @default {
                <div>
                    {{ 'workflow.fieldCouldNotBeDisplayed' | translate:{ field: ('workflow.' + globalTranslateKey + translateKey + '.' + key | translate) } }}
                </div>
            }
        }
    </div>
</ng-template>

@if(!!dataErrors) {
    <div class="p-4">
        <finprocess-hint type="error">
            <span header>{{'financing.features.financing-processing.risk-decision.loadRequestError' | translate}}</span>
            <ul content>
               <li *ngFor="let error of dataErrors">{{error}}</li> 
            </ul>
        </finprocess-hint>
    </div>
} @else {
    <ucba-table [noAlternatingColumnColors]="true">
        @if(!!form) {
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.risk-decision.fireForm' | translate}}</div>
            </ng-template>
            @for(key of getKeys(model); track $index) {
                @if($any(model[key]).type === WorkflowType.Object) {
                    <ng-template ucba-table-row>
                        <div [attr.ucba-column-span]="'span 2'" [attr.ucba-column-no-styles]="true">
                            <ucba-heading [type]="'medium'">
                                <div ucba-heading-title>
                                    {{ 'workflow.' + globalTranslateKey + model.midtermName + '.' + key | translate }}
                                </div>
                            </ucba-heading>
                        </div>
                    </ng-template>
                    @for(childKey of getKeys($any(model[key]).child); track $index) {
                        <ng-template ucba-table-row>
                            <ng-container
                                *ngTemplateOutlet="workflowControlTemplate; context: { control: $any(form.controls[key]).controls[childKey], model: $any(model[key]).child[childKey], key: childKey, translateKey: $any(model[key]).child.midtermName, lastOfGroup: $index === getKeys($any(model[key]).child).length - 1 }">
                            </ng-container>
                        </ng-template>
                    }
                } @else if($any(model[key]).type === WorkflowType.Array) {
                    <ng-template ucba-table-row>
                        <div [attr.ucba-column-span]="'span 2'" [attr.ucba-column-no-styles]="true">
                            <ucba-heading [type]="'medium'">
                                <div ucba-heading-title>
                                    {{ 'workflow.' + globalTranslateKey + model.midtermName + '.' + key | translate }}
                                </div>
                                <div ucba-heading-actions class="add-btn" (click)="addToFormArray($any(form.controls[key]), $any(model[key]).child)">
                                    <span class="mr-1">{{ 'workflow.add'| translate }}</span>
                                    <mat-icon style="font-size: 20px;">add_circle</mat-icon>
                                </div>
                            </ucba-heading>
                        </div>
                    </ng-template>
                    @if($any(form.controls[key]).controls.length === 0) {
                        <ng-template ucba-table-row>
                            <div [attr.ucba-column-span]="'span 2'" class="last-row-of-group">
                                {{ 'workflow.no-items'| translate }}
                            </div>
                        </ng-template>
                    } @else {
                        @for(subControl of $any(form.controls[key]).controls; track $index) {
                            <ng-template ucba-table-row>
                                <div [attr.ucba-column-span]="'span 2'" [attr.ucba-column-no-styles]="true">
                                    <ucba-heading [type]="'small'">
                                        <div ucba-heading-title>
                                            {{ $index + 1 }}. {{ 'workflow.' + globalTranslateKey + model.midtermName + '.' + key + 'Short' | translate }}
                                        </div>
                                        <div ucba-heading-actions>
                                            <button mat-icon-button 
                                                [matTooltip]="'workflow.remove' | translate:{field: $index + 1 + '. ' + ('workflow.' + globalTranslateKey + model.midtermName + '.' + key + 'Short' | translate) }"
                                                (click)="removeFromArray($any(form.controls[key]), $index)"
                                            >
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </ucba-heading>
                                </div>
                            </ng-template>
                            @if(isPrimitiveArray($any(model[key]).child)) {
                                <ng-template ucba-table-row>
                                    <ng-container
                                        *ngTemplateOutlet="workflowControlTemplate; context: { control: subControl, key: key, model: { type: $any(model[key]).child, enumObject: $any(model[key]).enumObject, enumLabel: $any(model[key]).enumLabel }, translateKey: model.midtermName, className: 'array', lastOfGroup: true }">
                                    </ng-container>
                                </ng-template>
                            } @else {
                                @for(childKey of getKeys($any(model[key]).child); track $index) {
                                    <ng-template ucba-table-row>
                                        <ng-container
                                            *ngTemplateOutlet="workflowControlTemplate; context: { control: subControl.controls[childKey], model: $any(model[key]).child[childKey], key: childKey, translateKey: $any(model[key]).child.midtermName, lastOfGroup: $index === getKeys($any(model[key]).child).length - 1 }">
                                        </ng-container>
                                    </ng-template>
                                }
                            }
                        }
                    }
                } @else {
                    <ng-template ucba-table-row>
                        <ng-container
                            *ngTemplateOutlet="workflowControlTemplate; context: { control: form.controls[key], model: model[key], key: key, translateKey: model.midtermName }">
                        </ng-container>
                    </ng-template>
                }
            }
            <ng-template ucba-table-row>
                <div [attr.ucba-column-span]="'span 2'" fxLayout="row" fxLayoutAlign="flex-end center" class="pt-3 pb-3">
                    <button [disabled]="form?.invalid || (form?.dirty && form?.invalid) || (fieldReadonly$ | async) ?? true"
                        style="width: fit-content;" mat-raised-button color="primary"
                        (click)="saveForm()" [disabled]="form?.invalid" matTooltip="Formular absenden">
                        {{'financing.features.financing-processing.risk-decision.decisionRequest' | translate}}
                    </button>
                </div>
            </ng-template>
        }
        <ng-template ucba-table-header>
            <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.risk-decision.fireResult' | translate}}</div>
        </ng-template>
        @if(fireResponse) {
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.fireID' | translate}}</div>
                <div>{{fireResponse.proposalId}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.belq' | translate}}</div>
                <div>{{fireResponse.kpiBelq}}%</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.dsti' | translate}}</div>
                <div>{{fireResponse.kpiDsti}}%</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.eifa' | translate}}</div>
                <div>{{fireResponse.kpiEifa}}%</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.el' | translate}}</div>
                <div>{{fireResponse.kpiEl | formatCurrency}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.lti' | translate}}</div>
                <div>{{fireResponse.kpiLti}}%</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.ltv' | translate}}</div>
                <div>{{fireResponse.kpiLtv}}%</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.kimv' | translate}}</div>
                <div>{{fireResponse.kpiKimVRelSchuldq}}%</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.reasonCode' | translate}}</div>
                <div>{{fireResponse.reasonCode | finprocessEnumTranslation:'ReasonCode'}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.reasonCodeText' | translate}}</div>
                <div>{{fireResponse.ergTextStatusFinal}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.addCode' | translate}}</div>
                <div>{{fireResponse.ergAdditionalCode | finprocessEnumTranslation:'AdditionalCode'}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.addCodeText' | translate}}</div>
                <div>{{fireResponse.ergTextAdditionalStatus}}</div>
            </ng-template>            
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.totalCommitment' | translate}}</div>
                <div>{{fireResponse.totalCommitment | formatCurrency}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div>{{'financing.features.financing-processing.risk-decision.totalCommitmentNoGuranatee' | translate}}</div>
                <div>{{fireResponse.totalCommitmentNoGuarantee | formatCurrency}}</div>
            </ng-template>
            <ng-template ucba-table-row *ngIf="!!fireDocumentData">
                <div fxLayoutAlign="space-between center">{{'financing.features.financing-processing.risk-decision.fireDocument' | translate}}</div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" [class.readonly-mode-field]="(fieldReadonly$ | async) !== false">
                        <mat-icon class="mr-1 document-icon">description</mat-icon>
                        <div>{{fireDocumentData.fileName}}.pdf</div>
                    </div>
                    <div *ngIf="(fieldReadonly$ | async) === false" fxLayout="row" fxLayoutAlign="flex-end center">
                        <button mat-mini-fab [color]="'primary'" 
                            [disabled]="(fieldReadonly$ | async) ?? true"
                            [matTooltip]="'financing.features.financing-processing.risk-decision.openUploadedDocument' | translate"
                            (click)="downloadFireDocument()">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template ucba-table-row>
                <div fxLayout="row" fxLayoutAlign="flex-end center" [attr.ucba-column-span]="'span 2'" class="pt-3 pb-3">
                    <button 
                        mat-raised-button color="primary"
                        (click)="switchToApproval()"
                        [disabled]="(fieldReadonly$ | async) ?? true">
                        {{'financing.features.financing-processing.risk-decision.approvalButton' | translate}}
                    </button>
                </div>
            </ng-template>
        } @else {
            <ng-template ucba-table-row>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.risk-decision.noFireResponse' | translate}}</div>
            </ng-template>
        }
    </ucba-table>
}