import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { AssetService, FinancingService, IAssetAgp } from 'app/modules/financing/data';
import { HelperService, ISelectItem } from 'app/modules/shared';

import { MortgageType } from '../../../colt/enums';
import { AssetCommonComponent } from '../asset-common/asset-common';

/**
 *
 */
@Component({
    selector: 'finprocess-asset-agp',
    templateUrl: './asset-agp.component.html',
    styleUrls: ['./asset-agp.component.scss'],
})
export class AssetAgpComponent extends AssetCommonComponent implements OnInit {

   
    @Input()
    public assetsAgps: IAssetAgp[] | undefined;

    public mortgageTypeSelectItems: ISelectItem<number>[] = [];

    /**
     * Konstruktor
     * 
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     */
    public constructor(
        enumTranslate: EnumTranslationPipe,
        assetService: AssetService,
        store: Store,
        financingService: FinancingService,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
    }

    /**
     * OnInit
     */
    public ngOnInit() {
        this.mortgageTypeSelectItems = HelperService.getSortedSelectItems(MortgageType, value => this.enumTranslate.transform(value, 'MortgageType') as string, [], true);
    }

    /**
     * function used to identify element in array that has a specific id
     * 
     * @param {any[]} array array
     * @param {any} id id
     * @returns {object | undefined} data object or null
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public findArrayElementWithId(array: any[], id: any) {
        return array.find(e => e.value === id);
    }
}
