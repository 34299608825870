import { Validators } from '@angular/forms';
import { FinprocessFormArray, FinprocessFormBuilder, createProviderInput } from '@ntag-ef/finprocess-forms';

import { EarlyRepaymentType, LoanPurpose, LoanType, ProductDescriptionType } from '../enums';
import { COLT_META_DATA, GUARANTEE_CREDIT_LINE, GUARANTEE_DETAIL_PRINTING, LOAN_DATA, LOAN_DATA_DETAILS, LOAN_DETAIL_PRINTING, OBJECT_INFORMATION, ONETIME_INSURANCE_FEE, PERIODICAL_INSURANCE_FEE, PRICING_DATA, PRODUCT, SALES_PARTNER, SPECIAL_CONDITIONS } from '../forms/colt-provider';
import { IColtMetaData, IGuaranteeCreditLine, IGuaranteeDetailPrinting, ILoanData, ILoanDataDetails, ILoanDetailPrinting, IObjectInformation, IOneTimeInsuranceFee, IPeriodicalInsuranceFee, IPricingData, IProduct, ISalesPartner, ISpecialConditions } from '../interfaces';
import { ColtValidators } from '../util';
import { creditPurposeToLoanPurpose } from '../util/helper';

const loanDataDetailsForm = FinprocessFormBuilder.createFormGroup<ILoanDataDetails>({
    startAsdPeriod: {
        validator: createProviderInput({
            providers: LOAN_DATA_DETAILS,
            fn: (details: ILoanDataDetails) => {
                const validators = [Validators.required];
                const maxValues: Date[] = []

                if (details.endOfDisbursementPeriod) {
                    maxValues.push(details.endOfDisbursementPeriod);
                }

                if (details.firstInstalmentDate) {
                    maxValues.push(details.firstInstalmentDate);
                }

                if (details.disbursement) {
                    maxValues.push(details.disbursement);
                }

                if (maxValues.length > 0) {
                    validators.push(ColtValidators.maxDate(maxValues));
                }

                return Validators.compose(validators);
            },
        }),
    },
    endOfDisbursementPeriod: {
        validator: createProviderInput({
            providers: LOAN_DATA_DETAILS,
            fn: (details: ILoanDataDetails) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const validators = [Validators.required];
                const minValues: Date[] = [today] // mind. aktuelles Datum

                if (details.startAsdPeriod) {
                    minValues.push(details.startAsdPeriod);
                }

                validators.push(ColtValidators.minDate(minValues));

                if (details.firstInstalmentDate) {
                    validators.push(ColtValidators.maxDate(details.firstInstalmentDate));
                }

                return Validators.compose(validators);
            },
        }),
    },
    disbursement: {
        validator: createProviderInput({
            providers: LOAN_DATA_DETAILS,
            fn: (details: ILoanDataDetails) => {
                const today = new Date();
                if (details.disbursement >= details.startAsdPeriod && details.disbursement <= details.firstInstalmentDate && details.disbursement >= today) {
                    return null;
                }

                return Validators.required;
            },
        }),
    },
    loanAmountCurrency: {
        validator: Validators.compose([Validators.required, ColtValidators.euroValidator]),
        default: {
            providers: [],
            fn: () => 'EUR',
        },
    },
    firstInstalmentDate: {
        validator: Validators.required,
        // validator: {
        //     providers: LOAN_DATA_DETAILS,
        //     fn: (details: ILoanDataDetails) => {
        //         if (details.firstInstalmentDate) {
        //             const twoDaysLater = moment(details.firstInstalmentDate).diff(moment(details.disbursement), 'days') >= 2;
        //             if (twoDaysLater) {
        //                 return null;
        //             }
        //             return Validators.required;
        //         }

        //         return Validators.required;
        //     },
        // },
    },
}, LOAN_DATA_DETAILS);

const specialConditionsForm = FinprocessFormBuilder.createFormGroup<ISpecialConditions>({
    followUpFixedSpread: {
        validator: Validators.compose([Validators.required, Validators.max(20)]),
    },
    endDateOfFixedInterestRate: {
        validator: createProviderInput({
            providers: SPECIAL_CONDITIONS,
            fn: (specialConditions: ISpecialConditions) => (!!specialConditions.fixedSpread ? Validators.required : null),
        }),
    },
    fixedSpread: null,
    //validator: Validators.pattern(/^\d{1,5}(\.\d{1,2})?$/),
    processingFee: {
        validator: createProviderInput({
            providers: [SALES_PARTNER, OBJECT_INFORMATION],
            fn: (salesPartner: ISalesPartner, objectInformation: IObjectInformation) => {
                const hasSalesPartner = !!salesPartner.salespartnerId ?? false;

                if (!hasSalesPartner && objectInformation.loanTypeValue !== LoanType.MOWNEMP) {
                    return Validators.compose([Validators.required, Validators.min(0), Validators.max(3)]);
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: [SALES_PARTNER, OBJECT_INFORMATION],
            fn: (salesPartner: ISalesPartner, objectInformation: IObjectInformation) => {
                const hasSalesPartner = !!salesPartner.salespartnerId ?? false;

                return !hasSalesPartner && objectInformation.loanTypeValue !== LoanType.MOWNEMP;
            },
        }),
    },
    processingFeeSalesPartner: {
        validator: createProviderInput({
            providers: [SALES_PARTNER, OBJECT_INFORMATION],
            fn: (salesPartner: ISalesPartner, objectInformation: IObjectInformation) => {
                const hasSalesPartner = !!salesPartner.salespartnerId ?? false;

                if (hasSalesPartner && objectInformation.loanTypeValue !== LoanType.MOWNEMP) {
                    return Validators.compose([Validators.required, Validators.min(0), Validators.max(3)]);
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: [SALES_PARTNER, OBJECT_INFORMATION],
            fn: (salesPartner: ISalesPartner, objectInformation: IObjectInformation) => {
                const hasSalesPartner = !!salesPartner.salespartnerId ?? false;

                return (hasSalesPartner && objectInformation.loanTypeValue !== LoanType.MOWNEMP);
            },
        }),
    },
    legalizationFee: {
        validator: Validators.max(999999.99),
    },
    appraisalFee: {
        validator: Validators.max(999999.99),
        visible: createProviderInput({
            providers: SPECIAL_CONDITIONS,
            fn: (specialConditions: ISpecialConditions) => !specialConditions.appraisalFeeConsumerExternal,
        }),
    },
    others: {
        validator: Validators.max(999999.99),
    },
    otherFeeAndCosts: {
        validator: Validators.max(999999.99),
    },
    landRegisterStatementFee: {
        validator: Validators.max(999999.99),
    },
    landRegisterRequestFee: {
        validator: Validators.max(999999.99),
    },
    landRegisterEntryFee: {
        validator: Validators.max(999999.99),
    },
    rankingFee: {
        validator: Validators.max(999999.99),
    },
    riskInsurance: {
        validator: Validators.max(999999.99),
    },
    riskInsuranceOwnEmployees: {
        validator: createProviderInput({
            providers: OBJECT_INFORMATION,
            fn: (objectInformation: IObjectInformation) => (objectInformation.loanTypeValue === LoanType.MOWNEMP ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: OBJECT_INFORMATION,
            fn: (objectInformation: IObjectInformation) => (objectInformation.loanTypeValue === LoanType.MOWNEMP),
        }),
    },
    specialFeeKleingarten: {
        validator: Validators.max(999999.99),
    },
    //gurantee lines missing
    maintenanceFee: {
        validator: Validators.required,
    },
    chargingNtCost: null,
    //gurantee lines missing
    salesPartnerFeeAmount: {
        validator: createProviderInput({
            providers: SALES_PARTNER,
            fn: (salesPartner: ISalesPartner) => (!!salesPartner.salespartnerId ? Validators.compose([Validators.required, Validators.max(99999.99)]) : null),
        }),
        visible: createProviderInput({
            providers: SALES_PARTNER,
            fn: (salesPartner: ISalesPartner) => !!salesPartner.salespartnerId,
        }),
    },
    appraisalFeeConsumerExternal: {
        visible: createProviderInput({
            providers: SPECIAL_CONDITIONS,
            fn: (specialConditions: ISpecialConditions) => !specialConditions.appraisalFee,
        }),
    },
}, SPECIAL_CONDITIONS);

const pricingDataForm = FinprocessFormBuilder.createFormGroup<IPricingData>({
    advantageLiquidityCosts: null,
    sollmarge: {
        validator: createProviderInput({
            providers: SPECIAL_CONDITIONS,
            fn: (specialConditions: ISpecialConditions) => (!!specialConditions.fixedSpread ? Validators.required : null),
        }),
    },
    conditionKeyCoverageBonds: {
        validator: Validators.compose([Validators.min(0), Validators.max(9999)]),
    },
    maximumAmountCoverableCL: null,
    coverageForbearanceRelevant: {
        validator: Validators.required,
    },
}, PRICING_DATA)

export const periodicalInsuranceFeeForm = FinprocessFormBuilder.createFormGroup<IPeriodicalInsuranceFee>({
    feeAmount: {
        validator: Validators.max(9999999.99),
    },
    startDate: null,
    endDate: null,
    periodicityValue: null,
}, PERIODICAL_INSURANCE_FEE);

export const oneTimeInsuranceFeeForm = FinprocessFormBuilder.createFormGroup<IOneTimeInsuranceFee>({
    feeAmount: {
        validator: Validators.max(9999999.99),
    },
}, ONETIME_INSURANCE_FEE);

export const loanDetailPrintingForm = FinprocessFormBuilder.createFormGroup<ILoanDetailPrinting>({
    earlyRepaymentValue: {
        validator: Validators.required,
    },
    earlyRepaymentAmount: {
        visible: createProviderInput({
            providers: LOAN_DETAIL_PRINTING,
            fn: (detailPrinting: ILoanDetailPrinting) => [EarlyRepaymentType.HSM_EARLY_REPAY_TYPE_5, EarlyRepaymentType.HSM_EARLY_REPAY_TYPE_6, EarlyRepaymentType.HSM_EARLY_REPAY_TYPE_7].includes(detailPrinting.earlyRepaymentValue),
        }),
        validator: createProviderInput({
            providers: LOAN_DETAIL_PRINTING,
            fn: (detailPrinting: ILoanDetailPrinting) => ([EarlyRepaymentType.HSM_EARLY_REPAY_TYPE_5, EarlyRepaymentType.HSM_EARLY_REPAY_TYPE_6, EarlyRepaymentType.HSM_EARLY_REPAY_TYPE_7].includes(detailPrinting.earlyRepaymentValue) ?
                Validators.compose([Validators.required, Validators.max(9999999.99)]) : null),
        }),
    },
    sumEstimatedValues: {
        validator: Validators.compose([Validators.required, Validators.max(9999999.99)]),
    },
    unknownCostsValue: null,
    spillOverPartOfTrusteeValuta: {
        visible: createProviderInput({
            providers: PRODUCT,
            fn: (product: IProduct) => product.productDescriptionType === ProductDescriptionType.SpillOver,
        }),
        validator: createProviderInput({
            providers: PRODUCT,
            fn: (product: IProduct) => (product.productDescriptionType === ProductDescriptionType.SpillOver ? Validators.required : null),
        }),
    },
}, LOAN_DETAIL_PRINTING);


export const productForm = FinprocessFormBuilder.createFormGroup<IProduct>({
    offerDate: {
        validator: Validators.required,
    },
    loanReferenceId: {
        validator: Validators.required,
    },
    productDescriptionType: {
        validator: Validators.required,
    },
    loanAmount: {
        validator: Validators.compose([Validators.required, Validators.max(9999999.99)]),
    },
    duration: {
        validator: Validators.compose([Validators.required, Validators.max(999)]),
    },
    indicatorBindingType: {
        validator: Validators.required,
    },
    fixedInterestPeriod: null,
    interestRate: null,
    mainCl: {
        validator: Validators.required,
    },
    pricingData: null,
    periodicalInsuranceFees: null,
    oneTimeInsuranceFees: null,
    loanDetailPrinting: null,
    details: null,
    specialConditions: null,
}, PRODUCT, (parent?: IProduct) => ({
    loanDetailPrinting: loanDetailPrintingForm(parent?.loanDetailPrinting),
    pricingData: pricingDataForm(parent?.pricingData),
    oneTimeInsuranceFees: new FinprocessFormArray((parent?.oneTimeInsuranceFees ?? []).map(oneTimeInsuranceFee => oneTimeInsuranceFeeForm(oneTimeInsuranceFee))),
    periodicalInsuranceFees: new FinprocessFormArray((parent?.periodicalInsuranceFees ?? []).map(periodicalInsuranceFee => periodicalInsuranceFeeForm(periodicalInsuranceFee))),
    details: loanDataDetailsForm(parent?.details),
    specialConditions: specialConditionsForm(parent?.specialConditions),
}))

const objectInformationForm = FinprocessFormBuilder.createFormGroup<IObjectInformation>({
    currency: {
        validator: Validators.compose([Validators.required, ColtValidators.euroValidator]),
        default: {
            providers: [],
            fn: () => 'EUR',
        },
    },
    value: {
        validator: createProviderInput({
            providers: OBJECT_INFORMATION,
            fn: (objInf: IObjectInformation) => {
                const validators = [Validators.max(9999999.99)];

                const value = creditPurposeToLoanPurpose(objInf.finProcessLoanPurpose);
                if (value !== null && [LoanPurpose.HMMO, LoanPurpose.HREN].includes(value)) {
                    validators.push(Validators.required);
                }

                return Validators.compose(validators);
            },
        }),
    },
    finProcessLoanObjectType: {
        validator: createProviderInput({
            providers: OBJECT_INFORMATION,
            fn: (objInf: IObjectInformation) => {
                const value = creditPurposeToLoanPurpose(objInf.finProcessLoanPurpose);
                if (value !== null && [LoanPurpose.HMMO, LoanPurpose.HREN].includes(value)) {
                    return Validators.required;
                }

                return null;
            },
        }),
    },
    finProcessLoanPurpose: {
        validator: Validators.required,
    },
    loanTypeValue: {
        validator: createProviderInput({
            providers: [COLT_META_DATA],
            fn: (metaData: IColtMetaData) => {
                const validators = [Validators.required];

                if (!metaData.isOwnEmployee) {
                    validators.push(ColtValidators.forbiddenValue(LoanType.MOWNEMP, 'Mortgage loan to own employee'))
                }
                return Validators.compose(validators)
            },
        }),
    },
}, OBJECT_INFORMATION);

const guaranteeDetailPrintingForm = FinprocessFormBuilder.createFormGroup<IGuaranteeDetailPrinting>({
    ibanDdAccountNumber: {
        validator: Validators.compose([Validators.required, ColtValidators.noSpace, ColtValidators.invalidAtIBAN]),
    },
    handlingFeeForGuarantee: {
        validator: Validators.compose([Validators.required, Validators.max(999999.99)]),
    },
    loanProvision: {
        validator: Validators.required,
    },
    ndgGuaranteeFee: {
        validator: Validators.required,
    },
    ibanGuaranteeFee: {
        validator: Validators.compose([Validators.required, ColtValidators.noSpace, ColtValidators.invalidAtIBAN]),
    },
    nameOfGuaranteeRecipient: {
        validator: Validators.required,
    },
    adressOfGuaranteeRecipient: {
        validator: Validators.required,
    },
    orderDetailsBankGuarantee: {
        validator: Validators.required,
    },
}, GUARANTEE_DETAIL_PRINTING);

export const guaranteeCreditLineForm = FinprocessFormBuilder.createFormGroup<IGuaranteeCreditLine>({
    duration: {
        validator: Validators.required,
    },
    changeDate: {
        validator: Validators.required,
    },
    guaranteeProductID: {
        validator: Validators.required,
    },
    guaranteeAmount: {
        validator: Validators.required,
    },
    linkedLoanProductIDs: {
        validator: createProviderInput({
            providers: GUARANTEE_CREDIT_LINE,
            fn: (guarantee: IGuaranteeCreditLine) => (!Array.isArray(guarantee.linkedLoanProductIDs) || guarantee.linkedLoanProductIDs.length === 0 ? ColtValidators.minValues(1) : null),
        }),
        default: {
            providers: [],
            fn: () => [],
        },
    },
    guaranteeDetailPrinting: null,
}, GUARANTEE_CREDIT_LINE, (parent?: IGuaranteeCreditLine) => ({

    guaranteeDetailPrinting: guaranteeDetailPrintingForm(parent?.guaranteeDetailPrinting),
}));

export const loanDataForm = FinprocessFormBuilder.createFormGroup<ILoanData>({
    objectInformation: null,
    products: null,
    guaranteeCreditLines: null,
}, LOAN_DATA, (parent?: ILoanData) => ({
    objectInformation: objectInformationForm(parent?.objectInformation),
    products: new FinprocessFormArray((parent?.products ?? []).map(product => productForm(product)),
        {
            validator: createProviderInput({
                providers: LOAN_DATA,
                fn: (loanData: ILoanData) => ColtValidators.productGuaranteeCreditLinesValidation(loanData.guaranteeCreditLines),
            }),
        }),
    guaranteeCreditLines: new FinprocessFormArray((parent?.guaranteeCreditLines ?? []).map(guaranteeCreditLine => guaranteeCreditLineForm(guaranteeCreditLine)),
        {
            validator: createProviderInput({
                providers: LOAN_DATA,
                fn: (loanData: ILoanData) => ColtValidators.productGuaranteeCreditLinesValidation(loanData.products),
            }),
        }),
}));
