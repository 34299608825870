export enum InterestMethodVariable {
   /**
    * Variabel mit 3-Mo Euribor
    */
   VariableThreeMo = 1,

   /**
    * Variabel mit 6-Mo Euribor
    */
   VariableSixMo = 2,

   /**
    * Variabel mit 1-Mo Euribor
    */
   VariableOneMo = 8,
}
