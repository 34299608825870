<div class="finprocess-stepper" fxLayout="row" fxLayoutAlign="flex-start center">
    <ng-container *ngFor="let step of steps; let i = index">
        <div fxLayout="row" fxLayoutAlign="start center" class="finprocess-stepper-step"
            [routerLink]="step.route" routerLinkActive="active" [class.disabled]="step.disabled"
            [queryParams]="step.queryParams"
            (click)="selectedStepIndexChanged.emit(i)">
            <div class="finprocess-stepper-bauble-container">
                <div class="finprocess-stepper-bauble">
                 <ng-container [ngSwitch]="step.status">
                     <mat-icon *ngSwitchCase="'success'" class="finprocess-stepper-bauble-icon success">check_circle</mat-icon>
                     <mat-icon *ngSwitchCase="'warn'" class="finprocess-stepper-bauble-icon warn">error</mat-icon>
                     <mat-icon *ngSwitchCase="'pending'" class="finprocess-stepper-bauble-icon pending">pending</mat-icon>
                     <mat-icon *ngSwitchCase="'error'" class="finprocess-stepper-bauble-icon error">cancel</mat-icon>
                     <mat-icon *ngSwitchDefault class="finprocess-stepper-bauble-icon edit">edit</mat-icon>
                 </ng-container>
                </div>
            </div>
            <span class="finprocess-stepper-label">
                {{ (i+1) + '. ' + step.label }}
            </span>
        </div>
    </ng-container>
</div>
