import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AssetService, FinancingService, ICollateralAccountDetail, ICollateralInsuranceDetail, ICollateralRealEstateDetail, IProductPackageData } from 'app/modules/financing/data';
import { Observable, Subject, take } from 'rxjs';

import { EnumTranslationPipe } from '../../../../../../../../../../../libs/enums/src/lib/pipes/enum-translation/enum-translation.pipe';
import { AssetCommonComponent } from '../../asset-common/asset-common';
import { ICollateralTableInterface } from '../collateral-real-estate-details/collateral-real-estate-details.component';

/**
 * Besicherung
 */
@Component({
    selector: 'finprocess-collateralisation',
    templateUrl: './collateral-collateralisation.component.html',
    styleUrls: ['./collateral-collateralisation.component.scss'],
})
export class CollateralisationComponent extends AssetCommonComponent implements OnDestroy {

    @Input()
    public collateralDetail!: ICollateralRealEstateDetail | ICollateralInsuranceDetail | ICollateralAccountDetail;

    @Input()
    public dataSources : ICollateralTableInterface[] | undefined;

    @Input()
    public editingReadonly$!: Observable<boolean>;

    /**
     * ProductPackage
     */
    @Input()
    public productPackage?: IProductPackageData;

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Konstruktor
     * 
     * @param {FinancingService} financingService financingservice
     * @param {TranslateService} translate TranslateService
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param {AssetService} assetService assetservice
     * @param {WaiterService} waiterService waiterService
     * @param {NotificationService} notificationService notficationservice
     * @param {ChangeDetectorRef} changeDetection change detection
     * @param {Store} store store
     */
    public constructor(financingService: FinancingService, private translate: TranslateService,
        enumTranslate: EnumTranslationPipe, assetService: AssetService, private waiterService: WaiterService,
        private notificationService: NotificationService, private changeDetection: ChangeDetectorRef, store: Store) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )
    }

    /**
     * secure Product to Collateral
     *
     * @param {string} collateralID collateralid
     * @param {string} productID productID
     * @param {boolean} isSecured true/false
     */
    public productSecured(collateralID: string, productID: string, isSecured: boolean) {
        if (!!this.productPackage?.assignProductPackages[0].productPackageID) {
            this.assetService.secureProductToCollateral(collateralID, productID, this.productPackage?.assignProductPackages[0].productPackageID, isSecured).pipe(take(1)).subscribe({
                error: () => {
                    this.waiterService.hide();
                    this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('asset.assetDetails.secureProductCollateralError'));
                },
            });
        }
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
