import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { EnumTranslationPipe, ProductPackageType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { DocumentService, FinancingService, FinancingState, IFinancing, IFinancingStateParentDefinition, IGetSampleCalculationDocumentsInfo, IProductPackageData, IRFPData, InterestMethodVariable, RepaymentPeriod } from 'app/modules/financing/data';
import { IProductPackage } from 'app/modules/financing/data/interfaces/product-package.interface';
import { OverwriteHelperService } from 'app/modules/financing/util';
import { FinancingStatus, HelperService, UUID } from 'app/modules/shared';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { Observable, Subject, combineLatest, map, take, takeUntil } from 'rxjs';

import { GenerateSampleCalculationComponent } from '../generate-sample-calculation/generate-sample-calculation.component';
import { IUpdateProductPackage, IUpdateProductPackageItem } from '../product-package/product-package.component';

/**
 * Zinsbindung
 */
@Component({
    selector: 'finprocess-financingdetails',
    templateUrl: './financingdetails.component.html',
    styleUrls: ['./financingdetails.component.scss'],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.yyyy',
                },
                display: {
                    dateInput: 'DD.MM.yyyy',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
    ],
})
export class FinancingDetailsComponent implements OnInit, OnDestroy {

    /**
     * Subject beim Entfernen der Komponente
     */
    public onDestroy$ = new Subject<void>();

    /**
     * Product Package Data
     */
    public productPackageData?: IProductPackageData;

    /**
     * ProductPackageType
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public ProductPackageType = ProductPackageType;

    /**
     * Sample Calculation Data
     */
    public sampleCalculationDocuments?: IGetSampleCalculationDocumentsInfo[];

    /**
     * ProductPackageID
     */
    public productPackageID?: UUID;

    /**
     * Indikator Enum Array
     */
    public indicator: InterestMethodVariable[] = [];

    /**
     * RepaymentPeriod Enum Array
     */
    public repaymentPeriod: RepaymentPeriod[] = [];

    /**
     *  Number Mask
     */
    public numberMaskOptions: NgxCurrencyConfig;

    /**
     *  Currency Mask
     */
    public currencyMaskOptions: NgxCurrencyConfig;

    /**
     *  percentage Mask
     */
    public percentageMaskOptions: NgxCurrencyConfig;

    /**
     * Langfristiger Finanzierungsbedarf Brutto Selector
     */
    @Select(FinancingState.grossFinancingRequirement)
    public grossFinancingRequirement$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * langfristiger Finanzierungsbedarf - Finanzierungsrahmen
     */
    public grossFinancingRequirement?: number;

    //Werte fürs draufrechnen auf den Finanzierungsbedarf//
    public redemptionInsurance?: number;
    public bausparCreditBalance?: number;
    public funding?: number;
    public otherOwnCapital?: number;

    /**
     * Differenz zwischen grossFinancingRequirement und neuer errechneter Finanzierungsbedarf
     */
    public differenceGrossFinancing?: number;

    /**
     * Warnungstext wenn Finanzierungsbedarf überschritten wurde
     */
    public warningFee = false;

    /**
     * RFP Position
     */
    public riskfinancingPlanPosition?: number;

    /**
     * Observable Schreibschutz mit Bearbeitungsmodus
     */
    public fieldReadonly$!: Observable<boolean>;

    /**
     * disable create SampleCalculation Button
     */
    public disableCreatingSampleCalculations = false;

    /**
     * Legalisierungsgebühr
     */
    @Select(FinancingState.legalisationFee)
    public legalisationFee$!: Observable<(withOverwrites?: boolean) => number>;

    /**
     * Ist eigener Angestellte
     */
    public isOwnEmployee?: boolean;

    /**
     * Formular
     */
    public form: FormGroup;

    /**
     * Product Packages FormArray
     * 
     * @returns {FormArray} form array
     */
    public get productPackages(): FormArray {
        const formArray = this.form.get('productPackages') as FormArray;
        return formArray;
    }

    /**
     * Garantiesumme
     */
    public guaranteeSum?: number;

    /**
     * Konstruktor
     *
     * @param {FinancingService} financingService financingservice
     * @param {ActivatedRoute} activatedRoute activatedroute
     * @param {ChangeDetectorRef} changeDetec changedetection
     * @param {EnumTranslationPipe} enumTranslate enumtranslation
     * @param {string} locale locale
     * @param {MatDialog} dialog dialog
     * @param {WaiterService} waiterService waiterservice
     * @param {NotificationService} notificationService notificationservice
     * @param {TranslateService} translate translateservice
     * @param {DocumentService} documentService document service
     * @param {Store} store store
     * @param {FormBuilder} fb FormBuilder-Injektor
     */
    public constructor(private financingService: FinancingService, private activatedRoute: ActivatedRoute,
        private changeDetec: ChangeDetectorRef, private enumTranslate: EnumTranslationPipe, @Inject(LOCALE_ID) locale: string,
        private dialog: MatDialog, private waiterService: WaiterService, private notificationService: NotificationService,
        private translate: TranslateService, private documentService: DocumentService, private store: Store,
        private fb: FormBuilder) {

        this.numberMaskOptions = HelperService.getInputMask(locale, {
            precision: 0,
            thousands: '',
            decimal: '',
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.currencyMaskOptions = HelperService.getInputMask(locale, {
            prefix: '€ ',
            precision: 2,
            inputMode: NgxCurrencyInputMode.Natural,
        });

        this.percentageMaskOptions = HelperService.getInputMask(locale, {
            suffix: '%',
            precision: 3,
            inputMode: NgxCurrencyInputMode.Natural,
            max: 100,
        });

        this.form = this.fb.group({
            productPackages: this.fb.array([]),
        });
    }

    /**
     * Initialisierung
     */
    public ngOnInit() {
        this.fieldReadonly$ = this.financingService.editingReadonlyWithEditmodeExpert$;

        this.financingService.editingReadonlyWithEditmodeExpert$.pipe(takeUntil(this.onDestroy$)).subscribe(disabled => {
            if (disabled === true) {
                this.form?.disable();
            }
            else {
                this.form?.enable();
            }
        })

        this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer?.status).pipe(takeUntil(this.onDestroy$)).subscribe(status => {
            if (status === FinancingStatus.SampleCalculationWaitingForAcception) {
                this.disableCreatingSampleCalculations = true;
            }
        });

        this.indicator = (HelperService.getEnumArray(InterestMethodVariable) as number[]);
        this.repaymentPeriod = (HelperService.getEnumArray(RepaymentPeriod) as number[]);

        this.activatedRoute.paramMap.pipe(
            map(params => {
                if (!!params) {
                    this.waiterService.show();
                    const productPackageID = params.get('productPackageID') as UUID;
                    const riskFinancingPlanID = params.get('riskfinancingplanid') as UUID;
                    this.productPackageID = productPackageID;
                    return { productPackageID, riskFinancingPlanID };
                } else {
                    return null;
                }
            }),
        ).subscribe({
            next: result => {
                if (!!result) {

                    const productPackage: IProductPackageData | undefined = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.productPackages);
                    const financing: IFinancing | undefined = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);
                    const rfp: IRFPData[] | undefined = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.riskFinancingPlans);

                    //get product package data
                    if (!!productPackage) {
                        const ppClone = HelperService.clone(productPackage);
                        const filteredProductPackage = ppClone.assignProductPackages.filter(pp => pp.productPackageID === this.productPackageID);

                        this.productPackageData = {
                            assignProductPackages: filteredProductPackage,
                            financingMapID: productPackage.financingMapID,
                        };
                        this.setDefaultValues();
                        this.setForm();
                    }

                    //set financing details values
                    if (!!financing) {
                        this.calculateFinancingValues(financing);
                        this.isOwnEmployee = financing.households.some(household => household.debitors.some(debitor => debitor.isOwnEmployee));
                    }
                    else {
                        this.financingService.loadFinancing(result.riskFinancingPlanID).pipe(take(1)).subscribe(finance => {
                            if (!!finance) {
                                this.calculateFinancingValues(finance);
                                this.isOwnEmployee = finance.households.some(household => household.debitors.some(debitor => debitor.isOwnEmployee));
                            }
                        })
                    }

                    //set rfp position
                    if (!!rfp) {
                        const rfpData = rfp.find(rfpID => rfpID.id === result.riskFinancingPlanID);
                        this.riskfinancingPlanPosition = rfpData?.position;
                    }
                    else {
                        this.financingService.getRiskFinancingPlan(result.riskFinancingPlanID).pipe(take(1)).subscribe(plan => {
                            if (!!plan) {
                                this.riskfinancingPlanPosition = plan.position;
                            }
                        })
                    }

                    this.waiterService.hide();
                    this.changeDetec.detectChanges();
                }
            },
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.loadProductError'));
            },
        });

        this.productPackages.controls.forEach((control: AbstractControl) => {
            const productGroup = control as FormGroup;
            this.updateDatesInterestOnlyPeriod(productGroup);

            const repaymentPeriodControl = control.get('repaymentPeriod');
            const durationControl = control.get('durationWithoutASD');
            const interestOnlyPeriodControl=control.get('interestOnlyPeriod');
            repaymentPeriodControl?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
                this.calculateRatesCount(control, value, durationControl?.value,interestOnlyPeriodControl?.value);
            });
            interestOnlyPeriodControl?.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                this.calculateRatesCount(control, repaymentPeriodControl?.value, durationControl?.value,interestOnlyPeriodControl?.value);
            });
        });

        this.getAllSampleCalculations();
    }

    /**
     * Update Repayment Date || Interest Only Period
     * 
     * @param {FormGroup} productGroup FormGroup
     */
    private updateDatesInterestOnlyPeriod(productGroup: FormGroup) {
        const repaymentDateControl = productGroup.get('repaymentDate');
        const disbursementDateControl = productGroup.get('disbursementDate');
        const interestOnlyPeriodControl = productGroup.get('interestOnlyPeriod');

        if (!!repaymentDateControl && !!disbursementDateControl) {
            combineLatest([
                repaymentDateControl.valueChanges,
                disbursementDateControl.valueChanges,
            ]).pipe(
                takeUntil(this.onDestroy$)).subscribe(([repaymentDate, disbursementDate]) => {
                if (!!repaymentDate && !!disbursementDate) {
                    const interestOnlyPeriod = this.calculateMonthsDifference(new Date(disbursementDate), new Date(repaymentDate));
                    productGroup.get('interestOnlyPeriod')?.setValue(interestOnlyPeriod, { emitEvent: false });
                }
            });
        }

        if (interestOnlyPeriodControl !== null) {
            interestOnlyPeriodControl.valueChanges.pipe(
                takeUntil(this.onDestroy$)).subscribe(value => {
                if (value !== undefined) {
                    const disbursementDate = new Date(disbursementDateControl?.value);
                    const newRepaymentDate = new Date(disbursementDate);
                    newRepaymentDate.setMonth(newRepaymentDate.getMonth() + value);
                    productGroup.get('repaymentDate')?.setValue(newRepaymentDate, { emitEvent: false });
                }
            });
        }
    }
    
    /**
     * calculate Rates Count
     * 
     * @param {AbstractControl} control AbstractControl
     * @param {RepaymentPeriod} repaymentPeriod RepaymentPeriod
     * @param {number} durationWithoutASD DurationWithoutASD
     * @param {number} interestOnlyPeriod InterestOnlyPeriod
     */
    public calculateRatesCount(control: AbstractControl, repaymentPeriod: RepaymentPeriod, durationWithoutASD: number, interestOnlyPeriod: number) {
        if (!!durationWithoutASD &&!!interestOnlyPeriod && !!repaymentPeriod) {
            let calculatedRatesCount: number;

            /**
             * siehe https://ntag-mspro04/NT.AG%20Financing/UCBA_Kreditanwendung/_wiki/wikis/UCBA_Kreditanwendung.wiki/589/Ermittlung-der-Kreditlaufzeit
             * zusätzliche ASD Periode wird hier nicht berücksichtigt -> =0
             */
            const sumOfMonths = durationWithoutASD-interestOnlyPeriod;

            if (repaymentPeriod === RepaymentPeriod.Monthly) {
                calculatedRatesCount = sumOfMonths;
            } else if (repaymentPeriod === RepaymentPeriod.Quarterly) {
                calculatedRatesCount = (sumOfMonths / 12) * 4;
            } else if (repaymentPeriod === RepaymentPeriod.HalfYearly) {
                calculatedRatesCount = sumOfMonths / 6;
            } else if (repaymentPeriod === RepaymentPeriod.Yearly) {
                calculatedRatesCount = sumOfMonths / 12;
            } else {
                calculatedRatesCount = 0;
            }
 
            calculatedRatesCount = parseFloat(calculatedRatesCount.toFixed(2));

            control.get('ratesCount')?.setValue(calculatedRatesCount);

            const product = this.productPackageData?.assignProductPackages[0].assignProducts.find(changeProduct => changeProduct.productID === control.get('productID')?.value);
            if (product) {
                product.ratesCount = calculatedRatesCount;
            }

            this.changeDetec.detectChanges();
        }
    }

    /**
     * update Products
     *
     * @param {IProductPackage} productPackage Product Package
     */
    public updateProducts(productPackage?: IProductPackage): void {

        if (!!productPackage) {
            // TODO: update product data first (fee sum calculated with old values)
            // TODO: calculate fee sum when page loaded?!
            this.calculateFeeSum(productPackage);
        }

        this.productPackages.controls.forEach(products => {
            if (products.dirty && products.valid) {
                const productData = products.getRawValue() as Partial<IUpdateProductPackageItem>;
                productData.productPackageID = this.productPackageID;

                this.guaranteeSum = (productData.guaranteeAmount ?? 0) - (productData.specialFinancing ?? 0);

                if (this.guaranteeSum >= 0 || productData.guaranteeAmount === 0 && productData.specialFinancing === 0) {
                    this.financingService.updateProductPackageItem(productData).pipe(take(1)).subscribe({
                        error: () => {
                            this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.saveProduct'));
                        },
                    });
                }

                //mark formgroup as pristine after saving
                products.markAsPristine();
            }
        });
    }

    /**
     * set Form
     */
    public setForm() {

        this.productPackageData?.assignProductPackages[0].assignProducts.forEach(product => {

            this.guaranteeSum = (product.guaranteeAmount ?? 0) - (product.specialFinancing ?? 0);

            const productGroup = this.fb.group({
                ratesCount: [product.ratesCount, Validators.required],
                disbursementDate: [product.disbursementDate],
                productID: [product.productID],
                repaymentPeriod: [product.repaymentPeriod, Validators.required],
                repaymentDate: [product.repaymentDate],
                creditAmount: [product.creditAmount, Validators.required],
                interestOnlyPeriod: [product.interestOnlyPeriod],
                indicator: [product.indicator, Validators.required],
                interestReferenceRate: [product.interestReferenceRate, Validators.required],
                addition: [product.addition, Validators.required],
                additionForComparativeAnalysis: [product.additionForComparativeAnalysis],
                guaranteeAmount: [product.guaranteeAmount],
                specialFinancing: [product.specialFinancing],
                variableInterest: [product.variableInterest],
                variableInterestFix5Year: [product.variableInterestFix5Year],
                variableInterestFix10Year: [product.variableInterestFix10Year],
                variableInterestFix15Year: [product.variableInterestFix15Year],
                variableInterestFix20Year: [product.variableInterestFix20Year],
                variableInterestFix25Year: [product.variableInterestFix25Year],
                riskAmount: [product.riskAmount],
                processingFee: [product.processingFee],
                valuationFee: [product.valuationFee],
                externalValuationFee: [product.externalValuationFee],
                accountFee: [product.accountFee],
                legalFee: [product.legalFee],
                brokerage: [product.brokerage],
                landRegisterEntryFee: [product.landRegisterEntryFee],
                priorityRankingFee: [product.priorityRankingFee],
                landRegisterRecord: [product.landRegisterRecord],
                landRegisterApplication: [product.landRegisterApplication],
                durationWithoutASD: [product.durationWithoutASD],
                id: [product.productID],
            });
            this.productPackages.push(productGroup);
        });
    }

    /**
     * set default values
     */
    public setDefaultValues() {
        if (!!this.productPackageData) {
            this.productPackageData.assignProductPackages.forEach(productPackage => {
                // eslint-disable-next-line complexity
                productPackage.assignProducts.forEach(productItem => {
                    if ((productItem.productCreditType === ProductPackageType.Credit && productItem.indicator === 0)) {
                        productItem.indicator = InterestMethodVariable.VariableThreeMo;
                    }

                    if ((productItem.productCreditType === ProductPackageType.BWWithoutFollowUpFinancing || productItem.productCreditType === ProductPackageType.BWWithFollowUpFinancing) && productItem.indicator === 0) {
                        productItem.indicator = InterestMethodVariable.VariableOneMo;
                    }
                });
            })
        }
    }


    /**
     * calculate financing details values
     * 
     * @param {IFinancing} financing financing
     */
    public calculateFinancingValues(financing: IFinancing) {

        //Ablösekapital Versicherung
        const prefinancingInsurance = OverwriteHelperService.getMergedOverwriteValue(financing, 'prefinancingInsurance', financing.prefinancingInsurance);
        if (prefinancingInsurance === true) {
            this.redemptionInsurance = OverwriteHelperService.getMergedOverwriteValue(financing, 'redemptionInsurance', financing.redemptionInsurance);
        }

        //Bausparguthaben
        const prefinancingBausparCreditBalance = OverwriteHelperService.getMergedOverwriteValue(financing, 'prefinancingBausparCreditBalance', financing.prefinancingBausparCreditBalance);
        if (prefinancingBausparCreditBalance === true) {
            this.bausparCreditBalance = OverwriteHelperService.getMergedOverwriteValue(financing, 'bausparCreditBalance', financing.bausparCreditBalance);
        }

        //Förderung
        const prefinancingFunding = OverwriteHelperService.getMergedOverwriteValue(financing, 'prefinancingFunding', financing.prefinancingFunding);
        if (prefinancingFunding === true) {
            this.funding = OverwriteHelperService.getMergedOverwriteValue(financing, 'funding', financing.funding);
        }

        //Sonstige Mittel
        const prefinancingOtherOwnCapital = OverwriteHelperService.getMergedOverwriteValue(financing, 'prefinancingOtherOwnCapital', financing.prefinancingOtherOwnCapital);
        if (prefinancingOtherOwnCapital === true) {
            this.otherOwnCapital = OverwriteHelperService.getMergedOverwriteValue(financing, 'otherOwnCapital', financing.otherOwnCapital);
        }

        //get current Finanzierungsbedarf Brutto and add optional values to it
        this.grossFinancingRequirement$.pipe(takeUntil(this.onDestroy$)).subscribe((valueGetter: (withOverwrites?: boolean) => number) => {
            const grossFinancingRequirementValue = valueGetter();
            this.grossFinancingRequirement = grossFinancingRequirementValue !== undefined ? (grossFinancingRequirementValue === 0 ? 0 : grossFinancingRequirementValue) : undefined;

            if (this.grossFinancingRequirement !== undefined) {
                this.grossFinancingRequirement += ((this.redemptionInsurance || 0) + (this.bausparCreditBalance || 0) + (this.funding || 0) + (this.otherOwnCapital || 0));
            }
        });
    }

    /**
     * get all sample calculations
     */
    public getAllSampleCalculations() {
        if (!!this.productPackageID) {
            this.financingService.getAllSampleCalculations(this.productPackageID).subscribe(res => {
                if (!!res) {
                    this.sampleCalculationDocuments = res.sampleCalculations;
                    this.changeDetec.detectChanges();
                }
            });
        }
    }

    /**
     * translate product package enum type
     *
     * @param {ProductPackageType} productType product package type
     * @returns {string} translated enum type
     */
    public getTranslatedProductType(productType: ProductPackageType): string {
        return this.enumTranslate.transform(productType, 'ProductPackageType') as string;
    }

    /**
     * Open Generate Sample Caluclation Dialog
     */
    public openGenerateSampleCalculation() {
        const dialogRef = this.dialog.open(GenerateSampleCalculationComponent, {
            maxWidth: '80vw',
            minWidth: '80vw',
            data: { rfpPosition: this.riskfinancingPlanPosition, productPackageID: this.productPackageID },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.getAllSampleCalculations();
            }
        });
    }

    /**
     * update ProductPackages
     *
     * @param {IProductPackage} productPackage productpackage data
     */
    public updateProductPackage(productPackage: IProductPackage) {

        const updateProductPackage: Partial<IUpdateProductPackage> = {
            productPackageID: this.productPackageID, //required
            description: productPackage.description, //required
            packageName: productPackage.name, //required

            grossFinancingProductPackages: productPackage.grossFinancingProductPackages,
        }

        this.financingService.updateProductPackage(updateProductPackage).pipe(take(1)).subscribe({
            error: () => {
                this.waiterService.hide();
                this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.productPackages.saveProductPackage'));
            },
        });
    }

    /**
     * calculate fee sum and show warning when financing scope has been exceeded
     *
     * @param {IProductPackage} products product package data
     */
    public calculateFeeSum(products: IProductPackage) {
        const creditSum = products.assignProducts.reduce((sum, product) => sum + (product.creditAmount), 0);

        const feeSum = products.assignProducts.reduce((sum, product) => sum + product.riskAmount + product.valuationFee +
            product.accountFee + product.legalFee +
            product.brokerage + product.landRegisterEntryFee +
            product.priorityRankingFee + product.landRegisterRecord +
            product.externalValuationFee + product.landRegisterApplication, 0);

        const result = feeSum + creditSum;

        //Wenn Finanzierungsbedarf pberschritten dann Warnung anzeigen
        if (this.grossFinancingRequirement !== undefined && result > this.grossFinancingRequirement) {
            this.warningFee = true;
            this.differenceGrossFinancing = result - this.grossFinancingRequirement;
            this.differenceGrossFinancing = parseFloat(this.differenceGrossFinancing.toFixed(3)); //3 Nachkommastellen
            this.changeDetec.detectChanges();
        }
        //Wenn Finanzierungsbedarf unterschritten -> neuer Wert berechnen und in grossfinancingProductPackages speichern
        else if (this.grossFinancingRequirement !== undefined && result < this.grossFinancingRequirement) {
            products.grossFinancingProductPackages = result;
            this.updateProductPackage(products);
            this.warningFee = false;
        }
    }

    /**
     * delete sample calculation document
     *
     * @param {UUID} id sample calculation id
     */
    public deleteSampleCalcDocument(id: UUID) {
        this.notificationService.confirmOkCancel(this.translate.instant('financing.features.financing-processing.financing-details.deleteSC'), this.translate.instant('financing.features.financing-processing.financing-details.deleteSCtext')).subscribe(res => {
            if (res === 'submit') {
                this.waiterService.show();
                this.financingService.deleteSampleCalculation(id).pipe(take(1)).subscribe({
                    next: () => {
                        this.notificationService.toast(this.translate.instant('financing.features.financing-processing.financing-details.deleteSCsuccess'));
                        this.getAllSampleCalculations();
                        this.waiterService.hide();
                    },
                    error: () => {
                        this.notificationService.alert(this.translate.instant('general.error'), this.translate.instant('financing.features.financing-processing.financing-details.deleteSCerror'));
                        this.waiterService.hide();
                    },
                });
            }
        });
    }

    /**
     * open sample calculation document
     *
     * @param {UUID} id sample calculation id
     * @param {string} fileName fileName
     */
    public openSampleCalculationDocument(id: UUID, fileName: string) {
        this.waiterService.show();
        this.financingService.getSampleCalculation(id).pipe(take(1)).subscribe({
            next: async fileContents => {
                if (fileContents === undefined || fileContents === null) {
                    this.waiterService.hide();
                    return;
                }

                await HelperService.downloadFileFromBlob(fileContents, fileName);
                this.waiterService.hide();
            },
        });
    }

    /**
     * Calculate Months Difference
     * 
     * @param {Date} startDate Start Date
     * @param {Date} endDate End Date
     * @returns {number} months difference
     */
    private calculateMonthsDifference(startDate: Date, endDate: Date): number {
        const years = endDate.getFullYear() - startDate.getFullYear();
        const months = endDate.getMonth() - startDate.getMonth();
        return years * 12 + months;
    }

    /**
     * Angular Lifecycle beim Entfernen der Komponente
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
