<ng-template #labelValueTemplate let-fieldName="fieldName" let-value="value">
  <finprocess-label-value>
      <span label>{{ ('asset.assetCommon.assetsAgp.'+fieldName) | translate }}</span>
      <span value>{{ (value !== undefined && value !== null) ? value : '-' }}</span>
  </finprocess-label-value>
</ng-template>

<ucba-heading [type]="'medium'">
  <div ucba-heading-title>{{ 'asset.assetCommon.assetsAgp.header' | translate }}</div>
  <div ucba-heading-body-visible>
    @for (assetAgp of assetsAgps; track $index) {
      <div [class.tile-margin]="$index !== 0" [class.darker-tiles]="$index % 2 !== 0">
        <ucba-tile-layout>
          <!-- Sicherheitennummer -->
          <ng-template *ngIf="!!assetAgp.collateralNo" ucba-tile>
            <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'collateralNo', value: assetAgp.collateralNo}"></ng-container>
          </ng-template>
    
          <!-- Ausnutzungskonten -->
          <ng-template *ngIf="!!assetAgp.withdrawalAccounts" ucba-tile>
            <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'withdrawalAccounts', value: assetAgp.withdrawalAccounts }"></ng-container>
          </ng-template>
    
          <!-- Hypothekenart -->
          <ng-template *ngIf="assetAgp.mortgageType !== null && assetAgp.mortgageType !== undefined" ucba-tile>
            <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'mortgageType', value: findArrayElementWithId(mortgageTypeSelectItems, assetAgp.mortgageType)?.displayName }"></ng-container>
          </ng-template>
    
          <!-- Höhe der PBU -->
          <ng-template *ngIf="!!assetAgp.pbuValue" ucba-tile>
            <ng-container *ngTemplateOutlet="labelValueTemplate; context: { fieldName: 'pbuValue', value: assetAgp.pbuValue }"></ng-container>
          </ng-template>
        </ucba-tile-layout>
      </div>
    } @empty {
      <ucba-tile-layout>
        <ng-template ucba-tile [span]="5">
          {{'asset.assetCommon.assetsAgp.noData' | translate}}
        </ng-template>
      </ucba-tile-layout>
    }
  </div>
</ucba-heading>
