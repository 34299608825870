/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreditPurpose } from '../../workflow-processing/enums/colt';
import { LoanPurpose } from '../enums';

/**
 * Prüft ob das übergebene Objekt nur als Properties null oder [] hat
 * 
 * @param {Record} value zu prüfendes Objekt
 * @returns {boolean} ist Objekt leer
 */
export function isObjectEmpty<T extends object>(value: T): boolean {
    if (!!value) {
        const keys = Object.keys(value) as Array<keyof typeof value>;

        for (const key of keys) {
            const prop = value[key];
            if (prop !== null && prop !== undefined && prop !== '' && (!Array.isArray(prop) || (prop as Array<unknown>).length > 0)) {
                return false;
            }
        }
    }

    return true;
}

/**
 * Ermittelt den größsten gemeinsamen Teiler
 * 
 * @see https://stackoverflow.com/a/41888395
 * @param {number} a erster Wert
 * @param {number} b zweiter Wert
 * @returns {number} gcd
 */
export function gcd(a: number, b: number): number {
    let t = 0;
    a < b && (t = b, b = a, a = t); // swap them if a < b
    t = a % b;
    return t ? gcd(b, t) : b;
}

/**
 * Ermittelt das kleinste gemeinsame Vielfache
 * 
 * @see https://stackoverflow.com/a/41888395
 * @param {number} a erster Wert
 * @param {number} b zweiter Wert
 * @returns {number} lcm
 */
export function lcm(a: number, b: number): number {
    return a / gcd(a, b) * b;
}

/**
 * Ermittelt das kleinste gemeinsame Vielfache aus einem Array
 * 
 * @see https://stackoverflow.com/a/41888395
 * @param {number[]} a Array
 * @returns {number} lcm
 */
export function lcmArray(a: number[]): number {
    return a.reduce(lcm);
}

/**
 * Summiert Brüche zusammen
 * 
 * @param {Array<{ nominator: number, denominator: number }>} values aufzusumierende Brüche
 * @returns {{ nominator: number, denominator: number }} aufsummierter Bruch
 */
export function sumFractions(values: Array<{ nominator: number, denominator: number }>): { nominator: number, denominator: number } {
    const lcmValue = lcmArray(values.map(({ denominator }) => denominator));
    const result = { nominator: 0, denominator: lcmValue };

    for (const v of values) {
        const factor = lcmValue / v.denominator;
        result.nominator += v.nominator * factor;
    }

    return result;
}

/**
 * Mapping eines Finanzierungszwecks in GwbIdPurpose 
 * 
 * @param {CreditPurpose} creditPurpose Finanzierungszwecks
 * @returns {LoanPurpose} GwbIdPurpose
 */
export function creditPurposeToLoanPurpose(creditPurpose: CreditPurpose): LoanPurpose | null {
    switch (creditPurpose) {
        case CreditPurpose.Construction:
        case CreditPurpose.Expansion:
        case CreditPurpose.Other:
        case CreditPurpose.Purchase:
        case CreditPurpose.Rescheduling:
        case CreditPurpose.TechEquipment: return LoanPurpose.HMMO;
        case CreditPurpose.Refurbishment: return LoanPurpose.HREN;
        default: return null;
    }
}
