import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { NgStyle } from '@angular/common';
import {
    Component,
    InjectionToken,
    ViewEncapsulation,
    computed,
    contentChildren,
    input,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IUcbaTile } from '../../interfaces';

import { UCBA_TILE } from './../../directives/tile.directives';

export const UCBA_TILE_LAYOUT = new InjectionToken<UcbaTileLayoutComponent>(
    'UCBA_TILE_LAYOUT',
);

/**
 * A component for tile based layouts.
 *
 * Usage:
 * ```html
 * <ucba-tile-layout>
 *   <ng-template ucbaTile>
 *    ... Add content here ...
 *   </ng-template>
 *   <ng-template ucba-tile>
 *     ... Add content here ...
 *   </ng-template>
 * </ucba-tile-layout>
 * ```
 *
 */
@Component({
    selector: 'ucba-tile-layout',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './tile-layout.component.html',
    styleUrls: ['./tile-layout.component.scss'],
    standalone: true,
    providers: [
        { provide: UCBA_TILE_LAYOUT, useExisting: UcbaTileLayoutComponent },
    ],
    imports: [CdkPortalOutlet, NgStyle],
})
export class UcbaTileLayoutComponent {
    /**
     * Standard number of columns
     */
    public columns = input<number>(5);

    /**
     * Number of columns for medium devices
     */
    public colummsMd = input<number>(3);

    /**
     * Number of columns for small devices
     */
    public columnsSm = input<number>(1);

    /**
     * Default minimum tile width
     */
    public minimumTileWidth = input<number>(200);

    /**
     * The number of columns to display based on the screen size
     */
    protected currentColumns = computed(() => {
        const screenSize = this.screenSize();

        switch (screenSize) {
            case 'sm':
                return this.columnsSm();
            case 'md':
                return this.colummsMd();
            case 'lg':
                return this.columns();
            default:
                return this.columns();
        }
    });

    /**
     * Calculated style for the grid columns
     */
    protected gridColumnsStyle = computed(
        () =>
            `repeat(${this.currentColumns()}, minmax(${this.minimumTileWidth()}px, 1fr))`,
    );

    /**
     * Filtered list of tiles. Only tiles that are direct children of the tile layout are included.
     * This allows to nest tile layouts.
     */
    protected tiles$ = computed<IUcbaTile[]>(() => {
        const currentColumns = this.currentColumns();
        const tiles = this.allTiles$();
        const mappedTiles: IUcbaTile[] = [];
        let rowCounter = 0;

        for (const tile of tiles) {
            if (tile.closestTileLayout !== this) {
                continue;
            }

            if (rowCounter > currentColumns - 1 || tile.forceNewLine() || rowCounter + tile.span() > currentColumns) {
                rowCounter = 0;
            }

            mappedTiles.push({
                tile,
                column: rowCounter,
            });

            rowCounter += tile.span();
        }

        return mappedTiles;
    });

    /**
     * The current screen size
     */
    private screenSize = signal<'sm' | 'md' | 'lg'>('lg');

    /**
     * Tiles transcluded into the tile layout
     */
    private allTiles$ = contentChildren(UCBA_TILE);

    /**
     * Constructor
     *
     * @param {BreakpointObserver} breakpointObserver The breakpoint observer
     */
    public constructor(private breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe([Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
            .pipe(takeUntilDestroyed())
            .subscribe(result => {
                if (result.breakpoints[Breakpoints.Small]) {
                    this.screenSize.set('sm');
                } else if (result.breakpoints[Breakpoints.Medium]) {
                    this.screenSize.set('md');
                } else if (result.breakpoints[Breakpoints.Large]) {
                    this.screenSize.set('lg');
                }
            });
    }
}
