export enum PriorChargeNotEurType {
    /**
     * Belastungs- und Veräußerungsverbot
     */
    SaleBan = 1,

    /**
     * sonstiges Recht
     */
    OtherLaw = 2,
}
