import { CdkPortal } from '@angular/cdk/portal';
import { Directive, InjectionToken, inject, input } from '@angular/core';

import { UCBA_TILE_LAYOUT } from '../components/tile-layout/tile-layout.component';

export const UCBA_TILE = new InjectionToken<UcbaTileDirective>('UCBA_TILE');

/**
 * Directive for a tile inside a tile-layout.
 * 
 * Usage:
 * ```html
 * <ucba-tile-layout>
 *   <ng-template ucbaTile>
 *     ... Add content here ...
 *   </ng-template>
 *   <ng-template ucba-tile>
 *     ... Add content here ...
 *   </ng-template>
 * </ucba-tile-layout>
 * ```
 * 
 * Tiles can span more than one column:
 * ```html
 * <ucba-tile-layout>
 *  <ng-template ucbaTile [span]="2">
 * </ucba-tile-layout>
 * ```
 * 
 * Tiles can be forced to start a new row, and the previous row will be filled with an empty tile:
 * ```html
 * <ucba-tile-layout>
 *   <ng-template ucbaTile [forceNewLine]="true">
 * </ucba-tile-layout>
 * ```
 * 
 */
@Directive({
    selector: '[ucbaTile], [ucba-tile]',
    standalone: true,
    providers: [
        { provide: UCBA_TILE, useExisting: UcbaTileDirective },
    ],
})
export class UcbaTileDirective extends CdkPortal {
    
    /**
     * Finds the closest tile layout.
     */
    public closestTileLayout = inject(UCBA_TILE_LAYOUT, { optional: true });

    /**
     * The number of columns this tile should span. Default is 1.
     */
    public span = input<number>(1);

    /**
     * Forces the tile layout to fill the last row and start a new row for this tile.
     */
    public forceNewLine = input<boolean>(false);
}
