
import { ValidatorFn, Validators } from '@angular/forms';
import { CollateralType } from '@ntag-ef/finprocess-enums/core';
import { CreateFormGroupOptions, FinprocessFormArray, FinprocessFormBuilder, FinprocessFormControl, FinprocessTypedForm, FormProviderToken, createOptions, createProviderInput } from '@ntag-ef/finprocess-forms';

import { AppraisalType, AssignmentType, DepositType, KindOfOwner, OrderType, PriorChargeNotEurType, PriorityAdmissionValueType, PropertyType, TrusteeType, TypeOfShare } from '../enums';
import { ACCOUNT, ASSESSMENT, ASSESSMENT_DATA, ASSET, CADASTRAL_DATA, COLLATERAL, INSURANCE, LEASE, LEASE_OWNER, MARRIAGE_PARTNER, ONLY_OWNERSHIP, OWNER, PRE_EXISTING_CLAIM, PRIOR_CHARGE_EUR, PRIOR_CHARGE_NOT_EUR, TOTAL_PROPERTY_OWNER, TRUSTEE } from '../forms/colt-provider';
import { IAccount, IAssessment, IAssessmentData, IAsset, ICadastralData, ICollateral, IInsurance, ILease, ILeaseOwner, IMarriagePartner, IOnlyOwnership, IOwner, IPreExistingClaim, IPriorChargesEur, IPriorChargesNotEur, ITotalPropertyOwner, ITrustee } from '../interfaces';
import { BA105BA125, ColtValidators, INSURANCES, MORTGAGES, OWN_COLLATERALS } from '../util';
import { INSURANCES_MAX_VALUE } from '../util/colt-constants';
import { isObjectEmpty } from '../util/helper';

const cadastralDataForm = FinprocessFormBuilder.createFormGroup({
    landRegisterNumber: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: collateral => (collateral.isNewCollateral ? Validators.compose([Validators.required, ColtValidators.numeric]) : ColtValidators.numeric),
        }),
    },
    landRegisterName: {
        validator: createProviderInput({
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    districtCourt: {
        validator: createProviderInput({
            providers: [COLLATERAL],
            fn: collateral => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    isNewEz: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => {
                const validators = [ColtValidators.isNewEzGuard(cadastralData.isNewBrez), ColtValidators.totalPropertyRegistrationBrezEz(collateral.trustee)];
                if (collateral.isNewCollateral) {
                    validators.push(Validators.required);
                }

                return Validators.compose(validators);
            },
        }),
        visible: createProviderInput({
            providers: [CADASTRAL_DATA],
            fn: cadastralData => cadastralData.existingEz === '',
        }),
    },
    isNewBrez: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => {
                const validators = [ColtValidators.isNewEzGuard(cadastralData.isNewEz), ColtValidators.totalPropertyRegistrationBrezEz(collateral.trustee)];
                if (collateral.isNewCollateral) {
                    validators.push(Validators.required);
                }
                else {
                    validators.push(ColtValidators.forbiddenValue(true, 'Ja', 'Ist nicht erlaubt bei bestehenden Sicherheiten'));
                }

                return Validators.compose(validators);
            },
        }),
    },
    realPropertyNumberEz: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => ((!cadastralData.isNewEz && !cadastralData.isNewBrez) && collateral.isNewCollateral ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => !cadastralData.isNewEz && !cadastralData.isNewBrez,
        }),
    },
    propertyNumber: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => (collateral.isNewCollateral && (cadastralData.isNewEz || cadastralData.isNewBrez || cadastralData.propertyTypeValue === PropertyType.Superaddicts) ?
                Validators.compose([Validators.required, Validators.maxLength(70)]) : null),
        }),
    },
    existingEz: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => (cadastralData.isNewEz && collateral.isNewCollateral ? Validators.compose([Validators.required, ColtValidators.numeric]) : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.isNewEz,
        }),
    },
    propertyTypeValue: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => {
                const validators = [ColtValidators.reducedAmountGuard(collateral), ColtValidators.totalPropertyRegistrationPropertyType(collateral.trustee), Validators.required];

                if (!collateral.isNewCollateral) {
                    // (!isNewCollateral && isNewBrez) ist nicht erlaubt,
                    // da isNewBrez in !isNewCollateral nicht Ja sein darf
                    validators.push(ColtValidators.fixedValue(PropertyType.Property, 'Liegenschaft'));
                }

                if (cadastralData.isNewBrez) {
                    validators.push(ColtValidators.forbiddenValue(PropertyType.Property, 'Liegenschaft'));
                }

                if (cadastralData.isNewEz || cadastralData.isNewBrez || collateral.isReducedAmount) {
                    validators.push(ColtValidators.forbiddenValue(PropertyType.Superaddicts, 'Superädifikat'));
                }

                if (cadastralData.isNewEz || collateral.isReducedAmount) {
                    validators.push(ColtValidators.forbiddenValue(PropertyType.BuildingRights, 'Baurecht'));
                }

                return Validators.compose(validators)
            },
        }),
    },
}, CADASTRAL_DATA);

const ownerFormArray = (isAsset: boolean, provider: FormProviderToken<IAsset | IOnlyOwnership>, parent?: IAsset | IOnlyOwnership) => new FinprocessFormArray((parent?.owners ?? []).map(owner => ownerForm(isAsset, provider)(owner)), {
    validator: createProviderInput({
        providers: provider,
        fn: (asset: IAsset | IOnlyOwnership) => {
            if (asset.typeOfShareValue === TypeOfShare.Property || asset.typeOfShareValue === TypeOfShare.NewProperty) {
                return Validators.compose([ColtValidators.minValues(1), ColtValidators.maxValues(2)]);
            }
            else if (asset.typeOfShareValue === TypeOfShare.IdealProperty) {
                return Validators.compose([ColtValidators.minValues(1), ColtValidators.maxValues(4)]);
            }

            return ColtValidators.minValues(1);
        },
    }),
})

export const ownerForm = (isAsset: boolean, provider: FormProviderToken<IAsset | IOnlyOwnership>) => FinprocessFormBuilder.createFormGroup<IOwner>({
    ndg: {
        validator: createProviderInput({
            providers: [provider],
            fn: (parent: ({ owners: IOwner[] })) => Validators.compose([ColtValidators.ndgValidators(), ColtValidators.uniqeNdg((parent?.owners?.map(({ ndg }) => ndg) ?? []), 1)]),
        }),
    },
    isAssetShareNumberUnknown: {
        visible: createProviderInput({
            providers: [OWNER, COLLATERAL, TRUSTEE, CADASTRAL_DATA],
            fn: (owner: IOwner, collateral: ICollateral, trustee: ITrustee, cadastralData: ICadastralData) => owner.isAssetShareNumberUnknown || (isAsset && collateral.collateralTypeValue !== CollateralType.BA220 && (collateral.collateralTypeValue !== CollateralType.BA205 || !collateral.isReducedAmount) && cadastralData.propertyTypeValue !== PropertyType.Superaddicts && !!trustee.ndg),
        }),
        validator: createProviderInput({
            providers: [COLLATERAL, TRUSTEE, CADASTRAL_DATA],
            fn: (collateral: ICollateral, trustee: ITrustee, cadastralData: ICadastralData) => ((isAsset && collateral.collateralTypeValue !== CollateralType.BA220 && (collateral.collateralTypeValue !== CollateralType.BA205 || !collateral.isReducedAmount) && cadastralData.propertyTypeValue !== PropertyType.Superaddicts && !!trustee.ndg) ? Validators.compose([ColtValidators.assetShareNumberUnknownGuard(cadastralData), Validators.required]) : ColtValidators.assetShareNumberUnknownGuard(cadastralData)),
        }),
    },
    nominator: {
        validator: createProviderInput({
            providers: [OWNER, provider],
            fn: (owner: IOwner, parent: ({ owners: IOwner[] })) => {
                const validators: ValidatorFn[] = [Validators.min(0), ColtValidators.proportionValidator(parent?.owners)];
                if (owner.isAssetShareNumberUnknown !== true) {
                    validators.push(Validators.required);
                }

                if (typeof owner.denominator === 'number') {
                    validators.push(Validators.max(owner.denominator));
                }

                return Validators.compose(validators);
            },
        }),
    },
    denominator: {
        validator: createProviderInput({
            providers: [OWNER, provider],
            fn: (owner: IOwner, parent: ({ owners: IOwner[] })) => {
                const validators: ValidatorFn[] = [Validators.min(0), ColtValidators.proportionValidator(parent?.owners)];

                if (owner.isAssetShareNumberUnknown !== true) {
                    validators.push(Validators.required)
                }

                return Validators.compose(validators);
            },
        }),
    },
    blNr: {
        visible: {
            providers: [],
            fn: () => isAsset,
        },
        validator: createProviderInput({
            providers: OWNER,
            fn: (owner: IOwner) => (!isAsset || owner.isAssetShareNumberUnknown === true ? null : Validators.required),
        }),
    },
    kindValue: {
        validator: {
            providers: [],
            fn: () => (!isAsset ? Validators.required : null),
        },
        visible: {
            providers: [],
            fn: () => !isAsset,
        },
    },
    commercialRegisterNumber: {
        validator: createProviderInput({
            providers: OWNER,
            fn: (owner: IOwner) => (owner.kindValue === KindOfOwner.LegalPerson ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: OWNER,
            fn: (owner: IOwner) => owner.kindValue === KindOfOwner.LegalPerson,
        }),
    },
    clubRegisterNumber: {
        visible: createProviderInput({
            providers: OWNER,
            fn: (owner: IOwner) => owner.kindValue === KindOfOwner.Club,
        }),
    },
}, OWNER);

export const totalPropertyOwnerForm = FinprocessFormBuilder.createFormGroup<ITotalPropertyOwner>({
    ndg: {
        validator: createProviderInput({
            providers: [TRUSTEE],
            fn: (trustee: ITrustee) => Validators.compose([ColtValidators.ndgValidators(trustee.isTotalPropertyRegistration), ColtValidators.uniqeNdg((trustee?.totalPropertyOwners?.map(({ ndg }) => ndg) ?? []), 1)]),
        }),
    },
    commercialRegisterNumber: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.isTotalPropertyRegistration === true ? Validators.required : null),
        }),
    },
    nominator: {
        validator: createProviderInput({
            providers: [TRUSTEE, TOTAL_PROPERTY_OWNER],
            fn: (trustee: ITrustee, totalPropertyOwner: ITotalPropertyOwner) => {
                const validators: ValidatorFn[] = [];

                if (trustee.isTotalPropertyRegistration === true) {
                    validators.push(Validators.required, Validators.min(0), ColtValidators.proportionValidator(trustee.totalPropertyOwners));
                }

                if (typeof totalPropertyOwner.denominator === 'number') {
                    validators.push(Validators.max(totalPropertyOwner.denominator));
                }

                return Validators.compose(validators);
            },
        }),
    },
    denominator: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.isTotalPropertyRegistration === true ? Validators.compose([Validators.required, Validators.min(0), ColtValidators.proportionValidator(trustee.totalPropertyOwners)]) : null),
        }),
    },
}, TOTAL_PROPERTY_OWNER);

const assessmentDataForm = FinprocessFormBuilder.createFormGroup<IAssessmentData>({
    finProcessAppraisalType: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    marketValue: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    appraisalDate: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    appraisalExternalId: {
        validator: createProviderInput({
            providers: [ASSESSMENT_DATA, COLLATERAL],
            fn: (assessmentData: IAssessmentData, collateral: ICollateral) => {
                const validators = [ColtValidators.numeric];

                if (collateral.isNewCollateral) {
                    validators.push(Validators.required);
                }

                if (assessmentData.finProcessAppraisalType === AppraisalType.LIEBElight || assessmentData.finProcessAppraisalType === AppraisalType.LIEBEprivate) {
                    validators.push(Validators.maxLength(11), Validators.minLength(11));
                }
                else if (assessmentData.finProcessAppraisalType === AppraisalType.BAIRG) {
                    validators.push(Validators.maxLength(10), Validators.minLength(10));
                }
                else if (assessmentData.finProcessAppraisalType === AppraisalType.IRG) {
                    validators.push(Validators.maxLength(9), Validators.minLength(9));
                }

                return Validators.compose(validators);
            },
        }),
    },
    appraiserName: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    appraiserUserId: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        }),
    },
}, ASSESSMENT_DATA);

export const priorChargesEurForm = FinprocessFormBuilder.createFormGroup<IPriorChargesEur>({
    typeValue: {
        validator: Validators.required,
    },
    amount: {
        validator: Validators.required,
    },
    date: null,
    owner: null,
}, PRIOR_CHARGE_EUR)

export const priorChargesNotEurForm = FinprocessFormBuilder.createFormGroup<IPriorChargesNotEur>({
    typeValue: {
        validator: Validators.required,
    },
    priorityAdmissionValue: {
        validator: Validators.required,
    },
    personKindValue: {
        validator: Validators.required,
    },
    name: {
        validator: Validators.required,
    },
    birthDate: {
        validator: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => (charge.personKindValue === KindOfOwner.NaturalPerson ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.personKindValue === KindOfOwner.NaturalPerson,
        }),
    },
    commercialRegisterNumber: {
        validator: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => (charge.personKindValue === KindOfOwner.LegalPerson ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.personKindValue === KindOfOwner.LegalPerson,
        }),
    },
    clubRegisterNumber: {
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.personKindValue === KindOfOwner.Club,
        }),
    },
    clNr: {
        validator: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => (charge.priorityAdmissionValue === PriorityAdmissionValueType.Existing ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.priorityAdmissionValue === PriorityAdmissionValueType.Existing,
        }),
    },
    otherLaw: {
        validator: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => (charge.typeValue === PriorChargeNotEurType.OtherLaw ? Validators.compose([Validators.maxLength(150), Validators.required]) : null),
        }),
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.typeValue === PriorChargeNotEurType.OtherLaw,
        }),
    },
    contractAccordingLandRegister: {
        validator: Validators.maxLength(70),
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.priorityAdmissionValue === PriorityAdmissionValueType.Existing,
        }),
    },
    contractAccordingLandRegisterDate: {
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.priorityAdmissionValue === PriorityAdmissionValueType.Existing,
        }),
        validator: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => (!!charge.contractAccordingLandRegister ? Validators.required : null),
        }),
    },
    purchaseContractPoint: {
        validator: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => (charge.priorityAdmissionValue === PriorityAdmissionValueType.New ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: PRIOR_CHARGE_NOT_EUR,
            fn: (charge: IPriorChargesNotEur) => charge.priorityAdmissionValue === PriorityAdmissionValueType.New,
        }),
    },
}, PRIOR_CHARGE_NOT_EUR)

export const preExistingClaimsForm = FinprocessFormBuilder.createFormGroup<IPreExistingClaim>({
    clnrCoverDelNr: {
        validator: Validators.required,
    },
    isCoveredByNewLoan: {
        validator: Validators.required,
    },
}, PRE_EXISTING_CLAIM);

export const createLeaseOwnerForm = (isAsset: boolean) => FinprocessFormBuilder.createFormGroup<ILeaseOwner>({
    kindValue: {
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (cadastralData.propertyTypeValue === PropertyType.Superaddicts ? Validators.required : null),
        }),
    },
    titleBefore: {
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.NaturalPerson,
        }),
    },
    firstName: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => ((cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.NaturalPerson) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.NaturalPerson,
        }),
    },
    lastName: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => ((cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue !== undefined && leaseOwner.kindValue !== null) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue !== undefined && leaseOwner.kindValue !== null,
        }),
        alternativTranslationKey: createProviderInput({
            providers: LEASE_OWNER,
            fn: (leaseOwner: ILeaseOwner) => {
                switch (leaseOwner.kindValue) {
                    case KindOfOwner.NaturalPerson: return 'lastNameNaturalPerson';
                    case KindOfOwner.LegalPerson: return 'lastNameLegalPerson';
                    case KindOfOwner.Club: return 'lastNameClub';
                    default: return null;
                }
            },
        }),
    },
    titleAfter: {
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.NaturalPerson,
        }),
    },
    birthDate: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => ((cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.NaturalPerson) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.NaturalPerson,
        }),
    },
    address: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA],
            fn: (cadastralData: ICadastralData) => (isAsset && cadastralData.propertyTypeValue === PropertyType.Superaddicts ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [CADASTRAL_DATA],
            fn: (cadastralData: ICadastralData) => isAsset && cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
    commercialRegisterNumber: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => ((cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.LegalPerson) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.LegalPerson,
        }),
    },
    clubRegisterNumber: {
        visible: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner) => cadastralData.propertyTypeValue === PropertyType.Superaddicts && leaseOwner.kindValue === KindOfOwner.Club,
        }),
    },
    nominator: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE_OWNER, LEASE],
            fn: (cadastralData: ICadastralData, leaseOwner: ILeaseOwner, lease: ILease) => {
                const validators: ValidatorFn[] = [];

                if (cadastralData.propertyTypeValue === PropertyType.Superaddicts) {
                    validators.push(Validators.required, Validators.min(0), ColtValidators.proportionValidator(lease.landOwners));
                }

                if (typeof leaseOwner.denominator === 'number') {
                    validators.push(Validators.max(leaseOwner.denominator));
                }

                return Validators.compose(validators);
            },
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
    denominator: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, LEASE],
            fn: (cadastralData: ICadastralData, lease: ILease) => (cadastralData.propertyTypeValue === PropertyType.Superaddicts ?
                Validators.compose([Validators.required, Validators.min(0), ColtValidators.proportionValidator(lease.landOwners)]) :
                null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
}, LEASE_OWNER);

const createLeaseForm = (isAsset: boolean) => FinprocessFormBuilder.createFormGroup<ILease>({
    landOwners: null,
    metersSquared: {
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (cadastralData.propertyTypeValue === PropertyType.Superaddicts ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
    description: {
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (cadastralData.propertyTypeValue === PropertyType.Superaddicts ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
    contractDate: {
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (cadastralData.propertyTypeValue === PropertyType.Superaddicts ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
    brez: {
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => ((cadastralData.propertyTypeValue === PropertyType.BuildingRights && cadastralData.isNewBrez !== true) ? Validators.compose([Validators.required, Validators.min(0)]) : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.BuildingRights && cadastralData.isNewBrez !== true,
        }),
    },
    buildingRightsExpiryDate: {
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (cadastralData.propertyTypeValue === PropertyType.BuildingRights ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.BuildingRights,
        }),
    },
    purchasePrice: {
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (isAsset && cadastralData.propertyTypeValue === PropertyType.Superaddicts ? Validators.compose([Validators.required, Validators.min(0)]) : null),
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => isAsset && cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
    },
    depositType: {
        validator: createProviderInput({
            providers: [CADASTRAL_DATA, COLLATERAL],
            fn: (cadastralData: ICadastralData, collateral: ICollateral) => {
                if (!isAsset || cadastralData.propertyTypeValue !== PropertyType.Superaddicts) {
                    return null;
                }

                if (collateral.collateralTypeValue === CollateralType.BA220) {
                    return Validators.compose([Validators.required, ColtValidators.fixedValue(DepositType.WithoutImmediateDeposit, 'Ohne sofortige Hinterlegung')])
                }

                if (collateral.collateralTypeValue === CollateralType.BA205 || collateral.collateralTypeValue === CollateralType.BA210) {
                    return Validators.compose([Validators.required, ColtValidators.fixedValue(DepositType.WithImmediateDeposit, 'Mit sofortiger Hinterlegung')])
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => isAsset && cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
        default: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (collateral.collateralTypeValue === CollateralType.BA220) {
                    return DepositType.WithoutImmediateDeposit;
                } else if (collateral.collateralTypeValue === CollateralType.BA205 || collateral.collateralTypeValue === CollateralType.BA210) {
                    return DepositType.WithImmediateDeposit;
                }

                return null;
            },
        }),
    },
}, LEASE, (parent?: ILease) => ({
    landOwners: new FinprocessFormArray((parent?.landOwners ?? []).map(landOwner => createLeaseOwnerForm(isAsset)(landOwner)), {
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts,
        }),
        validator: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => (cadastralData.propertyTypeValue === PropertyType.Superaddicts ? ColtValidators.minValues(1) : null),
        }),
    }),
}));

const onlyOwnershipOptions: (provider: FormProviderToken<IAsset | IOnlyOwnership>) => CreateFormGroupOptions<IOnlyOwnership> = (provider: FormProviderToken<IAsset | IOnlyOwnership>) => ({
    cadastralData: null,
    lease: null,
    owners: null,
    typeOfShareValue: {
        validator: createProviderInput({
            providers: [provider, COLLATERAL],
            fn: (onlyOwnership: IOnlyOwnership, collateral: ICollateral) => {
                const validators = [Validators.required, ColtValidators.totalPropertyRegistrationTypeOfShareGuard(collateral.trustee)];

                if (onlyOwnership.cadastralData.propertyTypeValue === PropertyType.Superaddicts) {
                    validators.push(ColtValidators.fixedValue(TypeOfShare.IdealProperty, 'Ideelles Eigentum'));
                }

                return Validators.compose(validators);
            },
        }),
    },
    doorNumber: {
        validator: createProviderInput({
            providers: provider,
            fn: (onlyOwnership: IOnlyOwnership) => ([TypeOfShare.NewProperty, TypeOfShare.Property].includes(onlyOwnership.typeOfShareValue) ?
                Validators.compose([Validators.required, Validators.maxLength(150)]) : Validators.maxLength(150)),
        }),
    },

    // Wird nur im "richtigen" Asset gebraucht. Muss aber in der onlyOwnershipOptions bleiben, da es in der Visibility Map sonst nicht enthalten ist
    // wird dann in der assetOverrides mit den richtigen Validatoren überladen
    realEstateTypeValue: { visible: { providers: [], fn: () => false } },
    assetCollateralFactor: { visible: { providers: [], fn: () => false } },
    degreeCompletition: { visible: { providers: [], fn: () => false } },
    constructionFinishDate: { visible: { providers: [], fn: () => false } },
    constructionStartDate: { visible: { providers: [], fn: () => false } },
    zipCode: { visible: { providers: [], fn: () => false } },
    city: { visible: { providers: [], fn: () => false } },
    street: { visible: { providers: [], fn: () => false } },
    streetNumber: { visible: { providers: [], fn: () => false } },
    staircase: { visible: { providers: [], fn: () => false } },
    usageTypeValue: { visible: { providers: [], fn: () => false } },
    isEnvironmentalRisk: { visible: { providers: [], fn: () => false } },
    thirdPartyPriority: { visible: { providers: [], fn: () => false } },
    trusteeAssetOrdersValues: { visible: { providers: [], fn: () => false } },
    rankNumber: { visible: { providers: [], fn: () => false } },
    rankAfterClnr: { visible: { providers: [], fn: () => false } },
    clnrReuse: { visible: { providers: [], fn: () => false } },
    amgId: { visible: { providers: [], fn: () => false } },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assetOptions: () => CreateFormGroupOptions<IAsset> = () => ({
    ...onlyOwnershipOptions(ASSET),
    priorChargesEur: null,
    priorChargesNotEur: null,
    preExistingClaims: null,
    assessmentData: null,
});

const onlyOwnershipOverrides: (provider: FormProviderToken<IOnlyOwnership>) => (parent?: IOnlyOwnership, isAsset?: boolean) => Partial<FinprocessTypedForm<IOnlyOwnership>> = (provider: FormProviderToken<IOnlyOwnership>) => (parent?: IOnlyOwnership, isAsset?: boolean) => ({
    cadastralData: cadastralDataForm(parent?.cadastralData, { removeIfEmpty: true }),
    lease: createLeaseForm(isAsset ?? false)(parent?.lease, {
        visible: createProviderInput({
            providers: CADASTRAL_DATA,
            fn: (cadastralData: ICadastralData) => cadastralData.propertyTypeValue === PropertyType.Superaddicts || cadastralData.propertyTypeValue === PropertyType.BuildingRights,
        }),
    }),
    owners: ownerFormArray(false, provider, parent),
});

export const assetOverrides: (provider: FormProviderToken<IAsset>) => (parent?: IAsset) => Partial<FinprocessTypedForm<IAsset>> = (provider: FormProviderToken<IAsset>) => (parent?: IAsset) => {

    const overrides = onlyOwnershipOverrides(provider)(parent, true);

    return {
        ...overrides,
        priorChargesEur: new FinprocessFormArray((parent?.priorChargesEur ?? []).map(priorChargesEur => priorChargesEurForm(priorChargesEur))),
        priorChargesNotEur: new FinprocessFormArray((parent?.priorChargesNotEur ?? []).map(priorChargesNotEur => priorChargesNotEurForm(priorChargesNotEur)), {
            visible: createProviderInput({
                providers: provider,
                fn: (asset: IAsset | IOnlyOwnership) => asset.cadastralData?.propertyTypeValue !== PropertyType.Superaddicts,
            }),
        }),
        preExistingClaims: new FinprocessFormArray((parent?.preExistingClaims ?? []).map(preExistingClaims => preExistingClaimsForm(preExistingClaims))),
        assessmentData: assessmentDataForm(parent?.assessmentData),
        owners: ownerFormArray(true, provider, parent),
        trusteeAssetOrdersValues: new FinprocessFormArray((parent?.trusteeAssetOrdersValues ?? []).map(value => new FinprocessFormControl(value, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => {
                    const validators = [Validators.required];

                    if (collateral.collateralTypeValue === CollateralType.BA220) {
                        validators.push(ColtValidators.forbiddenValue(OrderType.IncorporateMortgage, 'Einverleibung Hypothek'))
                    }

                    return Validators.compose(validators);
                },
            }),
        })), {
            visible: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => !isObjectEmpty(collateral.trustee),
            }),
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => {
                    if (isObjectEmpty(collateral.trustee)) {
                        return null;
                    }

                    const validators: ValidatorFn[] = [ColtValidators.minValues(1), ColtValidators.uniqueValidator];

                    if ([CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue)) {
                        validators.push(ColtValidators.containsValidator(OrderType.IncorporateMortgage, 'Einverleibung Hypothek'));
                    }
                    else if (collateral.collateralTypeValue === CollateralType.BA220) {
                        validators.push(ColtValidators.containsValidator(OrderType.IncorporateOwnership, 'Einverleibung Eigentumsrecht'));
                    }

                    return Validators.compose(validators);
                },
            }),
            default: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => ([CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue) ?
                    [OrderType.IncorporateMortgage] :
                    collateral.collateralTypeValue === CollateralType.BA220 ? [OrderType.IncorporateOwnership] : []),
            }),
        }, createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                const validators = [Validators.required];

                if (collateral.collateralTypeValue === CollateralType.BA220) {
                    validators.push(ColtValidators.forbiddenValue(OrderType.IncorporateMortgage, 'Einverleibung Hypothek'))
                }

                return Validators.compose(validators);
            },
        })) as never,
        realEstateTypeValue: new FinprocessFormControl(parent?.realEstateTypeValue, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        assetCollateralFactor: new FinprocessFormControl(parent?.assetCollateralFactor, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        degreeCompletition: new FinprocessFormControl(parent?.degreeCompletition, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        constructionFinishDate: new FinprocessFormControl(parent?.constructionFinishDate, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        constructionStartDate: new FinprocessFormControl(parent?.constructionStartDate, {
            validator: createProviderInput({
                providers: [provider, COLLATERAL],
                fn: (asset: IAsset, collateral: ICollateral) => (asset.degreeCompletition < 100 && collateral.isNewCollateral ? Validators.required : null),
            }),
            visible: createProviderInput({
                providers: provider,
                fn: (asset: IAsset) => asset.degreeCompletition < 100,
            }),
        }),
        zipCode: new FinprocessFormControl(parent?.zipCode, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ?
                    Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)]) : null),
            }),
        }),
        city: new FinprocessFormControl(parent?.city, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        street: new FinprocessFormControl(parent?.street, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        streetNumber: new FinprocessFormControl(parent?.streetNumber, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        staircase: new FinprocessFormControl(parent?.staircase),
        usageTypeValue: new FinprocessFormControl(parent?.usageTypeValue, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        isEnvironmentalRisk: new FinprocessFormControl(parent?.isEnvironmentalRisk, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
            }),
        }),
        thirdPartyPriority: new FinprocessFormControl(parent?.thirdPartyPriority, {
            validator: Validators.maxLength(100),
        }),
        rankNumber: new FinprocessFormControl(parent?.rankNumber, {
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => (collateral.isNewCollateral || collateral.collateralTypeValue === CollateralType.BA220 ? Validators.required : null),
            }),
        }),
        rankAfterClnr: new FinprocessFormControl(parent?.rankAfterClnr, {
            validator: createProviderInput({
                providers: provider,
                fn: (asset: IAsset) => ((typeof asset.rankNumber === 'number' && asset.rankNumber !== 1) ? Validators.compose([Validators.required, Validators.min(1)]) : null),
            }),
            visible: createProviderInput({
                providers: provider,
                fn: (asset: IAsset) => (typeof asset.rankNumber === 'number' && asset.rankNumber !== 1),
            }),
        }),
        clnrReuse: new FinprocessFormControl(parent?.clnrReuse, {
            visible: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => !collateral.isNewCollateral && collateral.collateralTypeValue === CollateralType.BA205,
            }),
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => ((!collateral.isNewCollateral && collateral.collateralTypeValue === CollateralType.BA205) ? Validators.required : null),
            }),
        }),
        amgId: new FinprocessFormControl(parent?.amgId, {
            visible: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => !collateral.isNewCollateral && MORTGAGES.includes(collateral.collateralTypeValue),
            }),
            validator: createProviderInput({
                providers: COLLATERAL,
                fn: (collateral: ICollateral) => ((!collateral.isNewCollateral && MORTGAGES.includes(collateral.collateralTypeValue)) ?
                    Validators.compose([Validators.required, Validators.maxLength(11), ColtValidators.numeric]) : null),
            }),
        }),
    };
};

export const assetForm = FinprocessFormBuilder.createFormGroup<IAsset>(assetOptions(), ASSET, assetOverrides(ASSET));
export const onlyOwnershipForm = FinprocessFormBuilder.createFormGroup<IOnlyOwnership>(onlyOwnershipOptions(ONLY_OWNERSHIP), ONLY_OWNERSHIP, onlyOwnershipOverrides(ONLY_OWNERSHIP));

const assessmentForm = FinprocessFormBuilder.createFormGroup<IAssessment>({
    value: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                const validators: ValidatorFn[] = [];

                if (INSURANCES.includes(collateral.collateralTypeValue)) {

                    // For BA105,BA110,BA115,BA120,BA125
                    // it must be possible to insert an assessment value (Rückkaufswert) greater than 0
                    // Only for new insurances (policy ID = neu), the value 0 schould be the only possible value 

                    // Entscheidung ob fix(0) oder min(0)
                    if (BA105BA125.includes(collateral.collateralTypeValue) && collateral.insurance?.policyNumber !== 'neu') {
                        validators.push(Validators.min(0));
                    }
                    else {
                        validators.push(ColtValidators.fixedValue(0, undefined, true));
                    }

                    // hinzufügen von required bei neuen Sicherheiten
                    if (collateral.isNewCollateral) {
                        validators.push(Validators.required);
                    }
                }

                return validators.length > 0 ? Validators.compose(validators) : null;
            },
        }),
    },
    currency: {
        validator: ColtValidators.euroValidator,
        default: {
            providers: [],
            fn: () => 'EUR',
        },
    },
    asOf: {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => BA105BA125.includes(collateral.collateralTypeValue),
        }),
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return BA105BA125.includes(collateral.collateralTypeValue) ? ColtValidators.maxDate(today) : null;
            },
        }),
    },
}, ASSESSMENT)

const insuranceForm = FinprocessFormBuilder.createFormGroup<IInsurance>({
    startDate: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (INSURANCES.includes(collateral.collateralTypeValue)) {
                    return collateral.isNewCollateral ? Validators.compose([ColtValidators.dayOfMonth(1), Validators.required]) : ColtValidators.dayOfMonth(1);
                }

                return null;
            },
        }),
    },
    endDate: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (INSURANCES.includes(collateral.collateralTypeValue)) {
                    return collateral.isNewCollateral ? Validators.compose([ColtValidators.dayOfMonth(1), Validators.required]) : ColtValidators.dayOfMonth(1);
                }

                return null;
            },
        }),
    },
    isSuicideClause: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (INSURANCES.includes(collateral.collateralTypeValue) && collateral.isNewCollateral ? Validators.required : null),
        }),
        default: {
            providers: [],
            fn: () => true,
        },
    },
    amountPayableDeath: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (INSURANCES.includes(collateral.collateralTypeValue)) {
                    const validators = [Validators.min(0), Validators.max(INSURANCES_MAX_VALUE)];

                    if (collateral.isNewCollateral) {
                        validators.push(Validators.required);
                    }

                    return Validators.compose(validators);
                }

                return null;
            },
        }),
    },
    policyNumber: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (INSURANCES.includes(collateral.collateralTypeValue) && collateral.isNewCollateral ? Validators.required : null),
        }),
    },
    insuranceCompanyNdg: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (INSURANCES.includes(collateral.collateralTypeValue)) {
                    return ColtValidators.ndgValidators(collateral.isNewCollateral);
                }

                return null;
            },
        }),
    },
    expectedMaturityPayment: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (BA105BA125.includes(collateral.collateralTypeValue)) {
                    const validators = [Validators.min(0), Validators.max(INSURANCES_MAX_VALUE)];

                    if (collateral.isNewCollateral) {
                        validators.push(Validators.required);
                    }

                    return Validators.compose(validators);
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => BA105BA125.includes(collateral.collateralTypeValue),
        }),
    },
    amountInsured: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (INSURANCES.includes(collateral.collateralTypeValue)) {
                    const validators = [Validators.min(0), Validators.max(INSURANCES_MAX_VALUE)];

                    if (collateral.isNewCollateral) {
                        validators.push(Validators.required);
                    }

                    return Validators.compose(validators);
                }

                return null;
            },
        }),
    },
    amountInsuredDate: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                if (CollateralType.BA130 === collateral.collateralTypeValue || CollateralType.BA135 === collateral.collateralTypeValue) {
                    const validators = [ColtValidators.dayOfMonth(1)];

                    if (collateral.isNewCollateral) {
                        validators.push(Validators.required);
                    }

                    return Validators.compose(validators);
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => CollateralType.BA130 === collateral.collateralTypeValue || CollateralType.BA135 === collateral.collateralTypeValue,
        }),
    },
}, INSURANCE);

const marriagePartnerForm = FinprocessFormBuilder.createFormGroup<IMarriagePartner>({
    isExisting: {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => INSURANCES.includes(collateral.collateralTypeValue),
        }),
        default: {
            providers: [],
            fn: () => false,
        },
    },
    birthdate: {
        validator: createProviderInput({
            providers: MARRIAGE_PARTNER,
            fn: (marriagePartner: IMarriagePartner) => (marriagePartner.isExisting ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: MARRIAGE_PARTNER,
            fn: (marriagePartner: IMarriagePartner) => marriagePartner.isExisting,
        }),
    },
    name: {
        validator: createProviderInput({
            providers: MARRIAGE_PARTNER,
            fn: (marriagePartner: IMarriagePartner) => (marriagePartner.isExisting ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: MARRIAGE_PARTNER,
            fn: (marriagePartner: IMarriagePartner) => marriagePartner.isExisting,
        }),
    },
}, MARRIAGE_PARTNER);

const accountForm = FinprocessFormBuilder.createFormGroup<IAccount>({
    number: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => ((OWN_COLLATERALS.includes(collateral.collateralTypeValue) && collateral.isNewCollateral) ? Validators.compose([Validators.maxLength(11), Validators.required]) : null),
        }),
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => OWN_COLLATERALS.includes(collateral.collateralTypeValue) && collateral.isNewCollateral,
        }),
    },
    amount: {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => ((CollateralType.BA415 === collateral.collateralTypeValue && collateral.isNewCollateral) ? Validators.compose([Validators.min(0), Validators.max(99999.99), Validators.required]) : null),
        }),
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => CollateralType.BA415 === collateral.collateralTypeValue && collateral.isNewCollateral,
        }),
    },
}, ACCOUNT);

const trusteeForm = FinprocessFormBuilder.createFormGroup<ITrustee>({
    ndg: null,
    kindValue: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (!!trustee.ndg ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    },
    commercialRegisterNumber: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.kindValue === KindOfOwner.LegalPerson ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg && trustee.kindValue === KindOfOwner.LegalPerson,
        }),
    },
    checkedBy: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (!!trustee.ndg ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    },
    typeValue: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (!!trustee.ndg ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    },
    isLawyerRegistered: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.typeValue === TrusteeType.Lawyer ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg && trustee.typeValue === TrusteeType.Lawyer,
        }),
    },
    // TODO: Mehrfachauswahl
    ordersValues: null,
    valuta: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.ordersValues && trustee.ordersValues.includes(OrderType.CashFlow) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg && trustee.ordersValues && trustee.ordersValues.includes(OrderType.CashFlow),
        }),
    },
    validTillDate: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => {
                if (!!trustee.ndg) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    return Validators.compose([Validators.required, ColtValidators.minDate(today)]);
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    },
    offerTillDate: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => {
                if (!!trustee.ndg) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    return Validators.compose([Validators.required, ColtValidators.minDate(today)]);
                }

                return null;
            },
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    },
    handoverPbu: {
        validator: createProviderInput({
            providers: [TRUSTEE, COLLATERAL],
            fn: (trustee: ITrustee, collateral: ICollateral) => (!!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [TRUSTEE, COLLATERAL],
            fn: (trustee: ITrustee, collateral: ICollateral) => !!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue),
        }),
    },
    isPreArrangementsAgreement: {
        validator: createProviderInput({
            providers: [TRUSTEE, COLLATERAL],
            fn: (trustee: ITrustee, collateral: ICollateral) => (!!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [TRUSTEE, COLLATERAL],
            fn: (trustee: ITrustee, collateral: ICollateral) => !!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue),
        }),
    },
    isEnforceableNotarialAct: {
        validator: createProviderInput({
            providers: [TRUSTEE, COLLATERAL],
            fn: (trustee: ITrustee, collateral: ICollateral) => (!!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: [TRUSTEE, COLLATERAL],
            fn: (trustee: ITrustee, collateral: ICollateral) => !!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue),
        }),
    },
    isTotalPropertyRegistration: {
        visible: createProviderInput({
            providers: [COLLATERAL, TRUSTEE],
            fn: (collateral: ICollateral, trustee: ITrustee) => !!trustee.ndg && [CollateralType.BA205, CollateralType.BA210].includes(collateral.collateralTypeValue) &&
                (
                    trustee.isTotalPropertyRegistration ||
                    (collateral.assets ?? []).every(({ typeOfShareValue, cadastralData }) =>
                        ![TypeOfShare.IdealProperty, TypeOfShare.Property].includes(typeOfShareValue) &&
                        !cadastralData.isNewEz && !cadastralData.isNewBrez && cadastralData.propertyTypeValue !== PropertyType.Superaddicts)
                ),
        }),
    },
    totalPropertyOwners: null,
    furtherAgreements: {
        validator: Validators.maxLength(500),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    },
    isTitleWithoutLien: {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => ((!!trustee.ndg && trustee.typeValue !== TrusteeType.ConstructionCompany) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg && trustee.typeValue !== TrusteeType.ConstructionCompany,
        }),
    },
    onlyOwnerships: null,
}, TRUSTEE, (parent?: ITrustee) => ({
    ndg: new FinprocessFormControl(parent?.ndg, {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => Validators.compose([ColtValidators.ndgValidators(false), ColtValidators.uniqeNdg([collateral.providerNdg, ...(collateral.coProvidersNdgs ?? [])])]),
        }),
    }, { updateOn: 'blur' }),
    ordersValues: new FinprocessFormArray((parent?.ordersValues ?? []).map(value => new FinprocessFormControl(value, {
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.collateralTypeValue === CollateralType.BA220 ? Validators.compose([Validators.required, ColtValidators.fixedValue(OrderType.IncorporateOwnership, 'Einverleibung Eigentumsrecht')]) : Validators.required),
        }),
    })), {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (!!trustee.ndg ? Validators.compose([ColtValidators.minValues(1), ColtValidators.uniqueValidator]) : ColtValidators.uniqueValidator),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg,
        }),
    }, createProviderInput({ // TODO, besser machen, damit die Controls diese Validierung nutzer können und sie nicht doppelt angelegt werden muss. Notwendigkeit siehe 8172
        providers: COLLATERAL,
        fn: (collateral: ICollateral) => (collateral.collateralTypeValue === CollateralType.BA220 ? Validators.compose([Validators.required, ColtValidators.fixedValue(OrderType.IncorporateOwnership, 'Einverleibung Eigentumsrecht')]) : Validators.required),
    })) as never,
    onlyOwnerships: new FinprocessFormArray((parent?.onlyOwnerships ?? []).map(onlyOwnerShip => onlyOwnershipForm(onlyOwnerShip)), {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.isTitleWithoutLien ? Validators.compose([ColtValidators.minValues(1), ColtValidators.maxValues(6)]) : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg && trustee.isTitleWithoutLien === true,
        }),
    }),
    totalPropertyOwners: new FinprocessFormArray((parent?.totalPropertyOwners ?? []).map(totalPropertyOwners => totalPropertyOwnerForm(totalPropertyOwners)), {
        validator: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => (trustee.isTotalPropertyRegistration === true ? Validators.compose([ColtValidators.minValues(1), ColtValidators.maxValues(2)]) : null),
        }),
        visible: createProviderInput({
            providers: TRUSTEE,
            fn: (trustee: ITrustee) => !!trustee.ndg && trustee.isTotalPropertyRegistration === true,
        }),
    }),
}));

export const collateralForm = FinprocessFormBuilder.createFormGroup<ICollateral>({
    assets: null,
    assessment: null,
    marriagePartner: null,
    insurance: null,
    account: null,
    trustee: null,
    providerNdg: {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => collateral.isNewCollateral,
        }),
        validator: createProviderInput({
            providers: [COLLATERAL, TRUSTEE],
            fn: (collateral: ICollateral, trustee: ITrustee) => Validators.compose([ColtValidators.ndgValidators(collateral.isNewCollateral), ColtValidators.uniqeNdg([trustee.ndg, ...(collateral.coProvidersNdgs ?? [])])]),
        }),
    },
    coProvidersNdgs: null,
    isNewCollateral: createOptions({
        validator: Validators.required,
        default: {
            providers: [],
            fn: () => true,
        },
    }),
    collateralId: createOptions({
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => !collateral.isNewCollateral,
        },
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (!collateral.isNewCollateral ? Validators.compose([ColtValidators.ndgValidators(), ColtValidators.forbiddenValue('0')]) : null),
        },
    }),
    isSettlementDeposit: createOptions({
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => collateral.isNewCollateral && collateral.collateralTypeValue === CollateralType.BA415,
        },
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral && collateral.collateralTypeValue === CollateralType.BA415 ? Validators.required : null),
        },
    }),
    collateralTypeValue: createOptions({
        validator: {
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => (!collateral.isNewCollateral ? Validators.compose([
                ColtValidators.forbiddenValue(CollateralType.BA210, 'BA210', 'Nicht erlaubt für bestehende Sicherheiten'),
                ColtValidators.forbiddenValue(CollateralType.BA330, 'BA330', 'Nicht erlaubt für bestehende Sicherheiten'),
            ]) : null),
        },
        default: { providers: [], fn: () => CollateralType.BA205 },
    }),
    expectedNominalValue: createOptions({
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        },
    }),
    expectedMaterialValue: createOptions({
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => {
                const validators: ValidatorFn[] = [];
                const fixedZeroTypes = [CollateralType.BA220, CollateralType.BA330];

                if (collateral.isNewCollateral) {
                    validators.push(Validators.required);
                }
                else {
                    fixedZeroTypes.push(CollateralType.BA130, CollateralType.BA135);
                }

                if (fixedZeroTypes.includes(collateral.collateralTypeValue)) {
                    validators.push(ColtValidators.fixedValue(0, undefined, true));
                }

                if (collateral.isReducedAmount) {
                    validators.push(Validators.max(collateral.reducedAmountForLandRegister ?? 0));
                }
                else if (collateral.isNewCollateral) {
                    validators.push(Validators.max(collateral.expectedNominalValue ?? 0));
                }
                else if (collateral.expectedNominalValue !== null && collateral.expectedNominalValue !== undefined) {
                    validators.push(Validators.max(collateral.expectedNominalValue));
                }

                return Validators.compose(validators);
            },
        },
    }),
    isReducedAmount: createOptions({
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => collateral.collateralTypeValue === CollateralType.BA205 && (collateral.isReducedAmount || (collateral.assets ?? []).every(asset => ![PropertyType.Superaddicts, PropertyType.BuildingRights].includes(asset.cadastralData.propertyTypeValue))),
        },
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.collateralTypeValue === CollateralType.BA205 && collateral.isNewCollateral && (collateral.isReducedAmount || (collateral.assets ?? []).every(asset => ![PropertyType.Superaddicts, PropertyType.BuildingRights].includes(asset.cadastralData.propertyTypeValue))) ? Validators.required : null),
        },
    }),
    reducedAmountForLandRegister: createOptions({
        visible: {
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => collateral.isReducedAmount,
        },
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isReducedAmount ? Validators.required : null),
        },
    }),
    collateralFactor: createOptions({
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.isNewCollateral ? Validators.required : null),
        },
    }),
    assignmentTypeValue: createOptions({
        default: {
            providers: [],
            fn: () => AssignmentType.Pledge,
        },
        visible: {
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => collateral.collateralTypeValue !== CollateralType.BA330,
        },
        validator: {
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => {
                if (collateral.collateralTypeValue === CollateralType.BA330) {
                    return null;
                }

                if (INSURANCES.includes(collateral.collateralTypeValue)) {
                    return Validators.required;
                }

                const validators = [ColtValidators.fixedValue(AssignmentType.Pledge, 'Verpfändung')];

                if (collateral.isNewCollateral) {
                    validators.push(Validators.required);
                }

                return Validators.compose(validators)
            },
        },
    }),
    isTrusteeAbolition: createOptions({
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => MORTGAGES.includes(collateral.collateralTypeValue),
        },
    }),
    mortgageType: createOptions({
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => collateral.collateralTypeValue === CollateralType.BA220,
        },
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.collateralTypeValue === CollateralType.BA220 && collateral.isNewCollateral ? Validators.required : null),
        },
    }),
    isContractUsableForCovRegister: createOptions({
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => MORTGAGES.includes(collateral.collateralTypeValue),
        },
    }),
    linkedProducts: createOptions({
        validator: {
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => {
                const validators: ValidatorFn[] = [];

                if (!Array.isArray(collateral.linkedGuarantees) || collateral.linkedGuarantees.length === 0) {
                    validators.push(ColtValidators.minValues(1));
                }

                if (collateral.isNewCollateral && collateral.collateralTypeValue === CollateralType.BA210) {
                    validators.push(ColtValidators.maxOneElement(collateral.linkedGuarantees ?? [], 'Besicherte Produkte', 'Garantien'));
                }

                return Validators.compose(validators);
            },
        },
        default: {
            providers: [],
            fn: () => [],
        },
    }),
    linkedGuarantees: createOptions({
        validator: {
            providers: [COLLATERAL],
            fn: (collateral: ICollateral) => (collateral.isNewCollateral && collateral.collateralTypeValue === CollateralType.BA210 ?
                ColtValidators.maxOneElement(collateral.linkedProducts ?? [], 'Besicherte Produkte', 'Garantien') : null),
        },
        default: {
            providers: [],
            fn: () => [],
        },
    }),
    exchangeBillNumber: createOptions({
        validator: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (collateral.collateralTypeValue === CollateralType.BA330 ? Validators.compose([Validators.required, Validators.min(1), Validators.max(2)]) : null),
        },
        visible: {
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => collateral.collateralTypeValue === CollateralType.BA330,
        },

    }),
}, COLLATERAL, (parent?: ICollateral) => ({
    assets: new FinprocessFormArray((parent?.assets ?? []).map(asset => assetForm(asset)), {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => MORTGAGES.includes(collateral.collateralTypeValue),
        }),
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (MORTGAGES.includes(collateral.collateralTypeValue) ? ColtValidators.minValues(1) : null),
        }),
    }),
    assessment: assessmentForm(parent?.assessment, {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => INSURANCES.includes(collateral.collateralTypeValue),
        }),
    }),
    trustee: trusteeForm(parent?.trustee, {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => MORTGAGES.includes(collateral.collateralTypeValue),
        }),
        removeIfEmpty: true,
    }),
    marriagePartner: marriagePartnerForm(parent?.marriagePartner, {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => collateral.isNewCollateral && INSURANCES.includes(collateral.collateralTypeValue),
        }),
    }),
    account: accountForm(parent?.account, {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => OWN_COLLATERALS.includes(collateral.collateralTypeValue) && collateral.isNewCollateral,
        }),
    }),
    insurance: insuranceForm(parent?.insurance, {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => INSURANCES.includes(collateral.collateralTypeValue),
        }),
    }),
    coProvidersNdgs: new FinprocessFormArray((parent?.coProvidersNdgs ?? []).map(ndg => new FinprocessFormControl(
        ndg,
        {
            validator: createProviderInput({
                providers: [COLLATERAL],
                fn: (collateral: ICollateral) => Validators.compose([ColtValidators.uniqeNdg([collateral.providerNdg, collateral.trustee?.ndg]), ColtValidators.ndgValidators(collateral.isNewCollateral)]),
            }),
        })), {
        visible: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => MORTGAGES.includes(collateral.collateralTypeValue) && collateral.isNewCollateral,
        }),
        validator: createProviderInput({
            providers: COLLATERAL,
            fn: (collateral: ICollateral) => (MORTGAGES.includes(collateral.collateralTypeValue) && collateral.isNewCollateral ? ColtValidators.uniqueValidator : null),
        }),
    }, createProviderInput({
        providers: [COLLATERAL],
        fn: (collateral: ICollateral) => Validators.compose([ColtValidators.uniqeNdg([collateral.providerNdg, collateral.trustee?.ndg]), ColtValidators.ndgValidators(collateral.isNewCollateral)]),
    }),
    ),
}));

