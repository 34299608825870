<div class="households-chip-navigation">
    <ucba-chip-list (selectionChange)="onHouseholdChange($event)"> 
        <ucba-chip [color]="'primary'" *ngFor="let household of householdOverviewDocuments; let i = index">{{'financing.features.financing-processing.householdBills.houseHoldTitle' | translate:{ number: household.householdPosition + 1 } }}</ucba-chip>
    </ucba-chip-list>
    <div class="button-wrapper ml-3">
        <button
            class="send-btn"
            mat-raised-button
            color="primary"
            (click)="documentsSend()"
            [disabled]="existNotGeneratedDocument || !existNotSendedDocument || isStatusNotOpenOrWaiting || isBaf || ((fieldReadonly$ | async) ?? true)">
            <span
                *ngIf="!existingSendedDocument"
                [matTooltip]="'financing.features.financing-processing.householdBillsView.notPossible' | translate"
                [matTooltipDisabled]="!isBaf">
                {{ 'financing.features.financing-processing.householdBillsView.sendAllDocumentsTitle' | translate }}
            </span>
            <span *ngIf="existingSendedDocument">
                {{ 'financing.features.financing-processing.householdBillsView.sendAgainAllDocumentsTitle' | translate }}
            </span>
        </button>
    </div>
</div>

@if (!selectedHousehold?.householdOverviewDocumentTypes || selectedHousehold?.householdOverviewDocumentTypes?.length === 0) {
    <div class="mt-4" fxLayoutAlign="center">{{'financing.features.financing-processing.householdBillsView.noDocumentTypes' | translate }}</div>
} @else {
    <ucba-list>
        <ucba-list-element *ngFor="let document of selectedHousehold?.householdOverviewDocumentTypes; let i = index" (click)="toggleDocuments($event, i)">
            <div ucba-list-content-left>
                <b>{{ document.documentType | finprocessEnumTranslation : 'DocumentType' }} {{ document?.displayName }}</b>
                <div class="dates-wrapper">
                    <finprocess-label-value>
                        <span label>{{ 'financing.features.financing-processing.householdBillsView.generated' | translate }}</span>
                        @if (document.generated) {
                            <span value>{{ (document.generated | date : 'dd.MM.yyyy') }}</span>
                        } @else {
                            <div value fxLayout="row" fxLayoutAlign="start start">
                                <mat-icon [matTooltip]="'financing.features.financing-processing.householdBillsView.tooltip-not-generated' | translate">schedule</mat-icon>
                            </div>
                        }
                    </finprocess-label-value>
                    <finprocess-label-value>
                        <span label>{{ 'financing.features.financing-processing.householdBillsView.sended' | translate }}</span>
                        @if (document.sended) {
                            <span value>{{ (document.sended | date : 'dd.MM.yyyy') ?? '-' }}</span>
                        } @else {
                            <div value fxLayout="row" fxLayoutAlign="start start">
                                <mat-icon [matTooltip]="'financing.features.financing-processing.householdBillsView.tooltip-not-sended' | translate">schedule_send</mat-icon>
                            </div>
                        }
                    </finprocess-label-value>
                    <finprocess-label-value>
                        <span label>{{ 'financing.features.financing-processing.householdBillsView.signed' | translate }}</span>
                        @if (document.signed) {
                            <span value>{{ (document.signed | date : 'dd.MM.yyyy') ?? '-' }}</span>
                        } @else {
                            <div value fxLayout="row" fxLayoutAlign="start start">
                                <mat-icon [matTooltip]="'financing.features.financing-processing.householdBillsView.tooltip-not-signed' | translate">draw</mat-icon>
                            </div>
                        }
                    </finprocess-label-value>
                </div>
            </div>
            <div class="action-buttons-wrapper" ucba-list-content-right #buttonsWrapper>
                <button
                    mat-mini-fab
                    class="mr-2"
                    color="primary"
                    (click)="file.click()"
                    *ngIf="!!document?.debitorId"
                    [disabled]="document.householdOverviewDocuments.length > 1 || ((fieldReadonly$ | async) ?? true) || isStatusNotOpenOrWaiting"
                    [matTooltip]="'financing.features.financing-processing.householdBillsView.uploadDocument' | translate"
                >
                    <mat-icon>upload_file</mat-icon>
                </button>
                <input
                    type="file"
                    #file
                    [multiple]="false"
                    [disabled]="document.householdOverviewDocuments.length > 1 || isStatusNotOpenOrWaiting"
                    (change)="uploadFile($event.target, document.documentType, document.debitorId, document.householdOverviewDocuments[0]?.fileId)"
                    accept=".pdf"
                    style="display: none" 
                />
                <button
                    *ngIf="hasRoleExpert === true && isFinAdvisory === true && !!document?.debitorId"
                    mat-mini-fab
                    color="primary"
                    class="mr-2"
                    (click)="generateSelfDisclosure(document?.debitorId)"
                    [disabled]="document.householdOverviewDocuments.length > 1 || ((fieldReadonly$ | async) ?? true) || isStatusNotOpenOrWaiting"
                    [matTooltip]="'financing.features.financing-processing.householdBillsView.generateSelfDisclosure' | translate"
                >
                    <mat-icon>person_pin</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    *ngIf="!!document?.generated && !!document?.sended"
                    color="primary"
                    class="sign-button mr-2"
                    [disabled]="!!document?.signed || isStatusNotOpenOrWaiting || ((fieldReadonly$ | async) ?? true)"
                    (click)="signDocument($event, document.documentType, document.householdOverviewDocuments)"
                    [matTooltip]="'financing.features.financing-processing.householdBillsView.markDocumentAsSigned' | translate"
                >
                    <mat-icon>border_color</mat-icon>
                </button>
                <button
                    mat-mini-fab
                    color="primary"
                    (click)="toggleDocuments($event, i, true)"
                    [matTooltip]="
                        showDocuments[i] ? 
                        ('financing.features.financing-processing.householdBillsView.hideDocuments' | translate) : 
                        ('financing.features.financing-processing.householdBillsView.showDocuments' | translate)
                    "
                >
                    <mat-icon>
                        @if(showDocuments[i]) {
                            visibility_off
                        } @else {
                            visibility
                        }
                    </mat-icon>
                </button>
            </div>
            <div class="documents-wrapper" #documents *ngIf="showDocuments[i]" @expand>
                <div class="documents-height-wrapper">
                    <div class="documents-border-wrapper" [class.dark-background]="i % 2 === 0" [class.light-background]="i % 2 !== 0">
                        @if(!!document.generated) {
                            <div class="document-wrapper" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let file of document.householdOverviewDocuments" [class.dark-background]="i % 2 === 0" [class.light-background]="i % 2 !== 0">
                                <div fxLayout="row" fxLayoutAlign="center start">
                                    <mat-icon class="mr-2 document-icon">insert_drive_file</mat-icon>
                                    <span>{{ file.fileName ?? (document.documentType | finprocessEnumTranslation : 'DocumentType') }}</span>
                                </div>
                                <button
                                    *ngIf="!!document.generated"
                                    fxLayout="column"
                                    fxLayoutAlign="center center"
                                    mat-mini-fab
                                    class="open-icon small-mat-mini-fab"
                                    color="white"
                                    (click)="openFile(file.fileId, file.mimeType, file.fileName)"
                                    [matTooltip]="'financing.features.financing-processing.householdBillsView.openDocument' | translate"
                                >
                                    <mat-icon color="primary">arrow_forward</mat-icon>
                                </button>
                            </div>
                        } @else {
                            <div class="document-wrapper" [class.dark-background]="i % 2 === 0" [class.light-background]="i % 2 !== 0">{{ 'financing.features.financing-processing.householdBillsView.noDocumentsGenerated' | translate }}</div>
                        }
                    </div>
                </div>
            </div>
        </ucba-list-element>
    </ucba-list>
}
