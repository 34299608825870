import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ISODate } from 'app/modules/messages/data/types';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import moment from 'moment';

import { PropertyType, TypeOfShare } from '../enums';
import { ICadastralData, ICollateral, ITrustee } from '../interfaces';

import { sumFractions } from './helper';

/* eslint-disable @typescript-eslint/no-namespace */
export namespace ColtValidators {

    /**
     * Validator für die Mindestanzahl an Werten in einem Array (macht eigentlich das gleiche wie minLength nur mit netterer Übersetzung)
     * 
     * @param {number} min Mindestanzahl der Werte
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function minValues(min: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control instanceof FormArray) {
                return control.length < min ? { minValues: { min, actual: control.length } } : null;
            } else if (control instanceof FormControl) {
                const value = control.value;

                if (Array.isArray(value)) {
                    return value.length < min ? { minValues: { min, actual: value.length } } : null;
                }
            }

            return null;
        }

    }

    /**
     * Validator für die Maximalzahl an Werten in einem Array (macht eigentlich das gleiche wie minLength nur mit netterer Übersetzung)
     * 
     * @param {number} max Maximalzahl der Werte
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function maxValues(max: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control instanceof FormArray) {
                return control.length > max ? { maxValues: { max, actual: control.length } } : null;
            } else if (control instanceof FormControl) {
                const value = control.value;

                if (Array.isArray(value)) {
                    return value.length > max ? { maxValues: { max, actual: value.length } } : null;
                }
            }

            return null;
        }

    }

    /**
     * Validator für den maximalen Wert eines Datumsfeldes
     * 
     * @param {number} max Maximaler gültiger Wert, oder ein Array aus maximalen Werten (kleinstes wird genommen)
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function maxDate(max: Date | ISODate | Array<Date | ISODate>): (control: AbstractControl) => ValidationErrors | null {
        // eslint-disable-next-line complexity
        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            let maxAsDate: Date | undefined;

            // TODO die mat date komponenten scheint irgendein komisches Date Objekt zurück zu geben. Hier ein Workaround das auch damit umgehen kann
            // Wenn Daten aus zwischenspeicher geladen werden, ist das Date ein ISO String

            if (Array.isArray(max)) {
                const minimumOfMax = max.reduce<Date | undefined>((acc, curr) => {
                    let curAsDate: Date | undefined;

                    if (typeof curr === 'string') {
                        curAsDate = new Date(curr);
                    }
                    else {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        curAsDate = curr instanceof Date ? curr : ('_d' in curr && ((curr as any)['_d'] instanceof Date)) ? ((curr as any)['_d'] as Date) : undefined;
                    }

                    return !acc ? curAsDate : !!curAsDate && acc.getTime() > curAsDate.getTime() ? curAsDate : acc;

                }, undefined);

                maxAsDate = minimumOfMax ?? maxAsDate;
            }
            else {
                if (typeof max === 'string') {
                    maxAsDate = new Date(max);
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    maxAsDate = max instanceof Date ? max : ('_d' in max && ((max as any)['_d'] instanceof Date)) ? ((max as any)['_d'] as Date) : undefined;
                }
            }

            const value = control.value;
            let valueAsDate: Date | undefined;

            if (typeof value === 'string') {
                valueAsDate = new Date(value);
            }
            else {
                valueAsDate = value instanceof Date ? value : ('_d' in value && value['_d'] instanceof Date) ? value['_d'] as Date : undefined;
            }

            if (!!maxAsDate && !!valueAsDate) {
                if (maxAsDate.getTime() < valueAsDate.getTime()) {
                    return { max: { max: maxAsDate.toLocaleDateString('de-AT', { day: '2-digit', month: '2-digit', year: 'numeric' }) } };
                }
            }

            return null;
        }
    }

    /**
     * Validator für den minimalen Wert eines Datumsfeldes
     * 
     * @param {number} min Minimaler gültiger Wert
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function minDate(min: Date | ISODate | Array<Date | ISODate>): (control: AbstractControl) => ValidationErrors | null {
        // eslint-disable-next-line complexity
        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            let minAsDate: Date | undefined;

            // TODO die mat date komponenten scheint irgendein komisches Date Objekt zurück zu geben. Hier ein Workaround das auch damit umgehen kann
            // Wenn Daten aus zwischenspeicher geladen werden, ist das Date ein ISO String

            if (Array.isArray(min)) {
                const maximumOfMin = min.reduce<Date | undefined>((acc, curr) => {
                    let curAsDate: Date | undefined;

                    if (typeof curr === 'string') {
                        curAsDate = new Date(curr);
                    }
                    else {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        curAsDate = curr instanceof Date ? curr : ('_d' in curr && ((curr as any)['_d'] instanceof Date)) ? ((curr as any)['_d'] as Date) : undefined;
                    }

                    return !acc ? curAsDate : !!curAsDate && acc.getTime() < curAsDate.getTime() ? curAsDate : acc;

                }, undefined);

                minAsDate = maximumOfMin ?? minAsDate;
            }
            else {
                if (typeof min === 'string') {
                    minAsDate = new Date(min);
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    minAsDate = min instanceof Date ? min : ('_d' in min && ((min as any)['_d'] instanceof Date)) ? ((min as any)['_d'] as Date) : undefined;
                }
            }

            const value = control.value;
            let valueAsDate: Date | undefined;

            if (typeof value === 'string') {
                valueAsDate = new Date(value);
            }
            else {
                valueAsDate = value instanceof Date ? value : ('_d' in value && value['_d'] instanceof Date) ? value['_d'] as Date : undefined;
            }

            if (!!minAsDate && !!valueAsDate) {
                if (minAsDate.getTime() > valueAsDate.getTime()) {
                    return { min: { min: minAsDate.toLocaleDateString('de-AT', { day: '2-digit', month: '2-digit', year: 'numeric' }) } };
                }
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob ein Wert mehr als einmal in einem Array enthalten ist
     * 
     * @param {AbstractControl} control FormArray
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function uniqueValidator(control: AbstractControl): ValidationErrors | null {
        if (!(control instanceof FormArray) || control.value === null || control.value === undefined) {
            return null;
        }

        const elements = control.controls.map(ctr => ctr.value);

        for (let i = 0; i < elements.length; i++) {
            // indexOf gibt immer den Index des ersten Treffers zurück
            // Wenn dieser nicht i entspricht, wurde ein weiteres Element mit dem Wert gefunden
            if (elements.indexOf(elements[i]) !== i) {
                return { unique: true };
            }
        }

        return null;

    }


    /**
     * Validator um zu prüfen ob ein Wert in einem Array enthalten ist
     * 
     * @param {any} value zu prüfender Wert
     * @param {string} translation Optionale Übersetzung für z.b. Enums
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function containsValidator<T>(value: T, translation?: string): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {

            if (!(control instanceof FormArray) || control.value === null || control.value === undefined) {
                return null;
            }

            const elements = control.controls.map<T>(ctr => ctr.value);

            if (!elements.includes(value)) {
                return { contains: { value: translation ?? value } }
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob Mindereintrag noch erlaubt ist
     * 
     * @param {ICollateral} collateral übergeordnete Sicherheit
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function reducedAmountGuard(collateral: ICollateral): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (collateral.isReducedAmount && [PropertyType.Superaddicts, PropertyType.BuildingRights].includes(control.value)) {
                return { reducedAmountGuard: true };
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob Eintragung auf Gesamtliegenschaft noch erlaubt ist
     * 
     * @param {ITrustee} trustee übergeordneter Treuhänder
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function totalPropertyRegistrationTypeOfShareGuard(trustee: ITrustee): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (trustee.isTotalPropertyRegistration && [TypeOfShare.IdealProperty, TypeOfShare.Property].includes(control.value)) {
                return { totalPropertyRegistrationGuard: true };
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob Eintragung auf Gesamtliegenschaft noch erlaubt ist
     * 
     * @param {ITrustee} trustee übergeordneter Treuhänder
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function totalPropertyRegistrationPropertyType(trustee: ITrustee): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (trustee.isTotalPropertyRegistration && control.value === PropertyType.Superaddicts) {
                return { totalPropertyRegistrationGuard: true };
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob Eintragung auf Gesamtliegenschaft noch erlaubt ist
     * 
     * @param {ITrustee} trustee übergeordneter Treuhänder
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function totalPropertyRegistrationBrezEz(trustee: ITrustee): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (trustee.isTotalPropertyRegistration && control.value === true) {
                return { totalPropertyRegistrationGuard: true };
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen Anteile unbekannt noch erlaubt ist
     * 
     * @param {ICadastralData} cadastralData übergeordnete Cadastral Data
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function assetShareNumberUnknownGuard(cadastralData: ICadastralData): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (cadastralData.propertyTypeValue === PropertyType.Superaddicts) {
                return { assetShareNumberUnknownGuard: true };
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob Neu zu gründende EZ und Neu zu gründende BREZ gleichzeitig aktiv ist
     * 
     * @param {boolean} other anderes Boolean Feld
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function isNewEzGuard(other: boolean): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (control.value === true && other === true) {
                return { isNewEzGuard: true };
            }

            return null;
        }
    }

    /**
     * Validator um zu prüfen ob max ein Wert in zwei Arrays gesetzt ist
     * 
     * @param {boolean} other das andere Array
     * @param {string} translation1 Name des ersten Feldes
     * @param {string} translation2 Name des zweiten Feldes
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function maxOneElement(other: unknown[], translation1: string, translation2: string): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (!Array.isArray(control.value)) {
                return null;
            }

            if (control.value.length + other.length > 1) {
                return { maxOneElement: { field1: translation1, field2: translation2 } };
            }

            return null;
        }
    }

    /**
     * Validator um Anteile zu prüfen. Darf in Summe nicht größer als 1/1 sein
     * 
     * @param {ICollateral} values alle Zähler und Nenner
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function proportionValidator(values?: Array<{ nominator: number, denominator: number }>): (control: AbstractControl) => ValidationErrors | null {

        return (control: AbstractControl) => {
            if (!Array.isArray(values) || control.value === null || control.value === undefined) {
                return null;
            }

            // Überprfüung ob alles einen Wert hat
            if (values.some(v => v.nominator === null || v.nominator === undefined || v.denominator === null || v.denominator === undefined)) {
                return null;
            }

            const sum = sumFractions(values);

            if ((sum.nominator / sum.denominator) > 1) {
                return { proportion: true }
            }

            return null;
        }
    }

    /**
     * Validator für Währungsfelder die immer Euro erwarten
     * 
     * @param {AbstractControl} control AbstractControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function euroValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;

        if (typeof value === 'string' && value !== '' && value !== 'EUR') {
            return { euro: { expected: 'EUR', actual: value } };
        }

        return null;
    }

    /**
     * Validator für Felder die nur Y oder N zulassen
     * 
     * @param {AbstractControl} control FormControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function yesNoValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        if (typeof control.value !== 'string' || (control.value !== 'Y' && control.value !== 'N')) {
            return { yesNo: { actual: control.value } };
        }

        return null;
    }

    /**
     * Validiert den Tag des Monats von Datumswerten
     * 
     * @param {number} day Tag (1-31)
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function dayOfMonth(day: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            const asMoment = moment(control.value);

            if (!asMoment.isValid()) {
                return { invalidDate: { actual: control.value } };
            }

            if (asMoment.date() !== day) {
                return { dayOfMonth: { expectedDay: day, actual: asMoment.date() } }
            }

            return null;
        }
    }

    /**
     * Validiert einen festgesetzten Wert
     * 
     * @param {any} expectedValue Erwarteter Wert
     * @param {string} translation Optionale Übersetzung für z.b. Enums
     * @param {boolean} allowEmpty Optionales Flag welches auch keinen Wert als gültig ansieht
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function fixedValue<T>(expectedValue: T, translation?: string, allowEmpty?: boolean): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (allowEmpty && (control.value === null || control.value === undefined)) {
                return null;
            }

            if (control.value !== expectedValue) {
                return { fixedValue: { fixedValue: translation ?? expectedValue, actual: control.value } };
            }

            return null;
        }
    }

    /**
     * Validiert einen verbotenen Wert
     * 
     * @param {any} forbidden verbotener Wert
     * @param {string} translation Optionale Übersetzung für z.b. Enums
     * @param {string} reason Optionaler Grund warum der Wert verboten ist
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function forbiddenValue<T>(forbidden: T, translation?: string, reason?: string): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control.value === forbidden) {
                return { forbiddenValue: { forbidden: translation ?? control.value, reason: reason ?? '' } };
            }

            return null;
        }
    }

    /**
     * Validator für Felder die nur Ziffern zulassen
     * 
     * @param {AbstractControl} control FormControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function numeric(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        if (typeof control.value !== 'string' || !/^[0-9]*$/.test(control.value)) {
            return { numeric: true };
        }

        return null;
    }

    /**
     * Validator für die FireId die einem bestimmten Format entsprechen muss.
     * CU[NDG]-[8Ziffern]-[6Ziffern]BE.
     * Bsp: CU00014442-20230915-101638BE.
     * Siehe #8358
     * 
     * @param {AbstractControl} control FormControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function fireId(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        if (typeof control.value !== 'string' || !/^CU\d{8}-\d{8}-\d{6}BE$/.test(control.value)) {
            return { fireId: true };
        }

        return null;
    }

    /**
     * Validator welcher jeden Whitespace Character verbietet
     * 
     * @param {AbstractControl} control FormControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function noSpace(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        if (typeof control.value !== 'string' || /\s/.test(control.value)) {
            return { noSpace: true };
        }

        return null;
    }

    /**
     * Validator welcher auf eine gültige IBAN aus Europa prüft
     * 
     * @param {AbstractControl} control FormControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function invalidEuIBAN(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        const testIBAN = electronicFormatIBAN(control.value);
        if (!testIBAN || !isValidIBAN(testIBAN) || !/^(AT|BE|BG|CY|CZ|DE|DK|EE|ES|FI|FR|GB|GR|HR|HU|IE|IS|IT|LI|LT|LU|LV|MC|MT|NL|NO|PL|PT|RO|SE|SI|SK).*/.test(testIBAN)) {
            return { invalidEuIBAN: true };
        }

        return null;
    }

    /**
     * Validator welcher auf eine gültige IBAN aus österreich prüft
     * 
     * @param {AbstractControl} control FormControl
     * @returns {ValidationErrors | null} Validierungsfehler
     */
    export function invalidAtIBAN(control: AbstractControl): ValidationErrors | null {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        const testIBAN = electronicFormatIBAN(control.value);
        if (!testIBAN || !isValidIBAN(testIBAN) || !testIBAN.startsWith('AT')) {
            return { invalidAtIBAN: true };
        }

        return null;
    }

    /**
     * Validiert einen string ob der übergebene number wert überschritten ist
     * 
     * @param {number} max maxmialer erlaubter Wert
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function maxStringNumber(max: number): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            const v = control.value;

            if (typeof v === 'string') {
                const parsed = parseInt(v, 10);

                if (!isNaN(parsed) && parsed > max) {
                    return { maxStringNumber: { max } };
                }
            }

            return null;
        }
    }

    /**
     * Gibt einen compose Validator zurück für NDGs
     * 
     * @param {boolean} require Ist der required Validator dabei
     * @returns {ValidatorFn} compose Validator
     */
    export function ndgValidators(require = true): ValidatorFn | null {
        const validators = [ColtValidators.numeric, Validators.maxLength(16)];

        if (require) {
            validators.push(Validators.required);
        }

        return Validators.compose(validators);
    }

    /**
     * Validiert doppelte NDG Wert
     * 
     * @param {string} ndgs ndgs
     * @param {number} maxAllowed max erlaubte, abhänig von der Feld art um sich selbst nicht mitzuzählen
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function uniqeNdg(ndgs: string[], maxAllowed = 0): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (control.value === null || control.value === undefined) {
                return null;
            }

            if (ndgs.filter(it => it === control.value).length > maxAllowed) {
                return { uniqeNdg: true };
            }

            return null;
        }
    }

    /**
     * Validiert ob mind ein produkt oder Garantiekredit vorhanden ist
     * 
     * @param {any[]} other das andere zu prüfende Array
     * @returns {(control: AbstractControl) => ValidationErrors | null} Validierungsfunktion
     */
    export function productGuaranteeCreditLinesValidation(other?: unknown[]): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl) => {
            if (Array.isArray(control.value) && control.value.length > 0) {
                return null;
            }

            if (Array.isArray(other) && other.length > 0) {
                return null;
            }

            return { productGuaranteeCreditLinesValidation: true }
        }
    }
}
