<div class="ucba-heading-container">
    <div
        class="ucba-heading ucba-heading-large"
        [ngClass]="{ 'ucba-heading-large': type() === 'large', 'ucba-heading-medium': type() === 'medium', 'ucba-heading-small': type() === 'small', 'ucba-expandable': expandable() }">
        <div class="ucba-heading-title">
            <ng-content select="ucba-heading-title, [ucbaHeadingTitle], [ucba-heading-title]"></ng-content>
        </div>
        @if (expandable()) {
            <div class="ucba-heading-expand-arrow" [class.is-expanded]="isExpanded()" (click)="toggleExpanded()"></div>
        }
        <div class="ucba-heading-content">
            <ng-content></ng-content>
        </div>
        <div class="ucba-heading-actions">
            <ng-content select="ucba-heading-actions, [ucbaHeadingActions], [ucba-heading-actions]"></ng-content>
        </div>
    </div>
    <div class="ucba-heading-body-visible">
        <ng-content select="ucba-heading-body-visible, [ucbaHeadingBodyVisible], [ucba-heading-body-visible]"></ng-content>
    </div>
    @if (isExpanded()) {
        <div class="ucba-heading-body" @expand>
            <ng-content select="ucba-heading-body, [ucbaHeadingBody], [ucba-heading-body]"></ng-content>
        </div>
    }
</div>
