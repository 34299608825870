import { FinprocessFormArray, FinprocessFormBuilder, createProviderInput } from '@ntag-ef/finprocess-forms';

import { MandantType } from '../../../../../../../../../libs/enums/src/core/enums';
import { COLT_DATA_CONTAINER, COLT_META_DATA } from '../forms/colt-provider';
import { IColtDataContainer, IColtMetaData } from '../interfaces';

import { additionalInformationForm } from './additional-information.validation';
import { approvalDataForm } from './approval-data.validation';
import { collateralForm } from './collateral.validation';
import { customerForm } from './customer.validation';
import { detailPrintingForm } from './detail-printing.validation';
import { loanDataForm } from './loan-data.validation';
import { repaymentDetailsForm } from './repayment-details.validation';
import { salesPartnerForm } from './sales-partner.validation';

export const coltForm = FinprocessFormBuilder.createRootFormGroup({
    salespartner: null,
    approvalData: null,
    detailPrinting: null,
    additionalInformation: null,
    repaymentDetails: null,
    loanData: null,
    customers: null,
    collaterals: null,

}, COLT_DATA_CONTAINER, (parent?: IColtDataContainer) => ({
    salespartner: salesPartnerForm(parent?.salespartner, {
        visible: createProviderInput({
            providers: COLT_META_DATA,
            fn: (metaData: IColtMetaData) => metaData.mandantType === MandantType.BAF,
        }),
    }),
    approvalData: approvalDataForm(parent?.approvalData),
    detailPrinting: detailPrintingForm(parent?.detailPrinting),
    additionalInformation: additionalInformationForm(parent?.additionalInformation),
    repaymentDetails: repaymentDetailsForm(parent?.repaymentDetails),
    loanData: loanDataForm(parent?.loanData),
    customers: new FinprocessFormArray((parent?.customers ?? []).map(cst => customerForm(cst))),
    collaterals: new FinprocessFormArray((parent?.collaterals ?? []).map(collateral => collateralForm(collateral))),
}));
