import { Injectable, OnDestroy } from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { FinprocessFormControl, FinprocessFormGroup } from '@ntag-ef/finprocess-forms';
import { Logout } from 'app/modules/auth/data';
import { FinancingStatus, FinancingSubStatus } from 'app/modules/shared';
import { BehaviorSubject, ReplaySubject, Subject, Subscription, combineLatest, filter, map, startWith, take, takeUntil } from 'rxjs';

import { FinancingLeaved, FinancingService, FinancingState, IFinancingStateParentDefinition } from '../../../../data';
import { COLT_META_DATA } from '../../forms/colt-provider';
import { IAdditionalInformation, IApprovalData, IAsset, ICollateral, IColtDataContainer, IColtMetaData, ICustomer, IDetailPrinting, IGuaranteeCreditLine, IObjectInformation, IOnlyOwnership, IProduct, IRepaymentDetails, ISalesPartner } from '../../interfaces';
import { EMPLOYEES_BRANCH_ID } from '../../util/colt-constants';
import { coltForm } from '../../validation/colt-data.validation';

interface ILabelValue<T> {
    label: string;
    value: T;
}

/**
 * Validierungsservice für Colt
 */
@Injectable()
export class ColtValidationService implements OnDestroy {

    public coltForm?: FinprocessFormGroup<IColtDataContainer>;

    public coltForm$ = new ReplaySubject<FinprocessFormGroup<IColtDataContainer>>(1);

    /**
     * Selektor für Sales Partner Formular
     */
    public salesPartnerForm = new BehaviorSubject<FinprocessFormGroup<ISalesPartner> | undefined>(undefined);

    /**
     * Selektor für Zusatzinformationen Formular
     */
    public additionalInformationForm = new BehaviorSubject<FinprocessFormGroup<IAdditionalInformation> | undefined>(undefined);

    /**
     * Selektor für Rückzahlungsdetails Formular
     */
    public repaymentDetailsForm = new BehaviorSubject<FinprocessFormGroup<IRepaymentDetails> | undefined>(undefined);

    /**
     * Selektor für Druckinformationen Formular
     */
    public detailPrintingForm = new BehaviorSubject<FinprocessFormGroup<IDetailPrinting> | undefined>(undefined);

    /**
     * Selektor für Genehmingungsinformationen Formular
     */
    public approvalDataForm = new BehaviorSubject<FinprocessFormGroup<IApprovalData> | undefined>(undefined);

    /**
     * Selektor für Objektinformationen Formular
     */
    public objectInformationForm = new BehaviorSubject<FinprocessFormGroup<IObjectInformation> | undefined>(undefined);

    /**
     * Selektor für Kunden
     */
    public customerForm = new BehaviorSubject<((index: number) => FinprocessFormGroup<ICustomer>) | undefined>(undefined);

    /**
     * Selektor für Collaterals
     */
    public collateralForm = new BehaviorSubject<((index: number) => FinprocessFormGroup<ICollateral>) | undefined>(undefined);

    /**
     * Selektor für Produkte
     */
    public productForm = new BehaviorSubject<((index: number) => FinprocessFormGroup<IProduct>) | undefined>(undefined);

    /**
     * Selektor für Garantien
     */
    public guaranteeForm = new BehaviorSubject<((index: number) => FinprocessFormGroup<IGuaranteeCreditLine>) | undefined>(undefined);

    /**
     * Selektor für Assets
     */
    public assetForm = new BehaviorSubject<((collateralIndex: number, assetIndex: number) => FinprocessFormGroup<IAsset>) | undefined>(undefined);

    /**
     * Selektor für Treuhänder Assets
     */
    public trusteeAssetForm = new BehaviorSubject<((collateralIndex: number, assetIndex: number) => FinprocessFormGroup<IOnlyOwnership>) | undefined>(undefined);

    /**
     * Auswahlliste für Produkt IDs
     */
    public productIds = new BehaviorSubject<ILabelValue<number>[]>([]);

    /**
     * Auswahlliste für Garantie IDs
     */
    public guaranteeIds = new BehaviorSubject<ILabelValue<number>[]>([]);

    /**
     * Auswahlliste für NDGs aus Collateral
     */
    public collateralNDGs = new BehaviorSubject<Record<number, ILabelValue<string>[]>>({});

    /**
     * Sollte der Salespartner Bereich angezeigt werden?
     */
    public showSalespartner = new BehaviorSubject<boolean>(false);

    /**
     * On Change Emitter des Colt Formulars
     */
    public onChange$ = new Subject<IColtDataContainer>();

    /**
     * Liste an Subscriptions
     */
    private onDestroy$ = new Subject<void>();

    /**
     * Offene Subscriptions
     */
    private subscriptions: Subscription[] = [];

    /**
     * Standard Konstruktor
     *
     * @param {Store} store Store-Injektor
     * @param {Actions} actions Actions-Injektor
     * @param {FinancingService} financingService FinancingService
     */
    public constructor(
        private store: Store,
        private actions: Actions,
        private financingService: FinancingService) {
        combineLatest([
            this.store.select((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer),
            this.store.select(FinancingState.isReferent),
            this.store.select((it: IFinancingStateParentDefinition) => it.financingTabs.editMode),
        ]).pipe(takeUntil(this.onDestroy$))
            .subscribe(([finprocessContainer, isReferent, editMode]) => {
                if (isReferent && editMode && (finprocessContainer?.status === FinancingStatus.Finalize && finprocessContainer?.subStatus === FinancingSubStatus.ViaColtApplication)) {
                    this.coltForm?.enable();
                } else {
                    this.coltForm?.disable();
                }
            });

        this.actions.pipe(ofActionSuccessful(FinancingLeaved, Logout), takeUntil(this.onDestroy$)).subscribe(() => this.resetColtForm());
    }

    /**
     * Beendet offene Subscriptions
     */
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    /**
     * Erstellt das Coltformular
     * 
     * @param {IColtDataContainer} data Colt Daten
     * @returns {FinprocessFormGroup<IColtDataContainer>} Colt Formular
     */
    public createColtForm(data?: IColtDataContainer): FinprocessFormGroup<IColtDataContainer> {
        const finprocessContainer = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.finprocessContainer);
        const financing = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financing.financing);
        const editMode = this.store.selectSnapshot((it: IFinancingStateParentDefinition) => it.financingTabs.editMode);
        const metaData = new FinprocessFormGroup<IColtMetaData>({
            mandantType: new FinprocessFormControl(financing?.mandantType),
            isOwnEmployee: new FinprocessFormControl(financing?.branchOfChoice?.toUpperCase() === EMPLOYEES_BRANCH_ID),
        });

        const form = coltForm(data, undefined, [{
            description: COLT_META_DATA,
            multi: false,
            group: metaData,
        }]);
        this.coltForm = form;
        this.coltForm$?.next(form);
        this.coltForm.disable();

        this.financingService.isEditor$.pipe(take(1)).subscribe(value => {
            if (!value || !editMode || finprocessContainer?.status !== FinancingStatus.Finalize || finprocessContainer?.subStatus !== FinancingSubStatus.ViaColtApplication) {
                this.coltForm?.disable();
            } else {
                this.coltForm?.enable();
            }

        });

        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
        this.subscriptions = [];

        this.subscriptions.push(form.valueChanges.subscribe(value => this.onChange$.next(value as IColtDataContainer)));

        // Selektoren für Unterobjekte des Gesamtformulares initialisieren
        const loanData = form.controls.loanData;
        const collaterals = form.controls.collaterals;
        const products = loanData.controls.products;
        const guarantees = loanData.controls.guaranteeCreditLines;

        this.salesPartnerForm.next(form.controls.salespartner);
        this.additionalInformationForm.next(form.controls.additionalInformation);
        this.repaymentDetailsForm.next(form.controls.repaymentDetails);
        this.detailPrintingForm.next(form.controls.detailPrinting);
        this.approvalDataForm.next(form.controls.approvalData);
        this.objectInformationForm.next(loanData.controls.objectInformation);
        this.customerForm.next((index: number) => form.controls.customers.at(index));
        this.collateralForm.next((index: number) => form.controls.collaterals.at(index));
        this.productForm.next((index: number) => products.at(index));
        this.guaranteeForm.next((index: number) => guarantees.at(index));
        this.assetForm.next((collateralIndex: number, assetIndex: number) => form.controls.collaterals.at(collateralIndex).controls.assets.at(assetIndex));
        this.trusteeAssetForm.next((collateralIndex: number, assetIndex: number) => form.controls.collaterals.at(collateralIndex).controls.trustee.controls.onlyOwnerships.at(assetIndex));

        this.subscriptions.push(products.valueChanges.pipe(startWith(products.getRawValue()), filter(prods => !!prods)).subscribe(prods => {
            this.productIds.next(prods.filter(product => product.loanReferenceId !== null && product.loanReferenceId !== undefined).map((product, index: number) => ({
                label: `Produkt ${index + 1}`,
                value: (product as Partial<IProduct> & { loanReferenceId: number }).loanReferenceId,
            })))
        }));

        this.subscriptions.push(guarantees.valueChanges.pipe(startWith(guarantees.getRawValue()), filter(guaranteesValue => !!guaranteesValue)).subscribe(guaranteesValue => {
            this.guaranteeIds.next(guaranteesValue.filter(guarantee => guarantee.guaranteeProductID !== null && guarantee.guaranteeProductID !== undefined).map((guarantee, index: number) => ({
                label: `Garantiekredit ${index + 1}`,
                value: (guarantee as Partial<IGuaranteeCreditLine> & { guaranteeProductID: number }).guaranteeProductID,
            })))
        }));

        this.subscriptions.push(collaterals.valueChanges.pipe(
            startWith(collaterals.getRawValue()),
            filter(collateralValue => !!collateralValue),
            map(collateralValue => collateralValue.map(({ providerNdg, coProvidersNdgs }) => ({ providerNdg, coProvidersNdgs }))))
            .subscribe(collateralValue => {
                const collateralNDGs = collateralValue.reduce<Record<number, ILabelValue<string>[]>>((acc, curr, idx) => {
                    const ndgs = (curr.coProvidersNdgs ?? []).filter(ndg => !!ndg) as string[];

                    if (!!curr.providerNdg) {
                        ndgs.push(curr.providerNdg);
                    }

                    acc[idx] = ndgs.map(v => ({ label: v, value: v }));

                    return acc;
                }, []);

                this.collateralNDGs.next(collateralNDGs)
            }));

        return form;
    }

    /**
     * Setzt das Colt Formular zurück
     */
    public resetColtForm(): void {
        this.coltForm?.reset();
    }
}
