<ng-template #readonlyFormFieldTemplate let-type="type" let-property="property" let-translation="translation">
    <div class="readonly-mode-field">
        @if(type === 'percent') {
            {{ (fireForm.get(property)?.value !== undefined && fireForm.get(property)?.value !== null) ? 
                ((fireForm.get(property)!.value | number:'1.3-3') +'%') : '-' }}
        } @else if(type === 'currency') {
            {{ (fireForm.get(property)?.value !== undefined && fireForm.get(property)?.value !== null) ? 
                (fireForm.get(property)!.value | formatCurrency) : '-' }}
        } @else {
            {{ (fireForm.get(property)?.value !== undefined && fireForm.get(property)?.value !== null && fireForm.get(property)?.value !== '') ? 
                (translation ? (fireForm.get(property)!.value! | finprocessEnumTranslation:translation) : fireForm.get(property)!.value) : '-' }}
        }
    </div>
</ng-template>

<ucba-table [noAlternatingColumnColors]="true">
    <ng-template ucba-table-header>
        <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.risk-decision.fireSelection' | translate}}</div>
    </ng-template>
    <ng-template ucba-table-row>
        <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.selection' | translate}}</div>
        <div>
            <mat-radio-group [(ngModel)]="fireOptions" (change)="onFireOptionChange(fireOptions)"
                [disabled]="(fieldReadonly$ | async) ?? true">
                <mat-radio-button [disabled]="isRiskDecisionFromApi === false" class="mr-3" color="primary" name="fireAPI" value="1">
                    {{'financing.features.financing-processing.risk-decision.fireAPI' | translate}}
                </mat-radio-button>
                <mat-radio-button [disabled]="isRiskDecisionFromApi === true" color="primary" name="fireManual" value="2">
                    {{'financing.features.financing-processing.risk-decision.fireManuell' | translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </ng-template>
    @if(fireManual) {
        <ng-template ucba-table-header>
            <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.risk-decision.fireForm' | translate}}</div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.reasonCode' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <mat-select formControlName="reasonCode">
                            <mat-option *ngFor="let reasonCode of reasonCodes" [value]="reasonCode">
                                {{reasonCode | finprocessEnumTranslation:'ReasonCode'}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ fireForm.get('reasonCode')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { property: 'reasonCode', translation: 'ReasonCode' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.reasonCodeText' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="ergTextStatusFinal" readonly>
                        <mat-error>
                            {{ fireForm.get('ergTextStatusFinal')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { property: 'ergTextStatusFinal' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.addCode' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <mat-select formControlName="ergAdditionalCode">
                            <mat-option *ngFor="let additionalCode of additionalCodes" [value]="additionalCode">
                                {{additionalCode | finprocessEnumTranslation:'AdditionalCode'}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{ fireForm.get('ergAdditionalCode')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { property: 'ergAdditionalCode', translation: 'AdditionalCode' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.addCodeText' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="ergTextAdditionalStatus" readonly>
                        <mat-error>
                            {{ fireForm.get('ergTextAdditionalStatus')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { property: 'ergTextAdditionalStatus' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.fireID' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="proposalId">
                        <mat-error>
                            {{ fireForm.get('proposalId')?.errors | finProcessFormError}}
                        </mat-error>
                        <button *ngIf="isTestEnvironment" mat-icon-button matSuffix (click)="generateCubeId($event)">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { property: 'proposalId' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.belq' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="kpiBelq" currencyMask [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiBelq')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { type: 'percent', property: 'kpiBelq' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.dsti' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="kpiDsti" currencyMask [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiDsti')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { type: 'percent', property: 'kpiDsti' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.ltv' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="kpiLtv" currencyMask [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiLtv')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { type: 'percent', property: 'kpiLtv' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.kimv' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="kpiKimVRelSchuldq" currencyMask
                            [options]="percentageMaskOptions">
                        <mat-error>
                            {{ fireForm.get('kpiKimVRelSchuldq')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { type: 'percent', property: 'kpiKimVRelSchuldq' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.totalCommitment' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="totalCommitment" currencyMask
                            [options]="currencyMaskOptions">
                        <mat-error>
                            {{ fireForm.get('totalCommitment')?.errors | finProcessFormError}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { type: 'currency', property: 'totalCommitment' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row>
            <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.totalCommitmentNoGuranatee' | translate}}</div>
            <div [formGroup]="fireForm">
                @if((fieldReadonly$ | async) === false) {
                    <mat-form-field appearance="fill" class="w-100 mat-form-field-transparent-background">
                        <input matInput formControlName="totalCommitmentNoGuarantee" currencyMask
                            [options]="currencyMaskOptions">
                        <mat-error>
                            {{ fireForm.get('totalCommitmentNoGuarantee')?.errors | finProcessFormError:undefined:'currency'}}
                        </mat-error>
                    </mat-form-field>
                } @else {
                    <ng-container *ngTemplateOutlet="readonlyFormFieldTemplate; context: { type: 'currency', property: 'totalCommitmentNoGuarantee' }"></ng-container>
                }
            </div>
        </ng-template>
        <ng-template ucba-table-row *ngIf="(fieldReadonly$ | async) === false">
            <div fxFlex="row" fxLayoutAlign="flex-end center" class="pt-3 pb-3">
                <!-- fire ergebnis pdf hochladen-->
                <a fxFlex="row" fxFlexAlign="center" *ngIf="!fireDocumentData && !uploadedData" mat-raised-button
                   href="javascript:void(0)" color="primary" class="mr-3 btn-fix-flex" style="width: fit-content"
                   (click)="file.click()">
                   <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false"
                       (change)="uploadFireDocument($event.target, false)" />
                   {{'financing.features.financing-processing.risk-decision.uploadManualFirePDF' | translate}}
               </a>
               <!-- hochgeladene fire ergebnis pdf anzeigen & ersetzen-->
               <div *ngIf="uploadedData && !fireDocumentData" class="mr-3 btn-fix-flex" fxLayout="row">
                   <mat-icon class="mr-1 document-icon">description</mat-icon>
                   <div class="mr-3">{{uploadedData.file.name}}</div>
                   <mat-icon class="icons" href="javascript:void(0)" matTooltip="Ersetzen" (click)="file.click()">
                       swap_horiz
                       <input accept="application/pdf" style="display: none;" type="file" #file [multiple]="false"
                           (change)="uploadFireDocument($event.target, false)" />
                   </mat-icon>
               </div>
                <!--fire formular speichern-->
                <button *ngIf="!fireDocumentData" mat-raised-button class="btn-fix-flex" color="primary"
                    [disabled]="fireForm.invalid || !uploadedData"
                    (click)="saveManualFireData()">{{'financing.features.financing-processing.risk-decision.save' |
                    translate}}</button>
                <button *ngIf="fireDocumentData" mat-raised-button class="btn-fix-flex" color="primary"
                    [disabled]="fireForm.invalid"
                    (click)="saveManualFireData()">{{'financing.features.financing-processing.risk-decision.save' |
                    translate}}</button>
            </div>
        </ng-template>
        @if(fireDocumentData?.fileId) {
            <!--hochgeladenes fire document anzeigen & ersetzen-->
            <ng-template ucba-table-header>
                <div [attr.ucba-column-span]="'span 2'">{{'financing.features.financing-processing.risk-decision.uploadedFireDocument' | translate}}</div>
            </ng-template>
            <ng-template ucba-table-row>
                <div fxLayoutAlign="flex-start center">{{'financing.features.financing-processing.risk-decision.fireDocument' | translate}}</div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" [class.readonly-mode-field]="(fieldReadonly$ | async) !== false">
                        <mat-icon class="mr-1 document-icon">description</mat-icon>
                        <div>{{fireDocumentData?.fileName}}.pdf</div>
                    </div>
                    <div *ngIf="(fieldReadonly$ | async) === false" fxLayout="row" fxLayoutAlign="flex-end center">
                        <button mat-mini-fab [color]="'primary'" class="mr-2 icons" 
                            (click)="downloadFireDocument()" 
                            [matTooltip]="'financing.features.financing-processing.risk-decision.openUploadedDocument' | translate"
                            [disabled]="(fieldReadonly$ | async) ?? true">
                                <mat-icon>open_in_new</mat-icon>
                        </button>
        
                        <button mat-mini-fab [color]="'primary'" class="icons" 
                            (click)="file.click()"
                            [matTooltip]="'financing.features.financing-processing.risk-decision.replaceUploadedDocument' | translate"
                            [disabled]="(fieldReadonly$ | async) ?? true">
                            <mat-icon  href="javascript:void(0)">
                                swap_horiz
                                <input accept="application/pdf" style="display: none;" type="file" #file
                                    [multiple]="false" (change)="uploadFireDocument($event.target, true)" />
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
        }
    } 
</ucba-table>
@if(fireAPI) {
    <!--fire über schnittstellen einreichen -->
    <finprocess-generic-form-risk-decision *ngIf="fireAPI" [data]="data$"></finprocess-generic-form-risk-decision>
}
