import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CollateralType, EnumTranslationPipe } from '@ntag-ef/finprocess-enums';
import { AssetService, FinancingService, SourceCollateral } from 'app/modules/financing/data';
import { IAssetAccount } from 'app/modules/financing/data/interfaces/asset-account';
import { ISelectItem } from 'app/modules/shared';

import { AssetCommonComponent } from '../asset-common/asset-common';

/**
 * Asset account Component
 */
@Component({
    selector: 'finprocess-asset-account',
    templateUrl: './asset-account.component.html',
    styleUrls: ['./asset-account.component.scss'],
})
export class AssetAccountComponent extends AssetCommonComponent {

    @Input()
    public assetAccount: IAssetAccount | undefined;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    public collateralType = CollateralType;
    
    public assetExpand = false;

    /**
     * Localisation
     */
    public locale: string;

    /**
     * Dropdown Boolean
     */
    public booleanItems?: ISelectItem<boolean | null>[] = [];

    /**
     * Validators für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public Validators = Validators;

    /**
     * SourceCollateral für HTML
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public SourceCollateral = SourceCollateral
   
    /**
     * Konstruktor
     * 
     * @param {string} locale Locale
     * @param {EnumTranslationPipe} enumTranslate EnumTranslationPipe
     * @param { AssetService } assetService AssetService
     * @param {Store} store Store-Injektor
     * @param {FinancingService} financingService financing service
     */
    public constructor(
        @Inject(LOCALE_ID) locale: string,
            enumTranslate: EnumTranslationPipe,
            assetService: AssetService,
            store: Store,
            financingService: FinancingService,
    ) {
        super(
            assetService,
            enumTranslate,
            store,
            financingService,
        )

        this.booleanItems = [true, false].map(value => ({
            value: value,
            displayName: value ? 'Ja' : 'Nein',
        }));

        this.booleanItems.push(( { displayName: 'Keine Auswahl', value: null }));

        this.locale = locale === 'de-AT' ? 'de' : locale;
    }  
}
