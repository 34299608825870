import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService, UUID } from 'app/modules/shared';
import { Observable, iif, mergeMap, of } from 'rxjs';

import { ColtSaveMode } from '../../enums';
import { IColtDataContainer, Server } from '../../interfaces';

/**
 * Service zum Abrufen und Speichern der Colt-Daten
 */
@Injectable()
export class ColtService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} http HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     * @param {Store} store Store-Injektor
     */
    public constructor(
        private http: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
        private store: Store,
    ) { }


    /**
     * Lädt einen Colt Data Container zu eine Finanzierung
     *
     * @param {UUID} id Identifier der Finanzierung
     * @param {UUID} financingPlanId Financing Map id
     * @param {boolean} cleanLoad Wenn true werden nur vorbefüllte Daten geladen, wenn false wird der Zwischenspeicher mitgeladen
     * @returns {IColtDataContainer | undefined} Colt Data Container
     */
    public loadColtData(id: UUID, financingPlanId?: UUID, cleanLoad = false): Observable<Server.IColtDataContainer | undefined> {

        let params = new HttpParams();

        params = params.append('finProcessContainerId', id)
        params = params.append('cleanLoad', cleanLoad);

        if (!!financingPlanId) {
            params = params.append('financingPlanId', financingPlanId)
        }

        return this.authorizationService.checkPermissions$(Role.Referent, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.http.get<Server.IColtDataContainer>(`${this.config.getEnvironment().apiUrl}/Colt/GetColtData`, {
                    params,
                }),
                of(undefined),
            )),
        );
    }

    /**
     * Speichert Colt Daten
     *
     * @param {IColtDataContainer} coltData Colt daten
     * @param {string} financingmapId ID der Finanzierung
     * @returns {string | undefined} Colt Systemnummer
     */
    public validate(coltData: IColtDataContainer, financingmapId: string): Observable<void> {
        return this.authorizationService.checkPermissions$(Role.Referent, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.http.post<void>(`${this.config.getEnvironment().apiUrl}/Colt/Validate`, { ...coltData, financingMapContainerId: financingmapId }, {}),
                of(undefined),
            )),
        );
    }

    /**
     * Speichert Colt Daten
     *
     * @param {IColtDataContainer} coltData Colt daten
     * @param {string} financingmapId ID der Finanzierung
     * @param {ColtSaveMode} saveMode Speichermodus
     * @returns {string | undefined} Colt Systemnummer
     */
    public storeColtData(coltData: IColtDataContainer, financingmapId: string, saveMode: ColtSaveMode): Observable<string | undefined> {
        let force = false;
        let changeStatus = true;

        if (saveMode === ColtSaveMode.TemporarySave) {
            force = true;
            changeStatus = false;
        } else if (saveMode === ColtSaveMode.ForceSave) {
            force = true;
            changeStatus = true;
        }

        return this.authorizationService.checkPermissions$(Role.Referent, false).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.http.post(`${this.config.getEnvironment().apiUrl}/Colt/StoreDataForColt`, { ...coltData, financingMapContainerId: financingmapId }, {
                    responseType: 'text',
                    params: {
                        force,
                        changeStatus,
                    },
                }),
                of(undefined),
            )),
        );
    }
}
