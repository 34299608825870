export enum LoanType {
    /**
     * Standard mortgage loan
     */
    MSTANDARD,

    /**
     * Third party funded mortgage loan
     */
    MWBB,

    /**
     * Mortgage loan to own employee
     */
    MOWNEMP,

    /**
     * Mortgage loan to own employee with third party funding
     */
    MOWNEMPWBB,
}
