<h2 mat-dialog-title>
    @if (data.rentalIncome?.id !== undefined) {
    {{ 'financing.features.financing-processing.rental-income.titleUpdate' | translate }}
    } @else {
    {{ 'financing.features.financing-processing.rental-income.titleAdd' | translate }}
    }
</h2>
<mat-dialog-content>
    @if (data.type === 'existing') {
    <div [formGroup]="existingRentalIncomeForm" fxLayout="column">
        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.objectName' | translate }}</mat-label>
            <input matInput formControlName="objectName" />
            @if (existingRentalIncomeForm.controls.objectName.errors) {
            <mat-error>{{ existingRentalIncomeForm.controls.objectName.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.rentalIncome' | translate }}</mat-label>
            <input matInput formControlName="rentalIncome" currencyMask [options]="currencyMaskOptions" />
            @if (existingRentalIncomeForm.controls.rentalIncome.errors) {
            <mat-error>{{ existingRentalIncomeForm.controls.rentalIncome.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>
    </div>
    } @else {
    <div [formGroup]="futureRentalIncomeForm" fxLayout="column">
        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.objectName' | translate }}</mat-label>
            <input matInput formControlName="objectName" />
            @if (futureRentalIncomeForm.controls.objectName.errors) {
            <mat-error>{{ futureRentalIncomeForm.controls.objectName.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.grossRentPerMonth' | translate }}</mat-label>
            <input matInput formControlName="grossRentPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (futureRentalIncomeForm.controls.grossRentPerMonth.errors) {
            <mat-error>{{ futureRentalIncomeForm.controls.grossRentPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.vatPerMonth' | translate }}</mat-label>
            <input matInput formControlName="vatPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (futureRentalIncomeForm.controls.vatPerMonth.errors) {
            <mat-error>{{ futureRentalIncomeForm.controls.vatPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.operatingCostsPerMonth' | translate }}</mat-label>
            <input matInput formControlName="operatingCostsPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (futureRentalIncomeForm.controls.operatingCostsPerMonth.errors) {
            <mat-error>{{ futureRentalIncomeForm.controls.operatingCostsPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.netRentPerMonth' | translate }}</mat-label>
            <input matInput formControlName="netRentPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (futureRentalIncomeForm.controls.netRentPerMonth.errors) {
            <mat-error>{{ futureRentalIncomeForm.controls.netRentPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'financing.features.financing-processing.rental-income.netRent70PercentPerMonth' | translate }}</mat-label>
            <input matInput formControlName="netRent70PercentPerMonth" currencyMask [options]="currencyMaskOptions" />
            @if (futureRentalIncomeForm.controls.netRent70PercentPerMonth.errors) {
            <mat-error>{{ futureRentalIncomeForm.controls.netRent70PercentPerMonth.errors | finProcessFormError }}</mat-error>
            }
        </mat-form-field>
    </div>
    }
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{ 'button.cancel' | translate }}</button>
    <button
        mat-button
        [disabled]="(data.type === 'existing' && existingRentalIncomeForm.invalid) || (data.type === 'future' && futureRentalIncomeForm.disabled)"
        (click)="save()">
        {{ (data.rentalIncome?.id !== undefined ? 'button.update' : 'button.save') | translate }}
    </button>
</mat-dialog-actions>
