import { Directive } from '@angular/core';

/**
 * Directive for actions in a ucba-heading. Used to layout actions in the correct position.
 */
@Directive({
    selector: '[ucbaHeadingActions], [ucba-heading-actions], ucba-heading-actions',
    standalone: true,
})
export class UcbaHeadingActionsDirective { }

/**
 * Directive for the title in a ucba-heading. Used to layout the title in the correct position.
 */
@Directive({
    selector: '[ucbaHeadingTitle], [ucba-heading-title], ucba-heading-title',
    standalone: true,
})
export class UcbaHeadingTitleDirective { }

/**
 * Directive for the body in an expandable ucba-heading.
 * Will only be shown if the ucba-heading is expandable and is expanded.
 */
@Directive({
    selector: '[ucbaHeadingBody], [ucba-heading-body], ucba-heading-body',
    standalone: true,
})
export class UcbaHeadingBodyDirective { }

/**
 * Directive for the always visible body in an ucba-heading.
 */
@Directive({
    selector: '[ucbaHeadingBodyVisible], [ucba-heading-body-visible], ucba-heading-body-visible',
    standalone: true,
})
export class UcbaHeadingBodyVisibleDirective { }
