<ucba-tile-layout [columns]="3" [colummsMd]="1">
  @if((isReadonly$ | async) === false) {
    <ng-template ucba-tile>
      <div class="input-label">{{'financing.features.financing-processing.scoringGwb.status' | translate}}</div>
      <mat-form-field class="w-100 mat-form-field-transparent-background">
        <mat-select [ngModel]="riskFinancing?.status" disabled="true">
          <mat-option *ngFor="let status of financingPlanStatuses" [value]="status" >
            {{ status | finprocessEnumTranslation:'StatusScoring' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
    <ng-template ucba-tile *ngIf="!!form" [formGroup]="form">
      <div class="input-label">{{'financing.features.financing-processing.scoringGwb.rating' | translate}}</div>
      <mat-form-field class="w-100 mat-form-field-transparent-background">
        <input formControlName="productRating" matInput>
      </mat-form-field>
    </ng-template>
    <ng-template ucba-tile *ngIf="!!form" [formGroup]="form">
      <div class="input-label">{{'financing.features.financing-processing.scoringGwb.zone' | translate}}</div>
      <mat-form-field class="w-100 mat-form-field-transparent-background">
        <mat-select formControlName="zone">
          <mat-option *ngFor="let zone of zones" [value]="zone.value">
            {{zone.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  } @else {
    <ng-template ucba-tile>
      <finprocess-label-value>
        <span label>{{'financing.features.financing-processing.scoringGwb.status' | translate}}</span>
        <span value>{{ (riskFinancing?.status ?? '') | finprocessEnumTranslation:'StatusScoring' }}</span>
    </finprocess-label-value>
    </ng-template>
    <ng-template ucba-tile *ngIf="!!form" [formGroup]="form">
      <finprocess-label-value>
        <span label>{{'financing.features.financing-processing.scoringGwb.rating' | translate}}</span>
        <span value>{{ form.controls.productRating.value ?? '-' }}</span>
      </finprocess-label-value>
    </ng-template>
    <ng-template ucba-tile *ngIf="!!form" [formGroup]="form">
      <finprocess-label-value>
        <span label>{{'financing.features.financing-processing.scoringGwb.zone' | translate}}</span>
        <span value>{{ findArrayElementWithId(zones, form.controls.zone.value)?.viewValue ?? '-' }}</span>
      </finprocess-label-value>
    </ng-template>
  }
  <div class="pt-3 pb-3 buttons-wrapper" *ngIf="!!form && (isReadonly$ | async) === false" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-raised-button color="primary" [disabled]="!this.isScoringAvailable || (isReadonly$ | async) === true" (click)="getNewScoring()" >
        {{'financing.features.financing-processing.scoringGwb.scoringNew' | translate}}
      </button>
      <button 
        mat-raised-button 
        color="primary" 
        [disabled]="form?.pristine || 
          form?.invalid || 
          form?.disabled || 
          (isReadonly$ | async) === true"                                                            
        (click)="sendScoring()" >
        {{'financing.features.financing-processing.scoringGwb.sendScoring' | translate}}
      </button>
    </div>
  </div>
</ucba-tile-layout>
