import { Validators } from '@angular/forms';
import { FinprocessFormBuilder, createProviderInput } from '@ntag-ef/finprocess-forms';

import { CustomerType, ProfessionType } from '../enums';
import { CUSTOMER, SCORING } from '../forms/colt-provider';
import { ICustomer, IScoring } from '../interfaces';
import { ColtValidators } from '../util';

const scoringForm = FinprocessFormBuilder. createFormGroup<IScoring>({
    monthlyAlimony: {
        validator: createProviderInput({
            providers: [CUSTOMER],
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, Validators.min(0), Validators.max(99999.99)]) : Validators.max(99999.99)),
        }),
    },
    telecommunicationrate: {
        validator: Validators.max(99999.99),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType === CustomerType.EconomicLeader || customer.twoPersonsLivingInSameHousehold === false,
        }),
    },
    rentOrRunningCosts: {
        validator: Validators.max(99999.99),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType === CustomerType.EconomicLeader || customer.twoPersonsLivingInSameHousehold === false,
        }),
    },
    insuranceCosts: {
        validator: Validators.max(99999.99),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType === CustomerType.EconomicLeader || customer.twoPersonsLivingInSameHousehold === false,
        }),
    },
    energyCosts: {
        validator: Validators.max(99999.99),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType === CustomerType.EconomicLeader || customer.twoPersonsLivingInSameHousehold === false,
        }),
    },
    costsForLiving: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => ((customer.roleType === CustomerType.EconomicLeader || customer.twoPersonsLivingInSameHousehold === false) ?
                Validators.compose([Validators.required, Validators.max(99999.99)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType === CustomerType.EconomicLeader || customer.twoPersonsLivingInSameHousehold === false,
        }),
    },
    mobilitycosts: {
        validator: Validators.max(99999.99),
    },
    currencyOfAllHhbAmounts: {
        validator: createProviderInput({
            providers: [CUSTOMER],
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, ColtValidators.euroValidator]) : null),
        }),
        default: {
            providers: [],
            fn: () => 'EUR',
        },
    },
    nominalAmountInternalInclEmk: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, Validators.min(0), Validators.max(9999999.99)]) : null),
        }),
    },
    nominalAmountInternalWithoutEmk: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, Validators.min(0), Validators.max(9999999.99)]) : null),
        }),
    },
    loanOrLeasingRates: {
        validator: Validators.max(9999999.99),
    },
    typeAddIncomeValue: null,
    otherMonthlyExpenses: {
        validator: Validators.max(9999999.99),
    },
}, SCORING);

export const customerForm = FinprocessFormBuilder.createFormGroup<ICustomer>({
    citizenship: {
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
    },
    employedSince: {
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    firstName: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    lastName: {
        validator: Validators.required,
    },
    zipCode: {
        validator: Validators.compose([Validators.required, ColtValidators.numeric]),
    },
    finProcessGenderType: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    residenceSince: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    specialFlag: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, ColtValidators.yesNoValidator]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    roleType: {
        validator: Validators.required,
    },
    finProcessEmployeeStatus: {
        validator: Validators.required,
    },
    finProcessFamilyStatus: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    borrowerNdg: {
        validator: Validators.compose([Validators.required, Validators.maxLength(16), ColtValidators.numeric]),
    },
    academicTitleBefore: {
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    dateOfBirth: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    numberOfDependentPersons: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, Validators.min(0), Validators.max(19)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    numberOfCars: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, Validators.min(0), Validators.max(99)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    monthlyNetIncome: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.compose([Validators.required, Validators.max(9999999.99)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    monthlySelfEmployedNetIncome: {
        validator: Validators.max(9999999.99),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    finProcessKindOfResidence: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    finProcessEducationLevel: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    uninteruptedEmploymentWithinLast6Months: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => ((cst.finProcessEmployeeStatus === ProfessionType.Employee && cst.roleType !== CustomerType.CO) ? Validators.required : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => cst.roleType !== CustomerType.CO && cst.finProcessEmployeeStatus === ProfessionType.Employee,
        }),
    },
    addMonthlyNetIncome: {
        validator: Validators.max(9999999.99),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    multiplicationFactorOfAddIncome: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => ((cst.addMonthlyNetIncome > 0 && cst.roleType !== CustomerType.CO) ? Validators.compose([Validators.required, Validators.min(1), Validators.max(20)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => cst.addMonthlyNetIncome > 0 && cst.roleType !== CustomerType.CO,
        }),
    },
    multiplicationFactorOfNetIncome: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => ((cst.monthlyNetIncome > 0 && cst.roleType !== CustomerType.CO) ? Validators.compose([Validators.required, Validators.min(1), Validators.max(20)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => cst.monthlyNetIncome > 0 && cst.roleType !== CustomerType.CO,
        }),
    },
    multiplicationFactorOfSelfEmployedIncome: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => ((cst.monthlySelfEmployedNetIncome > 0 && cst.roleType !== CustomerType.CO) ? Validators.compose([Validators.required, Validators.min(1), Validators.max(20)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (cst: ICustomer) => cst.monthlySelfEmployedNetIncome > 0 && cst.roleType !== CustomerType.CO,
        }),
    },
    branchOfEmployer: {
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType !== CustomerType.CO && [ProfessionType.Worker, ProfessionType.PublicServant, ProfessionType.Apprentice, ProfessionType.Employee].includes(customer.finProcessEmployeeStatus) ? Validators.compose([Validators.required, Validators.maxLength(8)]) : null),
        }),
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO && [ProfessionType.Worker, ProfessionType.PublicServant, ProfessionType.Apprentice, ProfessionType.Employee].includes(customer.finProcessEmployeeStatus),
        }),
    },
    academicTitleAfter: {
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    },
    twoPersonsLivingInSameHousehold: {
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType === CustomerType.CoBorrower,
        }),
        validator: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => (customer.roleType === CustomerType.CoBorrower ? Validators.required : null),
        }),
    },
    scoring: null,
}, CUSTOMER, (parent?: ICustomer) => ({
    scoring: scoringForm(parent?.scoring, {
        visible: createProviderInput({
            providers: CUSTOMER,
            fn: (customer: ICustomer) => customer.roleType !== CustomerType.CO,
        }),
    }),
}));
