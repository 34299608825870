<div>
    <finprocess-sub-header [label]="'financing.features.financing-processing.riskfinancingplans.rfpTitle' | translate"></finprocess-sub-header>
    <ucba-list>
        @for(rfp of rfpList | async; track $index) {
            <ucba-list-element>
                <div ucba-list-content-left class="mr-3">
                    <div class="uppercase"><b>{{rfp.description}}</b></div>
                    <div><span>{{rfp.status | finprocessEnumTranslation:'RiskFinancingPlanStatus'}}</span></div>
                </div>
                <div ucba-list-content-center class="mr-3">
                    <div><span class="medium fix-width">{{'financing.features.financing-processing.riskfinancingplans.createdAt' | translate}}:</span><span class="no-wrap">{{rfp.created | date:'dd.MM.yyyy HH:mm'}}</span></div>
                    <div><span class="medium fix-width">{{'financing.features.financing-processing.riskfinancingplans.lastChange' | translate}}:</span><span class="no-wrap">{{rfp.modified | date:'dd.MM.yyyy HH:mm'}}</span></div>
                </div>
                <div ucba-list-content-right>
                    <button mat-raised-button color="primary" (click)="openRFP(rfp.id)">{{'financing.features.financing-processing.riskfinancingplans.openFinancingPlan' | translate}}</button>
                    <button mat-raised-button color="primary" [disabled]="editingReadonly$ | async" class="copy-button ml-2" (click)="copyNewRFP(rfp)">{{'financing.features.financing-processing.riskfinancingplans.copyRFP' | translate}}</button>
                    <button mat-raised-button color="primary" (click)="openProductPackage(rfp.id)" class="ml-2">{{'financing.features.financing-processing.riskfinancingplans.openProductPackage' | translate}}</button>
                </div>
            </ucba-list-element>
        }
    </ucba-list>
</div>