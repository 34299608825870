import { Validators } from '@angular/forms';
import { FinprocessFormBuilder } from '@ntag-ef/finprocess-forms';

import { DETAIL_PRINTING } from '../forms/colt-provider';
import { IDetailPrinting } from '../interfaces';

export const detailPrintingForm = FinprocessFormBuilder.createFormGroup<IDetailPrinting>({
    /**
     * Baufortschritt
     */
    constructionInProgress: null,
    /**
     * Vollstreckbarer Notariatsakt
     */
    enforcableNotaryAct: null,

    /**
     * Vertriebskanal
     * in case of sales partner --> M – VERTRIEBSPARTNER,
     * in case of no sales partner and field sales channel is blank --> B- STATIONÄRER VERTRIEB
     * in case of no sales partner and field sales channel is filled --> value selected(e.g.I- INTERNET)
     */
    salesChannelValue: {
        validator: Validators.required,
    },
}, DETAIL_PRINTING);
