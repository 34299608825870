export enum KindValue {
    /**
     * natürl. Person
     */
    NaturalPerson = 1,

    /**
     * Firma
     */
    LegalPerson = 2,

    /**
     * Verein
     */
    Club = 3,
}
