import { FormProviderToken } from '@ntag-ef/finprocess-forms';

import { IAccount,
    IAdditionalInformation,
    IApprovalData,
    IAssessment,
    IAssessmentData,
    IAsset,
    ICadastralData,
    ICollateral,
    IColtDataContainer,
    IColtMetaData,
    ICustomer,
    IDetailPrinting,
    IGuaranteeCreditLine,
    IGuaranteeDetailPrinting,
    IInsurance,
    ILease,
    ILeaseOwner,
    ILoanData,
    ILoanDataDetails,
    ILoanDetailPrinting,
    IMarriagePartner,
    IObjectInformation,
    IOneTimeInsuranceFee,
    IOnlyOwnership,
    IOwner,
    IPeriodicalInsuranceFee,
    IPreExistingClaim,
    IPricingData,
    IPriorChargesEur,
    IPriorChargesNotEur,
    IProduct,
    IRepaymentDetails,
    ISalesPartner,
    IScoring,
    ISpecialConditions,
    ITotalPropertyOwner,
    ITrustee,
} from '../interfaces';

export const COLT_META_DATA: FormProviderToken<IColtMetaData> = new FormProviderToken('colt.metaData');
export const COLT_DATA_CONTAINER: FormProviderToken<IColtDataContainer> = new FormProviderToken('colt.coltDataContainer');
export const CUSTOMER: FormProviderToken<ICustomer> = new FormProviderToken('colt.customer');
export const SCORING: FormProviderToken<IScoring> = new FormProviderToken('colt.scoring');
export const COLLATERAL: FormProviderToken<ICollateral> = new FormProviderToken('colt.collateral');
export const LOAN_DATA: FormProviderToken<ILoanData> = new FormProviderToken('colt.loanData');
export const SALES_PARTNER: FormProviderToken<ISalesPartner> = new FormProviderToken('colt.salesPartner');
export const APPROVAL_DATA: FormProviderToken<IApprovalData> = new FormProviderToken('colt.approvalData');
export const DETAIL_PRINTING: FormProviderToken<IDetailPrinting> = new FormProviderToken('colt.detailPrinting');
export const ADDITIONAL_INFORMATION: FormProviderToken<IAdditionalInformation> = new FormProviderToken('colt.additionalInformation');
export const REPAYMENT_DETAILS: FormProviderToken<IRepaymentDetails> = new FormProviderToken('colt.repaymentDetails');
export const ASSET: FormProviderToken<IAsset> = new FormProviderToken('colt.asset');
export const TRUSTEE: FormProviderToken<ITrustee> = new FormProviderToken('colt.trustee');
export const CADASTRAL_DATA: FormProviderToken<ICadastralData> = new FormProviderToken('colt.cadastralData');
export const OWNER: FormProviderToken<IOwner> = new FormProviderToken('colt.owner');
export const ASSESSMENT_DATA: FormProviderToken<IAssessmentData> = new FormProviderToken('colt.assessmentData');
export const PRIOR_CHARGE_EUR: FormProviderToken<IPriorChargesEur> = new FormProviderToken('colt.priorChargeEur');
export const PRIOR_CHARGE_NOT_EUR: FormProviderToken<IPriorChargesNotEur> = new FormProviderToken('colt.priorChargeNotEur');
export const PRE_EXISTING_CLAIM: FormProviderToken<IPreExistingClaim> = new FormProviderToken('colt.preExistingClaim');
export const OBJECT_INFORMATION: FormProviderToken<IObjectInformation> = new FormProviderToken('colt.objectInformation');
export const PRODUCT: FormProviderToken<IProduct> = new FormProviderToken('colt.product');
export const LOAN_DATA_DETAILS: FormProviderToken<ILoanDataDetails> = new FormProviderToken('colt.loanDataDetails');
export const SPECIAL_CONDITIONS: FormProviderToken<ISpecialConditions> = new FormProviderToken('colt.specialConditions');
export const PRICING_DATA: FormProviderToken<IPricingData> = new FormProviderToken('colt.pricingData');
export const PERIODICAL_INSURANCE_FEE: FormProviderToken<IPeriodicalInsuranceFee> = new FormProviderToken('colt.periodicalInsuranceFee');
export const ONETIME_INSURANCE_FEE: FormProviderToken<IOneTimeInsuranceFee> = new FormProviderToken('colt.oneTimeInsuranceFee');
export const LOAN_DETAIL_PRINTING: FormProviderToken<ILoanDetailPrinting> = new FormProviderToken('colt.loanDetailPrinting');
export const GUARANTEE_DETAIL_PRINTING: FormProviderToken<IGuaranteeDetailPrinting> = new FormProviderToken('colt.guaranteeDetailPrinting');
export const GUARANTEE_CREDIT_LINE: FormProviderToken<IGuaranteeCreditLine> = new FormProviderToken('colt.guaranteeCreditLine');
export const ASSESSMENT: FormProviderToken<IAssessment> = new FormProviderToken('colt.assessment');
export const MARRIAGE_PARTNER: FormProviderToken<IMarriagePartner> = new FormProviderToken('colt.marriagePartner');
export const ACCOUNT: FormProviderToken<IAccount> = new FormProviderToken('colt.account');
export const INSURANCE: FormProviderToken<IInsurance> = new FormProviderToken('colt.insurance');
export const LEASE: FormProviderToken<ILease> = new FormProviderToken('colt.lease');
export const LEASE_OWNER: FormProviderToken<ILeaseOwner> = new FormProviderToken('colt.leaseOwner');
export const ONLY_OWNERSHIP: FormProviderToken<IOnlyOwnership> = new FormProviderToken('colt.onlyOwnership');
export const TOTAL_PROPERTY_OWNER: FormProviderToken<ITotalPropertyOwner> = new FormProviderToken('colt.totalPropertyOwner');
