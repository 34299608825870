export enum LevelOfTraining {
    /**
     * Pflichtschule
     */
    CompulsorySchool = 0,

    /**
     * Lehre
     */
    Apprenticeship = 1,

    /**
     * Matura
     */
    HigherSchoolCertificate = 2,

    /**
     * Meisterprüfung
     */
    MasterCertificate = 3,

    /**
     * MBA ohne akademische Ausbildung
     */
    MBAWithoutBachelor = 4,

    /**
     * Akademische Ausbildung ohne Doktorat mit/ohne MBA
     */
    AcademicDegree = 5,

    /**
     * Doktorat
     */
    DoctorsDegree = 6,
}
