import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ControlTableType } from 'app/modules/administration/api';
import { ICadastralCommunity, ICollateralFactor, ICollateralFactorsForAsset } from 'app/modules/administration/data/interfaces';
import { AuthorizationService, Role } from 'app/modules/auth/data';
import { ConfigService } from 'app/modules/shared';
import { Observable, iif, mergeMap, of } from 'rxjs';

/**
 * Service zum Tabelledaten suchen
 */
@Injectable()
export class ControlTableSearchService {

    /**
     * Standard Konstruktor
     *
     * @param {HttpClient} http HttpClient-Injektor
     * @param {ConfigService} config ConfigService-Injektor
     * @param {AuthorizationService} authorizationService AuthorizationService-Injektor
     */
    public constructor(
        private http: HttpClient,
        private config: ConfigService,
        private authorizationService: AuthorizationService,
    ) { }

    /**
     * Sucht daten in Tabelle
     *
     * @param {string} search Suche
     * @param {string} searchOnlyInFollowingProperty Suche nur nach einer bestimmten Property
     * @param {boolean} loadDisabled Sollen deaktivierte Einträge mitgeladen werden?
     * @returns {ICadastralCommunity[] | undefined} Colt Data Container
     */
    public searchCastralCommunityTableData(search?: string, searchOnlyInFollowingProperty?: string, loadDisabled = false): Observable<ICadastralCommunity[] | undefined> {
        let params = new HttpParams();

        params = params.appendAll({
            controlTableType: ControlTableType.CadastralCommunities,
            loadDisabled: loadDisabled,
        });

        if (search) {
            params = params.append('searchString', search);
        }
        
        if (searchOnlyInFollowingProperty) {
            params = params.append('searchOnlyInFollowingProperty', searchOnlyInFollowingProperty);
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.http.get<ICadastralCommunity[]>(`${this.config.getEnvironment().apiUrl}/ControlTable/searchControlTableData`, {
                    params,
                }),
                of(undefined),
            )),
        )
    }

    /**
     * sucht daten in Tabelle
     *
     * @param {string} search Suche
     * @param {string} searchOnlyInFollowingProperty Suche nur nach einer bestimmten Property
     * @param {boolean} loadDisabled Sollen deaktivierte Einträge mitgeladen werden?
     * @returns {ICadastralCommunity[] | undefined} Colt Data Container
     */
    public searchCollateralAssetTableData(search?: string, searchOnlyInFollowingProperty?: string, loadDisabled = false): Observable<ICollateralFactorsForAsset[] | undefined> {
        let params = new HttpParams();
        params = params.appendAll({
            controlTableType: ControlTableType.CollateralFactorsForAssets,
            loadDisabled: loadDisabled,
        });
        if (search) {
            params.append('searchString', search);
        }
        if (searchOnlyInFollowingProperty) {
            params.append('searchOnlyInFollowingProperty', searchOnlyInFollowingProperty);
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.http.get<ICollateralFactorsForAsset[]>(`${this.config.getEnvironment().apiUrl}/ControlTable/searchControlTableData`, {
                    params,
                }),
                of(undefined),
            )),
        )
    }

    /**
     * Sucht daten in Tabelle
     *
     * @param {string} search Suche
     * @param {string} searchOnlyInFollowingProperty Suche nur nach einer bestimmten Property
     * @param {boolean} loadDisabled Sollen deaktivierte Einträge mitgeladen werden?
     * @returns {ICadastralCommunity[] | undefined} Colt Data Container
     */
    public searchCollateralFactorsTableData(search?: string, searchOnlyInFollowingProperty?: string, loadDisabled = false): Observable<ICollateralFactor[] | undefined> {
        let params = new HttpParams();
        params = params.appendAll({
            controlTableType: ControlTableType.CollateralFactors,
            loadDisabled: loadDisabled,
        });
        if (search) {
            params.append('searchString', search);
        }
        if (searchOnlyInFollowingProperty) {
            params.append('searchOnlyInFollowingProperty', searchOnlyInFollowingProperty);
        }

        return this.authorizationService.checkPermissions$(Role.FinancingMapsEditor).pipe(
            mergeMap(authorized => iif(
                () => authorized,
                this.http.get<ICollateralFactor[]>(`${this.config.getEnvironment().apiUrl}/ControlTable/searchControlTableData`, {
                    params,
                }),
                of(undefined),
            )),
        )
    }
}
